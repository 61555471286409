/** @format */

import HighlightedInputsContainer from "Components/HighlightedInputsContainer";
import InputModalsButton from "Components/InputModalButtons";
import InputsModal from "Components/InputsModal";
import TransferDetailsTable from "Components/TransferDetailsTable";
import React from "react";
import "./index.css";
import CurrencyFormatText from "Components/CurrencyFormatText";

export default function InputsComponent({ visible, onClose, data, markAsComplete }) {
   const { transfer_from, transfer_to, goal_transfer_summaries, amount } = data;

   return (
      <InputsModal
         visible={visible}
         inputModalTitle={"Transfer details"}
         onClose={onClose}>
         <div className="input-modal-inputs-container">
            <HighlightedInputsContainer>
               <div className="transfer-details-bank-row">
                  <div className="bank">{transfer_from}</div>
                  <div className="to">to</div>
                  <div className="bank">{transfer_to}</div>
               </div>
               <div className="transfer-amount">
                  <CurrencyFormatText value={amount} />
               </div>
            </HighlightedInputsContainer>
            <TransferDetailsTable data={goal_transfer_summaries} />
         </div>
         <div className="mt-4 flex items-center justify-between">
            <div className="skip-this-month">Skip this month</div>
            <div
               onClick={markAsComplete}
               className="mark-as-complete">
               Mark as complete
            </div>
         </div>
      </InputsModal>
   );
}
