/** @format */

import { backendUrl } from "../definitions";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-hot-toast";

export const createRequest = async (endpoint, data) => {
   return new Promise((resolve, reject) => {
      trackPromise(post(endpoint, data))
         .then((res) => resolve(res))
         .catch((err) => {
            toast.error("Something went wrong");
            reject(err);
         });
   });
};

export const readRequest = async (endpoint) => {
   console.log(window.localStorage.getItem("auth_token"), "readRequest");
   return new Promise((resolve, reject) => {
      trackPromise(get(endpoint))
         .then((res) => resolve(res))
         .catch((err) => {
            reject(err);
         });
   });
};

export const deleteRequest = async (endpoint) => {
   return new Promise((resolve, reject) => {
      trackPromise(del(endpoint))
         .then((res) => resolve(res))
         .catch((err) => {
            toast.error("Something went wrong");
            reject(err);
         });
   });
};

export const updateRequest = async (endpoint, data) => {
   return new Promise((resolve, reject) => {
      trackPromise(put(endpoint, data))
         .then((res) => resolve(res))
         .catch((err) => {
            toast.error("Something went wrong");
            reject(err);
         });
   });
};

const get = (endpoint) => {
   return new Promise(async (resolve, reject) => {
      try {
         const res = await axios.get(`${backendUrl}${endpoint}`, {
            headers: {
               Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
               "ngrok-skip-browser-warning": "any",
            },
         });
         if (res.status === 200 || res.status === 201) {
            resolve(res);
         } else {
            reject(res);
         }
      } catch (error) {
         reject(error);
      }
   });
};

const post = (endpoint, data) => {
   return new Promise(async (resolve, reject) => {
      try {
         const res = await axios.post(`${backendUrl}${endpoint}`, data, {
            headers: {
               Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
               "ngrok-skip-browser-warning": "any",
            },
         });
         if (res.status === 200 || res.status === 201) {
            resolve(res);
         } else {
            reject(res);
         }
      } catch (error) {
         console.log(error, "POST");
         reject(error);
      }
   });
};

const put = (endpoint, data) => {
   return new Promise(async (resolve, reject) => {
      try {
         const res = await axios.put(`${backendUrl}${endpoint}`, data, {
            headers: {
               Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
               "ngrok-skip-browser-warning": "any",
            },
         });
         if (res.status === 200 || res.status === 201) {
            resolve(res);
         } else {
            reject(res);
         }
      } catch (error) {
         reject(error);
      }
   });
};

const del = (endpoint) => {
   return new Promise(async (resolve, reject) => {
      try {
         const res = await axios.delete(`${backendUrl}${endpoint}`, {
            headers: {
               Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
               "ngrok-skip-browser-warning": "any",
            },
         });
         if (res.status === 200 || res.status === 201) {
            resolve(res);
         } else {
            reject(res);
         }
      } catch (error) {
         reject(error);
      }
   });
};
