import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
            style={{ cursor: "pointer" }}
        >
            <rect width="40" height="40" fill="#F6F6F7" rx="8"></rect>
            <mask
                id="mask0_221_11987"
                style={ { maskType: "alpha" } }
                width="24"
                height="24"
                x="8"
                y="8"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M8 8H32V32H8z"></path>
            </mask>
            <g mask="url(#mask0_221_11987)">
                <path
                    fill="#14142B"
                    d="M14.927 26.127a.745.745 0 11-1.054-1.054L18.946 20l-5.073-5.073a.745.745 0 111.054-1.054L20 18.946l5.073-5.073a.745.745 0 111.054 1.054L21.054 20l5.073 5.073a.745.745 0 11-1.054 1.054L20 21.054l-5.073 5.073z"
                ></path>
            </g>
        </svg>
    );
}

export default Icon;