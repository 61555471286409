import React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { ActiveChip, InActiveChip } from './chipStatusStyles';

export default function Chips( { title, onClick, isSelected } ) {
    return (
        <Stack direction="row" spacing={ 1 }>
            <Chip onClick={ onClick } sx={ isSelected ? ActiveChip : InActiveChip } label={ title } color="secondary" />
        </Stack>

    );
}
