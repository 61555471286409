import React from 'react';
import { TextField } from '@mui/material';
import './noOfEntries.css';
import IncreaseOrDescreaseIcon from '../../icons/IncreaseOrDescreaseIcon';

export default function NoOfEntries( {
    setPageIndex,
    pageSize,
    setPageSize
} ) {
    return (
        <div className='no-of-entries-container'>
            <input
                className='no-of-entries-input'
                type='number'
                value={ pageSize }
                onChange={ e => {
                    const pageSize = e.target.value ? Number( e.target.value ) : 0;
                    setPageSize( pageSize );
                } }
            />
            <IncreaseOrDescreaseIcon
                onClickUp={ () => { setPageSize( pageSize + 1 ); } }
                onClickDown={
                    () => {
                        if ( pageSize > 0 )
                        {
                            setPageSize( pageSize - 1 );
                        }
                    }
                }
            />
        </div>
    );
}
