/** @format */

import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "./index.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import dayjs from "dayjs";
import Button from "../Button";
import { ActiveStyle, InActiveStyle } from "./SelectInputStyles";
import DropdownInput from "../DropdownInput";
import CurrencyFormatInput from "Components/CurrenctFormatInput";
import { upperCaseFirst } from "upper-case-first";

export default function TextInput({
   label,
   currencyMode,
   onCreate,
   pickerType,
   minDate,
   maxDate,
   onChange,
   error,
   helperText,
   id,
   value,
   color,
   icon,
   type,
   options,
   form,
   setDateValue,
   setDropDownValue,
   diableCreate,
}) {
   console.log("currencyMode", currencyMode);
   const [inputValue, setInputValues] = useState("");

   useEffect(() => {
      setInputValues(value || "");
   }, [value]);

   const handleBlur = () => {
      const capitalizedValue = upperCaseFirst(inputValue);
      setInputValues(capitalizedValue);
      const event = {
         target: {
            name: id,
            value: capitalizedValue,
         },
      };
      if (onChange) {
         onChange(event);
      }
   };

   if (type === "textInput") {
      const handleChange = (e) => {
         setInputValues(e.target.value);
      };

      return (
         <TextField
            id={id}
            style={{ marginTop: 20, marginBottom: 10, borderRadius: 12 }}
            className="inputRounded"
            error={error}
            value={inputValue}
            label={label}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            variant="outlined"
            //Yet to fix the currency mode code
            // InputProps={{
            //    endAdornment: <InputAdornment position="end">{icon}</InputAdornment>,
            //    inputComponent: currencyMode ? CurrencyFormatInput : null,
            // }}
            helperText={helperText}
         />
      );
   } else if (type === "datePicker") {
      const onKeyDown = (e) => {
         e.preventDefault();
      };
      return (
         <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
               label={label}
               views={pickerType === "date" ? ["year", "month", "day"] : ["year", "month"]} //For picking year and month
               helperText={helperText}
               value={value}
               minDate={minDate}
               maxDate={dayjs(new Date("2050-12-31"))}
               onChange={(val) => setDateValue(new Date(val))}
               id={id}
               renderInput={(props) => (
                  <TextField
                     {...props}
                     fullWidth
                     onKeyDown={onKeyDown}
                     error={error}
                     style={{ marginTop: 20, marginBottom: 10, borderRadius: 12 }}
                     className="inputRounded"
                  />
               )}
               InputProps={{
                  endAdornment: (
                     <InputAdornment position="end">
                        <CalendarMonthIcon />
                     </InputAdornment>
                  ),
               }}
            />
         </LocalizationProvider>
      );
   } else if (type === "dropDown") {
      console.log(options, "dropDown options");
      return (
         <div>
            <DropdownInput
               label={label}
               error={error}
               diableCreate={diableCreate}
               helperText={helperText}
               placeholder={label}
               onCreate={onCreate}
               value={value ? value : ""}
               options={options}
               onChange={(e) => form.setFieldValue(id, e.target.value)}
               setDropDownValue={(type, val) => {
                  console.log("setDropDownValue", val);
                  setDropDownValue(val);
               }}
            />
         </div>
      );
   } else if (type === "Select") {
      console.log(value, "Select value", id);

      return (
         <div>
            <div className="select-label">{label}</div>
            <div className="select-labels-container">
               {options?.map((option) => {
                  return (
                     <div
                        onClick={() => form.setFieldValue(id, option?.value)}
                        className={`select-option-${value === option?.value ? "active" : "inactive"}-container`}>
                        <div>{option?.label}</div>
                     </div>
                  );
               })}
            </div>
         </div>
      );
   }
}
