/** @format */

import React from "react";
import CurrencyFormatText from "Components/CurrencyFormatText";

export default function PlannedAmount({ amount, updated }) {
   return (
      <div className="expense-amount-container">
         <div>
            <CurrencyFormatText value={amount} />
         </div>
         {updated && <div className="expense-amount-updated">Updated</div>}
      </div>
   );
}
