export const ActiveChip = {
    color: '#ffffff',
    backgroundColor: '#500472',
    borderWidth: '0px',
    paddingInline: '0.4rem',
    paddingBlock: '0.1rem',
};

export const InActiveChip = {
    color: '#500472',
    backgroundColor: '#EEE6F1',
    borderWidth: '0px',
    borderStyle: 'solid',
    '&:hover': {
        borderWidth: '1px',
        backgroundColor: '#EEE6F1',
    },
    paddingInline: '0.4rem',
    paddingBlock: '0.1rem',
};