/** @format */

import React from "react";
import TransferCard from "Components/TransferCard";
import { useReminderStore } from "store/reminders";

export default function Index({ data }) {
   return (
      <div className="mt-[2rem] gap-[1rem] flex flex-col">
         {data?.map((item, index) => {
            return (
               <TransferCard
                  item={item}
                  index={index}
               />
            );
         })}
      </div>
   );
}
