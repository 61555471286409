/** @format */

import React from "react";
import "./index.css";
import HighlightText from "Components/HighlightText";

export default function ExpenseType({ value }) {
   const colour = value === "Fixed" ? "#FFBC99" : "#ADE1FF";

   return (
      <div className="income-summary-cell-container">
         <div
            style={{ borderColor: colour }}
            className="income-summary-cell-line"></div>
         <HighlightText value={value} />
      </div>
   );
}
