/** @format */

import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "./index.css";
import { Fade } from "@mui/material";

function a11yProps(index) {
   return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
   };
}

export default function TabWrapper({ tabs, tabIndex, onChangeTab }) {
   const handleChange = (event, newValue) => {
      onChangeTab(newValue);
   };

   return (
      <Fade in={true}>
         <Tabs
            TabIndicatorProps={{
               style: {
                  background: "#500472",
                  color: "#500472",
               },
            }}
            sx={{ background: "#ffffff", marginTop: "1rem", marginBottom: "1rem", width: "98%", borderRadius: 1 }}
            value={tabIndex}
            onChange={handleChange}
            aria-label="basic tabs example">
            {tabs.map((tab, index) => {
               return (
                  <Tab
                     style={{ color: "#500472" }}
                     label={tab}
                     {...a11yProps(index)}
                  />
               );
            })}
         </Tabs>
      </Fade>
   );
}
