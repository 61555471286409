/** @format */

import React, { useState, useEffect, useRef } from "react";
import ChipsSelection from "../ChipsSelection";
import { MONTHS, YEARS } from "../../data/dummyData";
import "./index.css";
import Select from "react-select";
import { useSearchAndFilterStore } from "store/searchAndFilter";
import { Fade } from "@mui/material";
import { Grow } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { generateYears } from "utils/helper";
import { useAppStore } from "store/useApp";

export default function ChooseYearAndMonth({ type, onClickDone, selectedMonth }) {
   const [searchParams, setSearchParams] = useSearchParams();
   const paramsYears = searchParams.get("years") ? searchParams.get("years").split(",") : [];
   const paramsMonths = searchParams.get("months") ? searchParams.get("months").split(",") : [];

   const dynamicYear = generateYears();
   const slicedYears = dynamicYear.slice(0, 10);
   const chooseYears = slicedYears.map(String);

   const [monthSummaryMonthIndex, setmonthSummaryMonthIndex] = useState();

   const [data, setdata] = useState({
      selectedYear: new Date(selectedMonth).getFullYear(),
      selectedMonthIndex: new Date(selectedMonth).getMonth() + 1,
   });

   const popoverRef = useRef(null);
   const [openPopOver, setopenPopOver] = useState(false);
   const { addOrRemoveFilter, selectedYearFilters, selectedMonthFilters, onChangeFilter, clearFiltersYearAndMonth } = useAppStore((state) => state);
   console.log("selectedYearFilters", selectedYearFilters);

   const updateYear = (year) => {
      setdata({ ...data, selectedYear: year });
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("year", year);
      setSearchParams(newSearchParams);
   };

   const updateMonth = (monthIndex) => {
      setdata({ ...data, selectedMonthIndex: (monthIndex + 1).toString().padStart(2, "0") });
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("month", MONTHS[monthIndex]);
      setSearchParams(newSearchParams);
      setmonthSummaryMonthIndex(monthIndex);
   };

   const onPressDone = () => {
      setopenPopOver(!openPopOver);
      onClickDone({
         year: data.selectedYear,
         month: data.selectedMonthIndex,
      });
   };

   console.log(data, "data");

   const onUpdateFilter = (val, type) => {
      if (type === "year") {
         let updateParamsYears = [...paramsYears];
         const yearIndex = updateParamsYears.findIndex((year) => year === val.toString());
         if (yearIndex > -1) {
            updateParamsYears.splice(yearIndex, 1);
         } else {
            updateParamsYears.push(val);
         }
         if (updateParamsYears.length > 0) {
            searchParams.delete("years");
            searchParams.append("years", updateParamsYears);
            setSearchParams(searchParams);
         } else {
            searchParams.delete("years");
            setSearchParams(searchParams);
         }
      } else if (type === "month") {
         let updateParamsMonths = [...paramsMonths];
         const monthIndex = updateParamsMonths.findIndex((month) => month === val.toString());
         if (monthIndex > -1) {
            updateParamsMonths.splice(monthIndex, 1);
         } else {
            updateParamsMonths.push(val);
         }
         if (updateParamsMonths.length > 0) {
            searchParams.delete("months");
            searchParams.append("months", updateParamsMonths);
            setSearchParams(searchParams);
         } else {
            searchParams.delete("months");
            setSearchParams(searchParams);
         }
      }

      addOrRemoveFilter(val, type);
      onChangeFilter();
   };

   useEffect(() => {
      clearFiltersYearAndMonth();
      const currentYears = searchParams.get("years");
      const currentMonths = searchParams.get("months");
      const currentYearsArray = currentYears?.split(",");
      if (currentYearsArray !== undefined && currentYearsArray !== null && currentYearsArray.length > 0) {
         currentYearsArray.map((year) => {
            addOrRemoveFilter(year, "year");
         });
      }
      const currentMonthsArray = currentMonths?.split(",");
      if (currentMonthsArray !== undefined && currentMonthsArray !== null && currentMonthsArray.length > 0) {
         currentMonthsArray.map((month) => {
            addOrRemoveFilter(month, "month");
         });
      }
   }, []);

   useEffect(() => {
      const currentMonthSummaryYear = searchParams.get("year");
      const currentMonthSummaryMonth = searchParams.get("month");
      const currentMonthSummaryMonthIndex = MONTHS.indexOf(currentMonthSummaryMonth);
      setdata({
         selectedYear: currentMonthSummaryYear,
         selectedMonthIndex: currentMonthSummaryMonthIndex + 1,
      });
   }, []);

   useEffect(() => {
      function handleClickOutside(event) {
         if (popoverRef.current && !popoverRef.current.contains(event.target)) {
            setopenPopOver(false);
         }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, [popoverRef, setopenPopOver]);

   if (type === "select") {
      return (
         <div onBlur={onPressDone}>
            <div className="choose-year-and-month-container">
               <div className="choose-year-and-month-text">Choose year & month :</div>
               <div style={{ position: "relative" }}>
                  <div
                     onClick={() => setopenPopOver(!openPopOver)}
                     className="choosed-year-and-month">
                     {MONTHS[parseInt(data.selectedMonthIndex - 1)]}, {data.selectedYear}
                  </div>
                  {openPopOver && (
                     <Grow in={true}>
                        <div
                           ref={popoverRef}
                           className="choose-year-and-month-popover-container">
                           <div className="year-and-month-sub-container-wrapper">
                              <div className="year-container">
                                 <div style={{ color: "#898995" }}>Year</div>
                                 <div className="year-sub-container">
                                    {dynamicYear.map((year) => {
                                       return (
                                          <div
                                             onClick={() => updateYear(year)}
                                             className={`year-sub-text-${data.selectedYear == year ? "active" : "inactive"}`}>
                                             {year}
                                          </div>
                                       );
                                    })}
                                 </div>
                              </div>
                              <div className="line"></div>
                              <div className="months-container">
                                 <div style={{ color: "#898995" }}>Month</div>
                                 <div className="month-sub-container">
                                    {MONTHS.map((month, index) => {
                                       return (
                                          <div
                                             onClick={() => updateMonth(index)}
                                             className={`month-sub-text-${data.selectedMonthIndex - 1 == index ? "active" : "inactive"}`}>
                                             {month}
                                          </div>
                                       );
                                    })}
                                 </div>
                              </div>
                           </div>
                           {/* <div
                              onClick={onPressDone}
                              className="done-text">
                              Done
                           </div> */}
                        </div>
                     </Grow>
                  )}
               </div>
            </div>
         </div>
      );
   } else {
      return (
         <Fade in={true}>
            <div className="chips-selection-wrapper">
               <ChipsSelection
                  title={"Choose year"}
                  data={chooseYears}
                  selectedValues={selectedYearFilters}
                  onSelect={(val) => onUpdateFilter(val, "year")}
               />
               <ChipsSelection
                  title={"Choose month"}
                  data={MONTHS}
                  selectedValues={selectedMonthFilters}
                  onSelect={(val) => onUpdateFilter(val, "month")}
               />
            </div>
         </Fade>
      );
   }
}
