/** @format */

import React from "react";
import SearchInput from "../SearchInput";
import Button from "../Button";
import { Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Fade } from "@mui/material";

export default function index({ createButtonText, onPressCreate, showAddButton }) {
   return (
      <Grid
         container
         columnSpacing={2}>
         <Grid
            item
            xs={createButtonText !== "" ? 9.2 : 10}>
            <SearchInput />
         </Grid>

         {createButtonText !== "" && (
            <Grid item>
               <Button
                  onClick={onPressCreate}
                  startIcon={showAddButton && <AddIcon />}
                  title={createButtonText}
                  backgroundColor={"#500472"}
                  color={"#ffffff"}
                  hoverBackgroundColor={"#42045E"}
                  hoverColor={"#ffffff"}
               />
            </Grid>
         )}
      </Grid>
   );
}
