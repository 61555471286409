/** @format */

import { create } from "zustand";
import { createRequest, deleteRequest, updateRequest, readRequest } from "APIs/crud";
import { toast } from "react-hot-toast";

const API_ENDPOINT = "/goal/";

export const useGoalsStore = (set, get) => ({
   goals: [],
   goalsSummary: [],
   addGoal: async (data) => {
      return new Promise((resolve, reject) => {
         createRequest(API_ENDPOINT, data)
            .then((result) => {
               toast.success("Goal added successfully");
               set({ goals: [...get().goals, result?.data] });
               resolve(result);
            })
            .catch((err) => reject(err));
      });
   },
   getGoals: async () => {
      return new Promise((resolve, reject) => {
         readRequest(API_ENDPOINT)
            .then((result) => {
               set({ goals: result?.data?.response });
               resolve(result?.data?.response);
            })
            .catch((err) => reject(err));
      });
   },
   deleteGoal: async (id) => {
      return new Promise((resolve, reject) => {
         deleteRequest(`${API_ENDPOINT}${id}/`)
            .then((result) => {
               toast.success("Goal deleted successfully");
               set((state) => ({ goals: state.goals.filter((item) => item.goal_id !== id) }));
               resolve(result);
            })
            .catch((err) => reject(err));
      });
   },
   updateGoal: async (data) => {
      return new Promise((resolve, reject) => {
         updateRequest(`${API_ENDPOINT}${data.goal_id}/`, data)
            .then((result) => {
               toast.success("Goal updated successfully");
               set((state) => ({ goals: state.goals.map((item) => (item.goal_id === data.goal_id ? result?.data : item)) }));
               resolve(result);
            })
            .catch((err) => reject(err));
      });
   },
});
