/** @format */

import { faker } from "@faker-js/faker";
import { CategoryScale, Chart as ChartJS, Filler, Legend, LineElement, LinearScale, PointElement, Title, Tooltip } from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

const backgroundColorPlugin = {
   id: "backgroundColorPlugin",
   beforeDraw: (chart) => {
      const ctx = chart.ctx;
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, chart.width, chart.height);
   },
};
ChartJS.register(backgroundColorPlugin);
export const options = {
   responsive: true,
   plugins: {
      legend: {
         display: false,
      },
      tooltip: {
         enabled: true,
         backgroundColor: "#FFF",
         borderColor: "#000",
         borderWidth: 1,
         titleColor: "#898995",
         titleFont: {
            size: 16,
            weight: "normal",
         },
         bodyColor: "#623882",
         bodyFont: {
            weight: "bold",
            size: 16,
         },
         displayColors: false,
         padding: {
            top: 9,
            right: 20,
            bottom: 10,
            left: 9,
         },
         callbacks: {
            title: function (context) {
               return context[0].label;
            },
            label: function (context) {
               return `₹${context.formattedValue}`;
            },
         },
      },
   },
   layout: {
      backgroundColorPlugin: true,
   },
   scales: {
      x: {
         grid: {
            display: false,
         },
      },
      y: {
         grid: {
            display: false,
         },
         min: 1000,
         max: 15000,
         ticks: {
            stepSize: 2000,
            callback: function (value) {
               return `${value / 100}k`;
            },
         },
      },
   },
};

const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const data = {
   labels,
   datasets: [
      {
         fill: true,
         data: [2000, 5000, 4000, 10000, 2000, 5000, 4000, 10000, 6000, 2000, 5000, 13000],
         borderColor: "#502274",
         backgroundColor: "#FBF0FB",
         tension: 0.4,
      },
   ],
};

export default function CustomChart() {
   return (
      <div className="flex flex-col overflow-hidden gap-[1.4rem] rounded-[16px] w-[65%] h-[70%] border-1 border-[#000] p-[2rem] bg-[#fff] mt-[2rem]">
         <div className="flex items-center justify-between">
            <span className="font-phudu text-[24px] font-semibold">Month wise spending</span>
            <span></span>
         </div>
         <Line
            style={{ width: "100%", height: "60%" }}
            options={options}
            data={data}
         />
      </div>
   );
}
