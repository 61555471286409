import React from "react";

function Icon( { onClick } ) {

    return (
        <svg
            onClick={ onClick }
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            fill="none"
            viewBox="0 0 34 34"
            className="svg-icon"
        >
            <rect
                width="33"
                height="33"
                x="0.5"
                y="0.5"
                fill="#fff"
                stroke="#E8E8EA"
                rx="5.5"
            ></rect>
            <mask
                id="mask0_363_19054"
                style={ { maskType: "alpha" } }
                width="24"
                height="24"
                x="4"
                y="5"
                maskUnits="userSpaceOnUse"
            >
                <path
                    fill="#D9D9D9"
                    d="M28 29H52V53H28z"
                    transform="rotate(180 28 29)"
                ></path>
            </mask>
            <g mask="url(#mask0_363_19054)">
                <path
                    fill="#14142B"
                    d="M18.073 11.873a.745.745 0 111.054 1.054L15.054 17l4.073 4.073a.745.745 0 11-1.054 1.054l-4.42-4.42a1 1 0 010-1.414l4.42-4.42z"
                ></path>
            </g>
        </svg>
    );
}

export default Icon;