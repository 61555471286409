/** @format */

import ConvertToGoal from "Components/InputModalTable";
import InputsModal from "Components/InputsModal";
import React, { useState } from "react";
import * as Yup from "yup";
import { useReminderStore } from "store/reminders";
import Button from "Components/Button";
import InlineAlert from "Components/InlineAlert";
import InputModalsButton from "Components/InputModalButtons";
import Input from "Components/Input";
import { Active, InActive } from "./buttonStyles";
import "./index.css";
import { Payload, PayloadSchema } from "./inputs";
import CurrencyFormatText from "Components/CurrencyFormatText";
import { useAppStore } from "store/useApp";

const buttons = [
   { title: "Increase monthly amount", value: "increase_monthly_amount" },
   { title: "Extend deadline", value: "extend_deadline" },
];

export default function InputsComponent({ visible, onClose, onSubmit, goalDetails, fetchGoalSummary, showAmountInput }) {
   const [inputValues, setinputValues] = useState(Payload);
   const [viewMonths, setviewMonths] = useState(false);
   const validationSchema = Yup.object().shape(PayloadSchema);
   const [selected, setselected] = useState("increase_monthly_amount");
   const [enteredAmount, setenteredAmount] = useState(showAmountInput ? goalDetails?.goal_amount : 0);
   const ImpactSummaryTable = useAppStore((state) => state.impactSummaryTable);
   const { resetSummaryTable } = useAppStore((state) => state);
   console.log(ImpactSummaryTable, "ImpactSummaryTable");

   const isTableContainsData = () => ImpactSummaryTable[selected]?.length > 0;

   const checkIsValidNumber = () => {
      if (enteredAmount !== null) {
         const reg = /^(0|[1-9]\d*)(\.\d+)?$/;
         if (!reg.test(enteredAmount)) {
            return false;
         } else {
            if (enteredAmount > goalDetails.goal_amount) {
               return false;
            } else {
               return true;
            }
         }
      }
      return true;
   };

   console.log("goalDetails", goalDetails);

   const onCloseModal = () => {
      resetSummaryTable();
      onClose();
   };

   const showHelperText = () => {
      if (checkIsValidNumber()) {
         return "";
      } else if (enteredAmount > goalDetails?.goal_amount) {
         return "Payment amount should  be lesser than goal amount";
      }
      return "Please enter a valid number";
   };

   const renderGoalAmount = () => {
      const { goal_amount, amount } = goalDetails;
      if (goal_amount !== undefined) return goal_amount;
      if (amount !== undefined) return amount;
   };

   return (
      <InputsModal
         visible={visible}
         inputModalTitle={"Goal managment"}
         onClose={onCloseModal}>
         <div className="">
            <form>
               <div className="my-2 flex flex-col gap-2">
                  <div className="flex gap-2">
                     <div className="expected-expense-name">Goal name :</div>
                     <div className="expected-expense-name-value">{goalDetails?.goal_name}</div>
                  </div>
                  <div className="flex gap-2">
                     <div className="expected-expense-name">Goal amount :</div>
                     <div className="expected-expense-name-value">
                        <CurrencyFormatText value={renderGoalAmount()} />
                     </div>
                  </div>
               </div>
               {showAmountInput && (
                  <>
                     <Input
                        label={"Enter the payment amount *"}
                        type={"textInput"}
                        value={enteredAmount}
                        helperText={showHelperText()}
                        error={!checkIsValidNumber()}
                        onChange={(e) => setenteredAmount(e.target.value)}
                     />
                     <Button
                        width={"100%"}
                        color={"#fff"}
                        disabled={!checkIsValidNumber()}
                        title={"Show impact summmary table"}
                        onClick={() => fetchGoalSummary(enteredAmount)}
                     />
                  </>
               )}
               {isTableContainsData() && (
                  <>
                     <InlineAlert
                        mode={"warning"}
                        background={" rgba(255, 183, 3, 0.09)"}
                        borderColor={"#FFB703"}
                        text={
                           "Please be aware that modifying this goal amount will either increase the subsequent goal amounts or require more time to achieve the same goal amount. What would you like to do from the two options provided below?"
                        }
                     />

                     <div className="flex w-[100%] justify-start gap-2 my-2">
                        {buttons.map((button, index) => {
                           return (
                              <Button
                                 title={button.title}
                                 key={index}
                                 onClick={() => setselected(button.value)}
                                 backgroundColor={selected === button.value ? Active.backgroundColor : InActive.backgroundColor}
                                 color={selected === button.value ? Active.color : InActive.color}
                                 hoverBackgroundColor={selected === button.value ? Active.hoverBackgroundColor : InActive.hoverBackgroundColor}
                                 hoverColor={selected === button.value ? Active.hoverColor : InActive.hoverColor}
                              />
                           );
                        })}
                     </div>

                     {ImpactSummaryTable?.negative_balance_months?.[selected] > 0 && (
                        <>
                           <div className="text-[0.9rem] font-bold">Impact summary table</div>
                           <div className="flex text-[0.8rem] mt-2 gap-2">
                              <div className="balance-container">
                                 <div className="balances-text">{ImpactSummaryTable?.negative_balance_months?.[selected]} months</div>
                                 <div className="have-negative-balances-text"> have negative balances</div>
                              </div>
                              <div
                                 onClick={() => setviewMonths(!viewMonths)}
                                 className="view-months-text">
                                 {viewMonths ? "Hide" : "View"} months
                              </div>
                           </div>
                        </>
                     )}

                     <ConvertToGoal
                        data={ImpactSummaryTable[selected]}
                        viewMonths={viewMonths}
                     />
                     <div className="h-[3rem]"></div>
                  </>
               )}
            </form>
         </div>
         {isTableContainsData() && (
            <InputModalsButton
               onClose={onCloseModal}
               onSubmit={() => onSubmit(selected, enteredAmount)}
               button1Title={"Cancel"}
               button2Title={"Confirm"}
            />
         )}
      </InputsModal>
   );
}
