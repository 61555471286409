/** @format */

import React from "react";
import "./index.css";
import ProgressCircle from "Components/ProgessCircle";
import CurrencyFormatText from "Components/CurrencyFormatText";

export default function ProgressCard({ goal_name, ends_in, saved_so_far, remaining, progress }) {
   return (
      <div className="progress-card-container">
         <div className="progress-card-details-container">
            <div className="progress-card-title">{goal_name}</div>
            <div className="progress-card-ends-in">
               Ends in <div className="bold">{ends_in} months</div>
            </div>
            <div className="progress-card-saved-so-far">
               <div className="bold">
                  <CurrencyFormatText value={saved_so_far} />
               </div>{" "}
               saved so far
            </div>
            <div className="progress-card-remaining">
               <div className="bold">
                  {" "}
                  <CurrencyFormatText value={remaining} />
               </div>{" "}
               remaining
            </div>
         </div>
         <ProgressCircle progress={parseInt(progress)} />
      </div>
   );
}
