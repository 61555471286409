import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <mask
                id="mask0_147_8727"
                style={ { maskType: "alpha" } }
                width="24"
                height="24"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
            </mask>
            <g mask="url(#mask0_147_8727)">
                <path
                    fill="#14142B"
                    d="M12 20c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0110 18c0-.55.196-1.02.588-1.413A1.926 1.926 0 0112 16c.55 0 1.02.196 1.412.587.392.392.588.863.588 1.413s-.196 1.02-.588 1.413A1.926 1.926 0 0112 20zm0-6c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 0110 12c0-.55.196-1.02.588-1.412A1.926 1.926 0 0112 10c.55 0 1.02.196 1.412.588.392.391.588.862.588 1.412 0 .55-.196 1.02-.588 1.412A1.926 1.926 0 0112 14zm0-6c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 0110 6c0-.55.196-1.02.588-1.412A1.926 1.926 0 0112 4c.55 0 1.02.196 1.412.588.392.391.588.862.588 1.412 0 .55-.196 1.02-.588 1.412A1.926 1.926 0 0112 8z"
                ></path>
            </g>
        </svg>
    );
}

export default Icon;