/** @format */

import React from "react";

export default function StatsCard({ title, icon, value }) {
   return (
      <div className="bg-white  rounded-[0.8rem] h-[7rem] w-[23%] p-3">
         <div className="flex items-center gap-2">
            <div className="bg-[#D0F4DE] h-[15px] rounded-sm w-[7px]"></div>
            <h6 className="text-[#898995] text-[0.9rem]">{title}</h6>
            {icon}
         </div>
         <div className="mt-3 pl-2 font-bold text-2xl">{value}</div>
      </div>
   );
}
