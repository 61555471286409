/** @format */

import BankDropdownInput from "Components/BankDropdownInput";
import CurrencyFormatText from "Components/CurrencyFormatText";
import ImpactTable from "Components/InputModalTable";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useMonthsSummaryStore } from "store/monthsSummary";
import { formatYearAndMonth, getCurrentMonthAndYear } from "utils/dateFunctions";
import * as Yup from "yup";
import InlineAlert from "Components/InlineAlert";
import Input from "Components/Input";
import InputModalsButton from "Components/InputModalButtons";
import InputsModal from "Components/InputsModal";
import "./index.css";
import { PayloadSchema } from "./inputs";
import { useAppStore } from "store/useApp";

export default function InputsComponent({ visible, onClose, preValues }) {
   const [inputValues, setinputValues] = useState(preValues);
   const [viewMonths, setviewMonths] = useState(false);
   const validationSchema = Yup.object().shape(PayloadSchema);
   const { getImpactTableForConvertToGoal, confirmConvertToGoal, getMonthsSumary } = useAppStore((state) => state);
   const [impactSummaryTable, setimpactSummaryTable] = useState([]);

   const onSubmit = (values) => {
      const payload = {
         start_month: formatYearAndMonth(form.values.start_month),
         end_month: formatYearAndMonth(preValues.end_month),
         amount: preValues.planned_amount,
         expected_expense_name: preValues.expense_name,
         saving_in_bank_account_id: form.values.saving_in_bank_account_id,
         saving_from_bank_account_id: form.values.saving_from_bank_account_id,
         comments: form.values.comments,
      };
      confirmConvertToGoal(preValues.expected_expenses_summary_id, payload).then((res) => {
         getMonthsSumary(getCurrentMonthAndYear()).then(() => {
            onClose();
         });
      });
      console.log(values, "onSubmit");
   };

   console.log("preValues", preValues);

   const form = useFormik({
      initialValues: inputValues,
      validationSchema: validationSchema,
      onSubmit: onSubmit,
      enableReinitialize: true,
   });

   useEffect(() => {
      getImpactTable();
   }, [form.values.start_month]);

   const getImpactTable = () => {
      const payload = {
         start_month: formatYearAndMonth(form.values.start_month),
         end_month: formatYearAndMonth(preValues.end_month),
         amount: preValues.planned_amount,
      };
      console.log("getImpactTable", payload);
      getImpactTableForConvertToGoal(payload).then((res) => {
         console.log("getImpactTableForConvertToGoal", res);
         setimpactSummaryTable(res);
      });
   };

   useEffect(() => {
      getImpactTable();
   }, [form.values.start_month]);

   const getNegativeMonths = () => {
      let count = 0;
      impactSummaryTable.map((item) => item.expected_monthly_balance < 0 && count++);
      return count;
   };

   console.log(form.errors, "form.errors");

   return (
      <InputsModal
         visible={visible}
         inputModalTitle={"Convert to goal"}
         onClose={onClose}>
         <div className="input-modal-inputs-container">
            <form onSubmit={form.handleSubmit}>
               <div className="inputs-container">
                  <div className="input-header-row">
                     <div className="expected-expense-name">Expected expense name</div>
                     <div className="expected-expense-name-value">{preValues?.expense_name}</div>
                  </div>
               </div>
               <Input
                  type={"datePicker"}
                  label={"Start month *"}
                  name={"start_month"}
                  id={"start_month"}
                  value={form.values.start_month}
                  minDate={new Date()}
                  maxDate={new Date(preValues.end_month)}
                  setDateValue={(val) => form.setFieldValue("start_month", val)}
               />
               <BankDropdownInput
                  id={"saving_from_account"}
                  label={"Transfer amount from"}
                  placeholder={"Transfer amount from  *"}
                  type={"dropDown"}
                  onChange={form.handleChange}
                  error={form.touched?.["saving_from_account"] && Boolean(form.errors?.["saving_from_account"])}
                  helperText={form.touched?.["saving_from_account"] && form.errors?.["saving_from_account"]}
                  value={form.values?.["saving_from_account"]}
                  form={form}
                  setDropDownValue={(val) => {
                     form.setFieldValue("saving_from_account", val?.bank_name);
                     form.setFieldValue("saving_from_bank_account_id", val?.bank_account_id);
                     form.setFieldValue("saving_in_account", "");
                     form.setFieldValue("saving_in_bank_account_id", "");
                  }}
               />
               <BankDropdownInput
                  id={"saving_in_account"}
                  label={"Where do you want to save for this goal  *"}
                  placeholder={"Where do you want to save for this goal  *"}
                  type={"dropDown"}
                  removedBankId={form.values?.["saving_from_bank_account_id"]}
                  onChange={form.handleChange}
                  error={form.touched?.["saving_in_account"] && Boolean(form.errors?.["saving_in_account"])}
                  helperText={form.touched?.["saving_in_account"] && form.errors?.["saving_in_account"]}
                  value={form.values?.["saving_in_account"]}
                  form={form}
                  setDropDownValue={(val) => {
                     form.setFieldValue("saving_in_account", val?.bank_name);
                     form.setFieldValue("saving_in_bank_account_id", val?.bank_account_id);
                  }}
               />
               <Input
                  id={"comments"}
                  label={"Comments"}
                  placeholder={"Comments"}
                  type={"textInput"}
                  onChange={form.handleChange}
                  error={form.touched?.["comments"] && Boolean(form.errors?.["comments"])}
                  helperText={form.touched?.["comments"] && form.errors?.["comments"]}
                  value={form.values?.["comments"]}
                  form={form}
               />
               <div className="inputs-container">
                  <div className="input-header-row">
                     <div className="expected-expense-name">Expected goal amount per month </div>
                     <div
                        style={{ color: "#1877F2" }}
                        className="expected-expense-name-value">
                        <CurrencyFormatText value={impactSummaryTable[0]?.amount} />
                     </div>
                  </div>
               </div>
               {getNegativeMonths() > 0 ? (
                  <InlineAlert
                     mode={"warning"}
                     background={" rgba(255, 183, 3, 0.09)"}
                     borderColor={"#FFB703"}
                     text={"Your monthly balance falls below zero for one or more months if you choose to add this goal amount. You can see the impact in the table below"}
                  />
               ) : (
                  <InlineAlert
                     mode={"info"}
                     background={"rgba(18, 183, 105, 0.09)"}
                     borderColor={"#12B769"}
                     text={"You have enough funds in each of the months to cover the goal amount."}
                  />
               )}
               {getNegativeMonths() > 0 && (
                  <>
                     <div className="text-[0.9rem] font-bold">Impact summary table</div>
                     <div className="flex text-[0.8rem] mt-2 gap-2">
                        <div className="balance-container">
                           <div className="balances-text">{getNegativeMonths()} months</div>
                           <div className="have-negative-balances-text"> have negative balances</div>
                        </div>
                        <div
                           onClick={() => setviewMonths(!viewMonths)}
                           className="view-months-text">
                           {viewMonths ? "Hide" : "View"} months
                        </div>
                     </div>
                  </>
               )}

               <ImpactTable
                  viewMonths={viewMonths}
                  data={impactSummaryTable}
               />
               <InputModalsButton
                  onClose={onClose}
                  button1Title={"Cancel"}
                  button2Title={"Confirm"}
               />
            </form>
         </div>
      </InputsModal>
   );
}
