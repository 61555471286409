/** @format */

import DeleteModal from "Components/DeleteModal";
import FullWidthSearchContainer from "Components/FullWidthSearchContainer";
import ReminderGoalsTable from "Components/Reminders/ReminderGoalsTable";
import ScreenContainer from "Components/ScreenContainer";
import TabWrapper from "Components/TabWrapper";
import TransferSummary from "Components/TransferSummary";
import React, { useEffect, useState } from "react";
import { useReminderStore } from "store/reminders";
import { useSearchAndFilterStore } from "store/searchAndFilter";
import { changeDateFormat, getCurrentMonthAndYear } from "utils/dateFunctions";
import "./index.css";
import { useAppStore } from "store/useApp";

const searchableKeys = ["goal_name", "transfer_from", "transfer_to"];
const searchableKeys2 = ["goal_transfer_summaries", "transfer_from", "transfer_to"];
const dateFilters = [];
const searchPlaceholder = "Search by goal name or transfer account";
const nestedSearchableKeys = ["goal_name"];

const breadCrumbsData = [
   {
      title: "Reminders",
      link: "/",
   },
   {
      title: "Reminders",
      link: "/reminders",
   },
];

export default function Income() {
   const ReminderStore = useAppStore();
   const { getTransferSummary } = ReminderStore;
   const [tabIndex, settabIndex] = useState(0);
   const [displayData, setdisplayData] = useState(ReminderStore?.ongoingGoals);
   const { setSearchData, getTableData, onSearchQueryChange, searchQuery, allData } = useAppStore((state) => state);
   const [showTransferSummary, setshowTransferSummary] = useState(false);

   useEffect(() => {
      fetchReminders();
      fetchTransferSumary();
   }, []);

   const fetchTransferSumary = () => {
      getTransferSummary(getCurrentMonthAndYear())
         .then()
         .catch((err) => console.log(err));
   };
   const fetchReminders = () => {
      ReminderStore.getAllReminders(getCurrentMonthAndYear())
         .then()
         .catch((err) => console.log(err));
   };

   useEffect(() => {
      if (showTransferSummary) {
         setdisplayData(ReminderStore.transferSummary);
      } else {
         if (tabIndex === 0) {
            setdisplayData(ReminderStore?.ongoingGoals);
         } else if (tabIndex === 1) {
            setdisplayData(ReminderStore?.completedGoals);
         } else if (tabIndex === 2) {
            setdisplayData(ReminderStore?.skippedGoals);
         }
      }
   }, [ReminderStore, tabIndex, showTransferSummary]);

   useEffect(() => {
      updateSearchData();
   }, [displayData]);

   const setCurrentPageKey = () => {
      let key;
      if (tabIndex === 0) {
         key = "ongoingGoals";
      } else if (tabIndex === 1) {
         key = "completedGoals";
      } else {
         key = "skippedGoals";
      }
      ReminderStore.setCurrentPageKey(key);
   };

   useEffect(() => {
      setCurrentPageKey();
   }, [tabIndex]);

   const updateSearchData = () => {
      setSearchData(displayData, showTransferSummary ? searchableKeys2 : searchableKeys, searchPlaceholder, dateFilters, showTransferSummary ? nestedSearchableKeys : []);
      onSearchQueryChange(searchQuery);
   };

   const toggleTransferSummary = () => setshowTransferSummary(!showTransferSummary);

   return (
      <div>
         <ScreenContainer breadCrumbsData={breadCrumbsData}>
            <DeleteModal
               visible={false}
               // warningMessage={'By deleting this source the income summary of the upcoming moth will be affected'}
               deleteMessage={"Are you sure you want to delete this income source?. You can’t undo this action."}
            />
            <div className="reminder-month">{changeDateFormat(new Date())}</div>
            <FullWidthSearchContainer
               createButtonText={showTransferSummary ? "View detailed summary" : "View transfer summary"}
               onPressCreate={toggleTransferSummary}
            />
            <div className="w-[98%]">
               {showTransferSummary ? (
                  <TransferSummary data={getTableData()} />
               ) : (
                  <>
                     <TabWrapper
                        tabIndex={tabIndex}
                        onChangeTab={(index) => settabIndex(index)}
                        tabs={["Ongoing goals", "Completed goals", "Skipped goals"]}
                     />
                     <ReminderGoalsTable
                        data={getTableData()}
                        tabIndex={tabIndex}
                     />
                  </>
               )}
            </div>
         </ScreenContainer>
      </div>
   );
}
