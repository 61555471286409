/** @format */
import { isDateInRange } from "utils/dateFunctions";
import * as _ from "lodash";
import { useCallback } from "react";

export const useSearchAndFilterStore = (set, get) => {
   return {
      currentPageKey: "",
      searchableKeys: [],
      nestedSearchableKeys: [],
      searchPlaceholder: "",
      searchQuery: "",
      searchResults: [],
      selectedYearFilters: [],
      selectedMonthFilters: [],
      dateFilters: [],
      filteredData: [],
      setCurrentPageKey: (val) => {
         set({ currentPageKey: val });
      },
      setSearchData: (allData, searchableKeys, searchPlaceholder, dateFilters, nestedSearchableKeys) => {
         console.log("setSearchData", allData, searchableKeys, searchPlaceholder, dateFilters, nestedSearchableKeys);
         set({
            searchableKeys,
            searchPlaceholder,
            dateFilters,
            nestedSearchableKeys,
         });
         get().onChangeFilter();
      },
      onSearchQueryChange: (searchQuery) => {
         set({ searchQuery });
         console.log("onSearchQueryChange", get);
         const { searchableKeys, nestedSearchableKeys } = get();
         const allData = get()[get().currentPageKey];
         let results = [];
         if (searchQuery !== "") {
            searchableKeys.map((key) => {
               allData.map((item) => {
                  console.log(item, key, "item, key", searchQuery);
                  if (Array.isArray(item[key])) {
                     const nestedArray = item[key];
                     nestedSearchableKeys.map((nestedSearchKey) => {
                        nestedArray.map((nestedItem) => {
                           console.log(nestedSearchKey, nestedItem, "nestedSearchKey, nestedItem");
                           if (nestedItem[nestedSearchKey].toLowerCase().includes(searchQuery.toLowerCase())) {
                              results.push(item);
                           }
                        });
                     });
                  } else if (item[key]?.toLowerCase().includes(searchQuery.toLowerCase())) {
                     results.push(item);
                  }
               });
            });
         }
         results = _.uniqWith(results, _.isEqual);
         set({ searchResults: results });
      },
      addOrRemoveFilter: (value, filterType) => {
         console.log(value, filterType, "addOrRemoveFilter");
         let filterKey = filterType === "year" ? "selectedYearFilters" : "selectedMonthFilters";
         let exisiting = [...get()[filterKey]];
         if (exisiting.includes(value)) {
            const index = exisiting.findIndex((item) => item === value);
            exisiting.splice(index, 1);
         } else {
            exisiting.push(value);
         }
         set({ [filterKey]: exisiting });
         get().onChangeFilter();
      },
      onChangeFilter: () => {
         const { selectedYearFilters, selectedMonthFilters, dateFilters } = get();
         const allData = get()[get().currentPageKey];
         if (allData?.length > 0) {
            let filteredData = allData.map((item) => {
               console.log(typeof item, "item onChangeFilter", dateFilters, get());
               const startDate = new Date(item[dateFilters[0]]);
               const endDate = new Date(item[dateFilters[1]]);

               // Check if either the start_date or end date falls within the selected years and months
               if (isDateInRange(startDate, selectedYearFilters, selectedMonthFilters) || isDateInRange(endDate, selectedYearFilters, selectedMonthFilters)) {
                  return item;
               }
            });
            filteredData = filteredData.filter(Boolean);
            set({ filteredData });
         }
      },
      isFilterOrSearchApplied: () => {
         const { selectedMonthFilters, selectedYearFilters, searchQuery } = get();
         if (selectedYearFilters.length > 0 || selectedMonthFilters.length > 0) {
            return true;
         } else if (searchQuery !== "") {
            return true;
         }
      },
      isResultsFounded: () => {
         const { searchResults, filteredData } = get();
         return searchResults.length > 0 || filteredData.length > 0;
      },
      getTableData: () => {
         const { searchResults, filteredData, searchQuery } = get();
         if (searchQuery !== "") {
            return searchResults;
         } else if (filteredData.length > 0) {
            return filteredData;
         }
         console.log(" getTableData", get().currentPageKey);
         return get()[get().currentPageKey];
      },
      clearFiltersYearAndMonth: () => {
         set({
            selectedMonthFilters: [],
            selectedYearFilters: [],
         });
      },
   };
};
