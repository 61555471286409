import React from 'react';
import { Grid, Box } from '@mui/material';
import './index.css';
import NoOfEntries from './NoOfEntries';
import RightArrowIcon from '../../icons/RightArrowIcon';
import LeftArrowIcon from '../../icons/LeftArrowIcon';

export default function Pagination( {
    currentPage,
    totalPages,
    onPressNext,
    onPressPrevious,
    setPageIndex,
    pageSize,
    setPageSize
} ) {
    return (
        <Grid marginTop={ '0.5rem' } gap={ '1rem' } container justifyContent='flex-end' alignItems='center' >
            <Grid borderRadius={ 1 } gap={ '0.5rem' } item className='entries-page-container' alignItems='center' >
                <NoOfEntries
                    setPageIndex={ setPageIndex }
                    pageSize={ pageSize }
                    setPageSize={ setPageSize }
                />
                <div>Entries per page</div>
            </Grid>
            <Grid gap={ '0.5rem' } borderRadius={ 1 } item className='entries-page-container' alignItems='center' >
                <LeftArrowIcon onClick={ onPressPrevious } />
                <div>{ currentPage }/{ totalPages }</div>
                <RightArrowIcon onClick={ onPressNext } />
            </Grid>
        </Grid>
    );
}
