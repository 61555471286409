/** @format */

import React from "react";
import Button from "../Button";
import "./index.css";

export default function index({ onClose, button1Title, button2Title, onSubmit }) {
   return (
      <div className="input-modal-btns-container">
         {button1Title !== "" && (
            <Button
               onClick={onClose}
               title={button1Title}
               backgroundColor={"#E8E8EA"}
               color={"#14142B"}
               hoverColor={"#E8E8EA"}
               hoverBackgroundColor={"#14142B"}
            />
         )}
         <Button
            title={button2Title}
            type={"submit"}
            backgroundColor={"#500472"}
            color={"#ffffff"}
            hoverBackgroundColor={"#42045E"}
            hoverColor={"#ffffff"}
            onClick={onSubmit}
         />
      </div>
   );
}
