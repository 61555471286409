/** @format */

import * as React from "react";
import Modal from "@mui/material/Modal";
import "./index.css";
import Grow from "@mui/material/Grow";

const style = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: 400,
   bgcolor: "background.paper",
   border: "2px solid #000",
   boxShadow: 24,
   p: 4,
};

export default function CenterModal({ visible, children, onClose }) {
   return (
      <Modal
         open={visible}
         onClose={onClose}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description">
         <Grow in={visible}>
            <div className="center-modal-container">
               <div>{children}</div>
            </div>
         </Grow>
      </Modal>
   );
}
