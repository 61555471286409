/** @format */

import CurrencyFormatText from "Components/CurrencyFormatText";
import HighlightText from "Components/HighlightText";
import React, { useEffect } from "react";
import { useSearchAndFilterStore } from "store/searchAndFilter";
import ChooseYearAndMonth from "Components/ChooseYearAndMonth";
import DeleteModal from "Components/DeleteModal";
import FullWidthSearchContainer from "Components/FullWidthSearchContainer";
import IncomeSummaryCell from "Components/IncomeSummaryCells/IncomeSource";
import NoData from "Components/NoData";
import ScreenContainer from "Components/ScreenContainer";
import Table from "Components/Table";
import { useIncomeStore } from "store/income";
import { changeDateFormat } from "utils/dateFunctions";
import { useAppStore } from "store/useApp";
import { useSearchParams } from "react-router-dom";

const searchableKeys = ["income_name", "notes"];
const dateFilters = ["month", "month"];
const searchPlaceholder = "Search by income source or notes";

const breadCrumbsData = [
   {
      title: "Income details",
      link: "/",
   },
   {
      title: "Income summary",
      link: "/income_summary",
   },
];

export default function Income() {
   const incomeStore = useAppStore((state) => state);
   console.log("useAppStoresummary", useAppStore());
   const getIncome = useAppStore().getIncome;
   console.log(getIncome, "IncomeStore");
   const { setSearchData, isFilterOrSearchApplied, isResultsFounded, getTableData } = useAppStore((state) => state);
   const [searchParams, setSearchParams] = useSearchParams();

   const columnsData = [
      {
         header: "income_summary_id",
         accessorKey: "income_summary_id",
      },
      {
         header: "Income source",
         accessorKey: "income_name",
         cell: (value) => <IncomeSummaryCell value={value?.getValue()} />,
      },
      {
         header: "month",
         accessorKey: "month",
         cell: (value) => changeDateFormat(value?.getValue()),
      },
      {
         header: "Amount (In ₹)",
         accessorKey: "amount",
         cell: (value) => <CurrencyFormatText value={value?.getValue()} />,
      },
      {
         header: "Account",
         accessorKey: "bank",
      },
      {
         header: "Notes",
         accessorKey: "notes",
         cell: (value) => <HighlightText value={value?.getValue()} />,
      },
   ];

   const fetchIncomeSummary = () => {
      incomeStore
         .getIncomeSummary()
         .then((res) => {
            setSearchData(res, searchableKeys, searchPlaceholder, dateFilters);
         })
         .catch((err) => console.log(err.message));
   };

   useEffect(() => {}, []);

   useEffect(() => {
      incomeStore.setCurrentPageKey("incomeSummary");
      fetchIncomeSummary();
   }, []);

   useEffect(() => {
      incomeStore.onChangeFilter();
   }, [incomeStore?.incomeSummary]);

   const showTable = () => {
      if (incomeStore.incomeSummary.length > 0) {
         if (isFilterOrSearchApplied()) {
            return isResultsFounded();
         } else {
            return true;
         }
      } else {
         return false;
      }
   };

   // useEffect(() => {
   //    incomeStore.onChangeFilter();
   // }, [getTableData()]);

   console.log(getTableData(), "GETATBLEDATA");

   return (
      <div>
         <ScreenContainer breadCrumbsData={breadCrumbsData}>
            <DeleteModal
               visible={false}
               deleteMessage={"Are you sure you want to delete this income source?. You can’t undo this action."}
            />
            <FullWidthSearchContainer createButtonText="" />
            <ChooseYearAndMonth />
            {!showTable() ? (
               <NoData
                  buttonText={""}
                  message={"No income summary found"}
               />
            ) : (
               <Table
                  tableData={getTableData()}
                  columnsData={columnsData}
                  hideColumn={"income_summary_id"}
               />
            )}
         </ScreenContainer>
      </div>
   );
}
