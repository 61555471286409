/** @format */

import React from "react";
import DeleteIcon from "icons/DeleteIcon";
import EditIcon from "icons/EditIcon";
import GoalsIcon from "icons/GoalsIcon";
import MoreOptionIcon from "icons/MoreOptionIcon";
import PlusIcon from "icons/PlusIcon";
import TickIcon from "icons/TickIcon";
import UndoIcon from "icons/UndoIcon";
import "./index.css";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import { Tooltip } from "@mui/material";

export default function Actions({
   showEdit,
   showDelete,
   showMoreOption,
   showPlusIcon,
   showUndoIcon,
   showTickIcon,
   showGoalsIcon,
   showSkipIcon,
   onClickPlusIcon,
   onClickEditIcon,
   onClickSkipIcon,
   onClickConvertToGoal,
   onClickDeleteIcon,
   disableGoalsIcon,
   disablePlusIcon,
   disableEditIcon,
   disableSkipIcon,
}) {
   return (
      <div className="actions-container">
         {showPlusIcon && (
            <div
               style={{ opacity: disablePlusIcon ? 0.5 : 1 }}
               onClick={() => !disablePlusIcon && onClickPlusIcon()}
               className="action">
               <PlusIcon />
            </div>
         )}
         {showUndoIcon && (
            <div className="action">
               <UndoIcon />
            </div>
         )}
         {showTickIcon && (
            <div className="action">
               <TickIcon />
            </div>
         )}
         {showGoalsIcon && (
            <div
               style={{ opacity: disableGoalsIcon ? 0.5 : 1 }}
               onClick={() => !disableGoalsIcon && onClickConvertToGoal()}
               className="action">
               <GoalsIcon disabled={disableGoalsIcon} />
            </div>
         )}
         {showEdit && (
            <div
               style={{ opacity: disableEditIcon ? 0.5 : 1 }}
               onClick={() => !disableEditIcon && onClickEditIcon()}
               className="action">
               <EditIcon color={"#1877F2"} />
            </div>
         )}
         {showSkipIcon && (
            <Tooltip title="Skip goal">
               <div
                  style={{ opacity: disableSkipIcon ? 0.5 : 1 }}
                  onClick={() => !disableSkipIcon && onClickSkipIcon()}
                  className="action">
                  <SkipNextIcon />
               </div>
            </Tooltip>
         )}
         {showDelete && (
            <div
               onClick={onClickDeleteIcon}
               className="action">
               <DeleteIcon />
            </div>
         )}
         {showMoreOption && (
            <div className="action">
               <MoreOptionIcon />
            </div>
         )}
      </div>
   );
}
