/** @format */

import { RadioGroup } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import BankDropdownInput from "Components/BankDropdownInput";
import Button from "Components/Button";
import dayjs from "dayjs";
import { APP_MODE } from "definition";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useBankStore } from "store/bank";
import { generateAmount, generateNumbers } from "utils/stringFunction";
import * as Yup from "yup";
import CheckBoxInput from "Components/CheckBoxInput";
import HighlightedInputsContainer from "Components/HighlightedInputsContainer";
import InlineAlert from "Components/InlineAlert";
import Input from "Components/Input";
import InputModalsButton from "Components/InputModalButtons";
import InputsModal from "Components/InputsModal";
import { useExpectedExpensesStore } from "store/expectedExpenses";
import "./index.css";
import { Inputs, Payload, PayloadSchema } from "./inputs";
import { getDateAfterGivenMonths } from "utils/dateFunctions";
import { useAppStore } from "store/useApp";

export default function InputsComponent({ visible, onClose, editMode, preValues }) {
   const [inputValues, setinputValues] = useState(editMode ? preValues : Payload);
   const [viewAll, setviewAll] = useState(false);
   const ExpectedExpensesStore = useAppStore();
   const Banks = useAppStore((state) => state.banks);

   const validationSchema = Yup.object().shape(PayloadSchema);

   const onSubmit = (values) => {
      console.log(values, "On submit values");
      const payload = generatePayload(values);
      editMode ? UpdateExpectedExpense({ ...payload, expected_expenses_id: values?.expected_expenses_id }) : AddExpectedExpense(payload);
   };

   const AddExpectedExpense = (payload) => {
      ExpectedExpensesStore.addExpectedExpense(payload)
         .then((res) => resetForm())
         .catch((err) => console.log(err.message));
   };

   const UpdateExpectedExpense = (payload) => {
      ExpectedExpensesStore.updateExpectedExpense(payload)
         .then((res) => resetForm())
         .catch((err) => console.log(err.message));
   };

   const resetForm = () => {
      form.resetForm();
      onClose();
   };

   useEffect(() => {
      if (editMode) {
         setinputValues(preValues);
      } else {
         setinputValues(Payload);
      }
   }, [editMode, preValues]);

   const form = useFormik({
      initialValues: inputValues,
      validationSchema: validationSchema,
      onSubmit: onSubmit,
      enableReinitialize: true,
   });

   const generatePayload = (values) => {
      return {
         expense_type: values.expense_type || "",
         from_month: dayjs(values.from_month).format("YYYY-MM-DD") || "",
         to_month: values.recurring_expense ? dayjs(values.to_month).format("YYYY-MM-DD") || "" : null,
         amount: parseFloat(values.amount) || 0,
         bank: values.bank || "",
         reason: values.reason || "",
         recurring_expense: values.recurring_expense || false,
         bank_account_id: values.bank_account_id || "",
      };
   };

   const handleFillData = () => {
      form.setFieldValue("expense_type", "Fixed");
      form.setFieldValue("reason", `Expected expense ${generateNumbers()}`);
      form.setFieldValue("amount", generateAmount());
      form.setFieldValue("bank", Banks[0].label);
      form.setFieldValue("bank_account_id", Banks[0].value?.bank_account_id);
      form.setFieldValue("from_month", dayjs().format("YYYY-MM-DD"));
      form.setFieldValue("to_month", dayjs().format("YYYY-MM-DD"));
   };

   const modifiedMonths = ["Jan 2024", "Feb 2024", "Mar 2024", "Apr 2024"];
   const slicedMonths = viewAll ? modifiedMonths?.slice() : modifiedMonths?.slice(0, 2);

   console.log(form.values, "expected expense values");
   return (
      <InputsModal
         visible={visible}
         inputModalTitle={"New expected expense"}
         onClose={onClose}>
         <div className="input-modal-inputs-container">
            {!editMode && APP_MODE === "development" && (
               <Button
                  title={"Fill mode (dev mode)"}
                  onClick={handleFillData}
               />
            )}
            <form onSubmit={form.handleSubmit}>
               <div className="inputs-container">
                  {false && (
                     <InlineAlert
                        mode="info"
                        text={"The following months have been modified in the summary, do you wish to retain the existing value or do you wish to over ride them?"}
                        borderColor={"#1877F2"}
                        background={"rgba(24, 119, 242, 0.1)"}>
                        <div className="modified-months-container">
                           <div className="modified-months-text">Modified months :</div>
                           {slicedMonths.map((month, index) => {
                              return <div className="modified-months">{month}</div>;
                           })}
                           <div
                              className="view-all-text"
                              onClick={() => setviewAll(!viewAll)}>
                              View {viewAll ? "less" : "all"}
                           </div>
                        </div>
                        <div className="choose-action-container">
                           <div className="choose-action-text">Choose action :</div>
                           <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={"male"}
                              sx={{ flexDirection: "row" }}
                              onChange={(event) => console.log(event.target.value)}>
                              <FormControlLabel
                                 value="override"
                                 control={<Radio color={"secondary"} />}
                                 label="Override"
                              />
                              <FormControlLabel
                                 value="retain"
                                 control={<Radio color={"secondary"} />}
                                 label="Retain"
                              />
                           </RadioGroup>
                        </div>
                     </InlineAlert>
                  )}
                  {Inputs?.map((input, index) => {
                     if (input?.customComponent) {
                        return (
                           <HighlightedInputsContainer>
                              <div>
                                 <CheckBoxInput
                                    title={"This is a recurring expense"}
                                    value={form.values?.["recurring_expense"]}
                                    onChange={(value) => form.setFieldValue("recurring_expense", value)}
                                 />
                                 <Input
                                    id={"from_month"}
                                    label={"From month"}
                                    placeholder={"From month"}
                                    minDate={dayjs()}
                                    type={"datePicker"}
                                    options={input?.options}
                                    setDateValue={(value) => {
                                       form.setFieldValue("from_month", value);
                                       form.setFieldValue("to_month", getDateAfterGivenMonths(value, 12));
                                    }}
                                    error={form.touched?.["from_month"] && Boolean(form.errors?.["from_month"])}
                                    helperText={form.touched?.["from_month"] && form.errors?.["from_month"]}
                                    value={form.values?.["from_month"]}
                                 />
                                 {form.values?.["recurring_expense"] && (
                                    <Input
                                       id={"to_month"}
                                       label={"to month"}
                                       placeholder={"to month"}
                                       type={"datePicker"}
                                       options={input?.options}
                                       minDate={form.values?.["from_month"]}
                                       maxDate={getDateAfterGivenMonths(form.values?.["from_month"], 24)}
                                       setDateValue={(value) => form.setFieldValue("to_month", value)}
                                       error={form.touched?.["to_month"] && Boolean(form.errors?.["to_month"])}
                                       helperText={form.touched?.["to_month"] && form.errors?.["to_month"]}
                                       value={form.values?.["to_month"]}
                                    />
                                 )}
                              </div>
                           </HighlightedInputsContainer>
                        );
                     } else if (input.key == "bank") {
                        return (
                           <BankDropdownInput
                              id={"bank"}
                              label={"Choose account"}
                              placeholder={"Choose account"}
                              onChange={form.handleChange}
                              error={form.touched?.["bank"] && Boolean(form.errors?.["bank"])}
                              helperText={form.touched?.["bank"] && form.errors?.["bank"]}
                              value={form.values?.["bank"]}
                              form={form}
                              setDropDownValue={(val) => {
                                 console.log("bank setDropDownValue", val);
                                 form.setFieldValue("bank", val?.bank_name);
                                 form.setFieldValue("bank_account_id", val?.bank_account_id);
                              }}
                           />
                        );
                     } else {
                        return (
                           <Input
                              id={input.key}
                              label={input.label}
                              placeholder={input.placeholder}
                              type={input.type}
                              options={input?.options}
                              onChange={form.handleChange}
                              error={form.touched?.[input.key] && Boolean(form.errors?.[input.key])}
                              helperText={form.touched?.[input.key] && form.errors?.[input.key]}
                              value={form.values?.[input.key]}
                              form={form}
                              currencyMode={input.key === "amount" && true}
                              setDropDownValue={() => {}}
                           />
                        );
                     }
                  })}
               </div>
               <InputModalsButton
                  onClose={onClose}
                  button1Title={"Cancel"}
                  button2Title={editMode ? "Update" : "Create"}
               />
            </form>
         </div>
      </InputsModal>
   );
}
