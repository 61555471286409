/** @format */

import React from "react";
import "./index.css";
import SearchingData from "../../vectors/SearchingData";
import Button from "../Button";
import AddIcon from "@mui/icons-material/Add";
import { useSearchAndFilterStore } from "store/searchAndFilter";
import { useAppStore } from "store/useApp";

export default function NoData({ message, buttonText, onClickButton }) {
   const { isFilterOrSearchApplied } = useAppStore((state) => state);

   const renderCreateButton = () => {
      return !isFilterOrSearchApplied();
   };

   return (
      <div className="no-data-container">
         <SearchingData />
         <div className="no-data-container-message">{message}</div>
         {buttonText !== "" && renderCreateButton() && (
            <div>
               <Button
                  title={buttonText}
                  onClick={onClickButton}
                  startIcon={<AddIcon />}
                  backgroundColor={"#500472"}
                  color={"#ffffff"}
                  hoverBackgroundColor={"#42045E"}
                  hoverColor={"#ffffff"}
               />
            </div>
         )}
      </div>
   );
}
