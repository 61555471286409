/** @format */

import React from "react";
import Tooltip from "../Components/Tooltip";

function Icon() {
   return (
      <Tooltip
         placement={"top"}
         title={"Revive"}
         toolTipBackgroundColor={"#FFB703"}
         toolTipFontColor={"#000000"}>
         <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="24"
            fill="none"
            viewBox="0 0 24 24">
            <mask
               id="mask0_492_17103"
               style={{ maskType: "alpha" }}
               width="24"
               height="24"
               x="0"
               y="0"
               maskUnits="userSpaceOnUse">
               <path
                  fill="#D9D9D9"
                  d="M0 0H24V24H0z"></path>
            </mask>
            <g mask="url(#mask0_492_17103)">
               <path
                  fill="#FFB703"
                  d="M7.202 18.75v-1.5h6.85c1.12 0 2.092-.356 2.915-1.067.823-.712 1.235-1.606 1.235-2.683 0-1.077-.412-1.971-1.235-2.683-.823-.711-1.795-1.067-2.915-1.067H7.155l2.85 2.85-1.053 1.054L4.298 9l4.654-4.654L10.005 5.4l-2.85 2.85h6.897c1.54 0 2.866.501 3.98 1.503 1.113 1.002 1.67 2.25 1.67 3.747 0 1.496-.557 2.745-1.67 3.747-1.114 1.002-2.44 1.503-3.98 1.503h-6.85z"></path>
            </g>
         </svg>
      </Tooltip>
   );
}

export default Icon;
