import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export default function Component( { toolTipBackgroundColor, toolTipFontColor, children, title,placement } ) {

    const HtmlTooltip = styled( ( { className, ...props } ) => (
        <Tooltip placement={ placement } { ...props } classes={ { popper: className } } />
    ) )( ( { theme } ) => ( {
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: toolTipBackgroundColor,
            color: toolTipFontColor,
            fontSize: theme.typography.pxToRem( 12 ),
            border: '1px solid #dadde9',
            borderRadius: '1rem',
        },
    } ) );

    return (
        <HtmlTooltip title={ title }  >
            { children }
        </HtmlTooltip>
    );
}
