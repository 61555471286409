/** @format */

import CenterModal from "Components/CenterModal";
import TextInput from "Components/Input";
import InputModalButtons from "Components/InputModalButtons";
import { useFormik } from "formik";
import React from "react";
import { useMonthsSummaryStore } from "store/monthsSummary";
import { changeDateFormat, getCurrentMonthAndYear } from "utils/dateFunctions";
import * as Yup from "yup";
import { useAppStore } from "store/useApp";

export const PayloadSchema = {
   planned_amount: Yup.number("Please enter number").typeError("Amount must be a number").moreThan(0).required("This field is required"),
};

const inputs = [
   {
      label: "Planned amount",
      id: "planned_amount",
   },
];

export default function Index({ data, onClose }) {
   const validationSchema = Yup.object().shape(PayloadSchema);
   const { updateExpectedExpenseSummary, getMonthsSumary } = useAppStore((state) => state);

   const onSubmit = (values) => {
      const { expected_expenses_summary_id } = data;
      updateExpectedExpenseSummary(expected_expenses_summary_id, values)
         .then((res) => {
            getMonthsSumary(changeDateFormat(data?.month)).then(() => {
               onClose();
            });
         })
         .catch();
   };

   const inputValues = {
      planned_amount: data.planned_amount,
   };

   const form = useFormik({
      initialValues: inputValues,
      validationSchema: validationSchema,
      onSubmit: onSubmit,
      enableReinitialize: true,
   });

   return (
      <CenterModal
         visible={true}
         onClose={onClose}>
         <div className="w-full">
            <div className="text-[#500472] font-bold text-[1.5rem]">Edit expected expense</div>
            <div className="flex flex-col gap-2 mt-5 ">
               <div>
                  You are changing the planned amount for <span className="font-semibold">{data?.expense_name}</span>
                  {"  "}on <span className="font-semibold">{changeDateFormat(data.month)}</span>
               </div>
            </div>
            <form onSubmit={form.handleSubmit}>
               {inputs.map((item, index) => {
                  return (
                     <TextInput
                        type={"textInput"}
                        label={item.label}
                        id={item.id}
                        error={form.errors[item.id]}
                        touched={form.touched[item.id]}
                        value={form.values[item.id]}
                        onChange={form.handleChange}
                        key={index}
                        helperText={form.errors[item.id] && form.errors[item.id]}
                     />
                  );
               })}
               <div className="bg-[red] mt-[4rem]">
                  <InputModalButtons
                     button1Title={"Cancel"}
                     onClose={onClose}
                     button2Title={"Update"}
                  />
               </div>
            </form>
         </div>
      </CenterModal>
   );
}
