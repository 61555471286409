export const InActiveTab = {
    color: '#898995',
    backgroundColor: 'transparent',
    hoverBackgroundColor: 'transparent',
    hoverColor: '#14142B',
};

export const ActiveTab = {
    color: '#ffffff',
    backgroundColor: '#500472',
    hoverBackgroundColor: '#42045E',
    hoverColor: '#ffffff',
};  
