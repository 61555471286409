/** @format */

import HighlightText from "Components/HighlightText";
import NoData from "Components/NoData";
import React, { useEffect, useState } from "react";
import { useSearchAndFilterStore } from "store/searchAndFilter";
import Actions from "Components/Actions";
import ChooseYearAndMonth from "Components/ChooseYearAndMonth";
import DeleteModal from "Components/DeleteModal";
import ExpenseAmount from "Components/ExpectedExpenseCells/ExpenseAmount";
import ExpenseType from "Components/ExpectedExpenseCells/ExpenseType";
import FullWidthSearchContainer from "Components/FullWidthSearchContainer";
import ScreenContainer from "Components/ScreenContainer";
import Table from "Components/Table";
import { useExpectedExpensesStore } from "store/expectedExpenses";
import { changeDateFormat } from "utils/dateFunctions";
import InputModal from "./Inputs";
import { useAppStore } from "store/useApp";

const breadCrumbsData = [
   {
      title: "Expense details",
      link: "/",
   },
   {
      title: "Expected expenses",
      link: "/expected_expenses",
   },
];

const searchableKeys = ["expense_type", "reason", "bank"];
const dateFilters = ["from_month", "to_month"];
const searchPlaceholder = "Search by reason source or expense type";

export default function Income() {
   const [showInputModal, setshowInputModal] = useState(false);
   const ExpectedExpensesStore = useAppStore();
   console.log("useAppStoreexpected", useAppStore());
   const { setSearchData, isFilterOrSearchApplied, isResultsFounded, getTableData } = useAppStore((state) => state);

   const columnsData = [
      {
         header: "expected_expenses_id",
         accessorKey: "expected_expenses_id",
      },
      {
         header: "Expense type",
         accessorKey: "expense_type",
         cell: (value) => <ExpenseType value={value?.getValue()} />,
      },
      {
         header: "From month",
         accessorKey: "from_month",
         cell: (value) => changeDateFormat(value?.getValue()),
      },
      {
         header: "To month",
         accessorKey: "to_month",
         cell: (value) => changeDateFormat(value?.getValue()),
      },
      {
         header: "Amount (In ₹)",
         accessorKey: "amount",
         cell: (value) => (
            <ExpenseAmount
               value={value?.getValue()}
               updated={false}
            />
         ),
      },
      {
         header: "Account",
         accessorKey: "bank",
         cell: (value) => <HighlightText value={value.getValue()} />,
      },
      {
         header: "Reason",
         accessorKey: "reason",
         cell: (value) => <HighlightText value={value.getValue()} />,
      },
      {
         header: "Action",
         accessorKey: "action",
         cell: (value) => {
            return (
               <Actions
                  showEdit={true}
                  showDelete={true}
                  onClickEditIcon={() => handleEdit(value?.row?.original)}
                  onClickDeleteIcon={() => handleDelete(value?.row?.original)}
               />
            );
         },
      },
   ];
   const [editData, seteditData] = useState(null);
   const [editMode, seteditMode] = useState(false);
   const [deleteId, setdeleteId] = useState(null);
   const [showDeleteModal, setshowDeleteModal] = useState(false);

   const handleDelete = (tableRowData) => {
      setdeleteId(tableRowData?.expected_expenses_id);
      setshowDeleteModal(true);
   };

   const handleEdit = (tableRowData) => {
      seteditData(tableRowData);
      seteditMode(true);
      setshowInputModal(true);
   };
   const fetchData = async () => {
      ExpectedExpensesStore.getExpectedExpenses()
         .then((res) => {
            setSearchData(res, searchableKeys, searchPlaceholder, dateFilters);
         })
         .catch((err) => console.log(err.message));
   };

   const DeleteExpectedExpense = () => {
      ExpectedExpensesStore.deleteExpectedExpense(deleteId)
         .then((res) => setshowDeleteModal(false))
         .catch((err) => console.log(err.message));
   };

   useEffect(() => {
      ExpectedExpensesStore.setCurrentPageKey("expectedExpenses");
      fetchData();
   }, []);

   useEffect(() => {
      ExpectedExpensesStore.onChangeFilter();
   }, [ExpectedExpensesStore?.expectedExpenses]);

   const showTable = () => {
      if (ExpectedExpensesStore.expectedExpenses.length > 0) {
         if (isFilterOrSearchApplied()) {
            return isResultsFounded();
         } else {
            return true;
         }
      } else {
         return false;
      }
   };

   return (
      <div>
         <ScreenContainer breadCrumbsData={breadCrumbsData}>
            <DeleteModal
               visible={showDeleteModal}
               deleteMessage={"Are you sure you want to delete this expected expense?. You can’t undo this action."}
               onClose={() => setshowDeleteModal(false)}
               onDelete={DeleteExpectedExpense}
            />
            <InputModal
               visible={showInputModal}
               onClose={() => {
                  seteditMode(false);
                  setshowInputModal(false);
               }}
               editMode={editMode}
               preValues={editData}
            />

            <FullWidthSearchContainer
               createButtonText="Create expected expense"
               onPressCreate={() => setshowInputModal(true)}
            />
            <ChooseYearAndMonth />
            {!showTable() ? (
               <NoData
                  buttonText={"Create expected expense"}
                  onClickButton={() => setshowInputModal(true)}
                  message={"No expected expenses found"}
               />
            ) : (
               <Table
                  tableData={getTableData()}
                  columnsData={columnsData}
                  hideColumn={"expected_expenses_id"}
               />
            )}
         </ScreenContainer>
      </div>
   );
}
