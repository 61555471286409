/** @format */

import React from "react";
import { NumericFormat } from "react-number-format";

export default function CurrencyFormatText({ value }) {
   return (
      <div style={{ color: value < 0 ? "red" : "inherit" }}>
         <NumericFormat
            value={value}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"₹"}
         />
      </div>
   );
}
