/** @format */

import CurrencyFormatText from "Components/CurrencyFormatText";
import NoData from "Components/NoData";
import React, { useEffect } from "react";
import { useSearchAndFilterStore } from "store/searchAndFilter";
import ChooseYearAndMonth from "Components/ChooseYearAndMonth";
import ScreenContainer from "Components/ScreenContainer";
import Table from "Components/Table";
import { useMonthlySummaryStore } from "store/monthlySummary";
import { changeDateFormat } from "utils/dateFunctions";
import { useNavigate } from "react-router";
import RenderAmount from "Components/RenderAmount";
import { MONTHS } from "data/dummyData";
import { useAppStore } from "store/useApp";

const searchableKeys = ["expense_type", "reason", "bank"];
const dateFilters = ["month", "month"];
const searchPlaceholder = "Search by reason source or expense type";

const breadCrumbsData = [
   {
      title: "Summary",
      link: "/",
   },
   {
      title: "Monthly summary",
      link: "/monthly_summary",
   },
];

export default function MonthlySummary() {
   const navigate = useNavigate();

   const columnsData = [
      {
         header: "Id",
         accessorKey: "_id",
      },
      {
         header: "Month",
         accessorKey: "month",
         cell: (value) => changeDateFormat(value?.getValue()),
      },
      {
         header: "Income",
         accessorKey: "total_income",
         cell: (value) => <CurrencyFormatText value={value?.getValue()} />,
      },
      {
         header: "Expected expenses",
         accessorKey: "total_expected_expenses",
         cell: (value) => <CurrencyFormatText value={value?.getValue()} />,
      },
      {
         header: "Goals",
         accessorKey: "total_goal",
         cell: (value) => <CurrencyFormatText value={value?.getValue()} />,
      },
      {
         header: "Actual expenses",
         accessorKey: "total_actual_expenses",
         cell: (value) => <CurrencyFormatText value={value?.getValue()} />,
      },
      {
         header: "Total expenses",
         accessorKey: "total_expenses",
         cell: (value) => <CurrencyFormatText value={value?.getValue()} />,
      },
      {
         header: "Expected remaining balance",
         accessorKey: "total_expected_remaining_balance",
         cell: (value) => {
            const renderValue = value?.getValue();
            return (
               <div
                  className="cursor-pointer underline"
                  style={{ color: renderValue > 0 ? "#12B769" : "red" }}
                  onClick={() => navigateToMonthsSummary(value?.row?.original?.month)}>
                  <RenderAmount value={renderValue} />
               </div>
            );
         },
      },
      {
         header: "Actual balance",
         accessorKey: "total_balance",
         cell: (value) => <RenderAmount value={value?.getValue()} />,
      },
   ];
   const MonthlySummaryStore = useAppStore();
   const { setSearchData, isFilterOrSearchApplied, isResultsFounded, getTableData } = useAppStore((state) => state);

   const navigateToMonthsSummary = (timestamp) => {
      console.log(timestamp, "DATEVALUE");
      const date = new Date(parseInt(timestamp));
      const year = date.getFullYear();
      const month = date.getMonth();
      const currentMonth = MONTHS[month];
      console.log(year, currentMonth, "YEAR&MONTH");
      navigate(`/months_summary?year=${year}&month=${currentMonth}`);
   };

   const fetchData = async () => {
      await MonthlySummaryStore.getMonthlySummary().then((res) => {
         setSearchData(res, searchableKeys, searchPlaceholder, dateFilters);
      });
   };

   useEffect(() => {
      MonthlySummaryStore.onChangeFilter();
   }, [MonthlySummaryStore?.monthlySummary]);

   useEffect(() => {
      MonthlySummaryStore.setCurrentPageKey("monthlySummary");
      fetchData();
   }, []);

   const showTable = () => {
      if (MonthlySummaryStore?.monthlySummary.length > 0) {
         if (isFilterOrSearchApplied()) {
            return isResultsFounded();
         } else {
            return true;
         }
      } else {
         return false;
      }
   };

   return (
      <div>
         <ScreenContainer breadCrumbsData={breadCrumbsData}>
            <ChooseYearAndMonth />
            {!showTable() ? (
               <NoData
                  buttonText={""}
                  onClick={() => {}}
                  text={"No data to display yet"}
               />
            ) : (
               <Table
                  tableData={getTableData()}
                  columnsData={columnsData}
                  hideColumn={"_id"}
               />
            )}
         </ScreenContainer>
      </div>
   );
}
