/** @format */

import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { useAccountSummaryStore } from "./accountSummay";
import { useActualExpensesStore } from "./actualExpenses";
import { useBankStore } from "./bank";
import { useExpectedExpensesStore } from "./expectedExpenses";
import { useGoalsStore } from "./goals";
import { useIncomeStore } from "./income";
import { useMonthlySummaryStore } from "./monthlySummary";
import { useMonthsSummaryStore } from "./monthsSummary";
import { useReminderStore } from "./reminders";
import { useSavingsStore } from "./savings";
import { useSearchAndFilterStore } from "./searchAndFilter";
import { useTagStore } from "./tags";

export const useAppStore = create()(
   devtools(
      persist(
         (set, get) => ({
            ...useAccountSummaryStore(set, get),
            ...useActualExpensesStore(set, get),
            ...useBankStore(set, get),
            ...useExpectedExpensesStore(set, get),
            ...useGoalsStore(set, get),
            ...useIncomeStore(set, get),
            ...useMonthlySummaryStore(set, get),
            ...useMonthsSummaryStore(set, get),
            ...useReminderStore(set, get),
            ...useSavingsStore(set, get),
            ...useSearchAndFilterStore(set, get),
            ...useTagStore(set, get),
         }),
         {
            name: "yaba-store",
            getStorage: () => localStorage,
         },
      ),
   ),
);
