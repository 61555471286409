export const InActive = {
    color: '#14142B',
    backgroundColor: '#EEE6F1',
    hoverBackgroundColor: '#EEE6F1',
    hoverColor: '#14142B',
};

export const Active = {
    color: '#ffffff',
    backgroundColor: '#500472',
    hoverBackgroundColor: '#42045E',
    hoverColor: '#ffffff',
};  