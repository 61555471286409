/** @format */

import React from "react";
import "./index.css";
import CurrencyFormatText from "Components/CurrencyFormatText";

export default function AssociatedGoals({ value, onClickViewMore }) {
   const showViewMore = () => {
      return value?.length > 2;
   };

   return (
      <div className="associated-goals-container">
         {value?.slice(0, 2)?.map((item, index) => {
            return (
               <div
                  key={index}
                  className="associated-goal">
                  <div className="flex gap-[0.5rem] text-[0.8rem] font-semibold">
                     {item?.goal_name}{" "}
                     <div className="amount-wrapper">
                        <div>{"["}</div>
                        {<CurrencyFormatText value={item?.amount} />}
                        <div>{"]"}</div>
                     </div>{" "}
                  </div>
               </div>
            );
         })}
         {showViewMore() ? (
            <div
               onClick={onClickViewMore}
               className="text-[#1877f2] text-[0.8rem] font-semibold text-end cursor-pointer">
               {" "}
               + View more
            </div>
         ) : null}
      </div>
   );
}
