/** @format */

import { create } from "zustand";
import { createRequest, deleteRequest, updateRequest, readRequest } from "APIs/crud";
import { toast } from "react-hot-toast";

const API_ENDPOINT = "/expected_expenses/";

export const useExpectedExpensesStore = (set, get) => ({
   expectedExpenses: [],
   addExpectedExpense: async (data) => {
      return new Promise(async (resolve, reject) => {
         createRequest(API_ENDPOINT, data)
            .then((result) => {
               set({ expectedExpenses: [...get().expectedExpenses, result?.data] });
               resolve(result?.data);
               toast.success("Expected expense added successfully");
            })
            .catch((error) => reject(error));
      });
   },
   getExpectedExpenses: async () => {
      return new Promise(async (resolve, reject) => {
         readRequest(API_ENDPOINT)
            .then((result) => {
               set({ expectedExpenses: result?.data?.response });
               resolve(result?.data?.response);
            })
            .catch((error) => reject(error));
      });
   },
   deleteExpectedExpense: async (id) => {
      return new Promise(async (resolve, reject) => {
         deleteRequest(`${API_ENDPOINT}${id}/`)
            .then((result) => {
               set((state) => ({ expectedExpenses: state.expectedExpenses.filter((item) => item.expected_expenses_id !== id) }));
               resolve(result?.data);
               toast.success("Expected expense deleted successfully");
            })
            .catch((error) => reject(error));
      });
   },
   updateExpectedExpense: async (data) => {
      return new Promise(async (resolve, reject) => {
         updateRequest(`${API_ENDPOINT}${data.expected_expenses_id}/`, data)
            .then((result) => {
               set((state) => ({ expectedExpenses: state.expectedExpenses.map((item) => (item.expected_expenses_id === data.expected_expenses_id ? result?.data : item)) }));
               resolve(result?.data);
               toast.success("Expected expense updated successfully");
            })
            .catch((error) => reject(error));
      });
   },
});
