/** @format */

import Modal from "@mui/material/Modal";
import React from "react";
import RoundCloseIcon from "../../icons/RoundCloseIcon";
import "./index.css";
import Slide from "@mui/material/Slide";

export default function InputsModal({ visible, onClose, inputs, inputModalTitle, children }) {
   return (
      <Modal
         open={visible}
         onClose={onClose}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description">
         <Slide
            direction="left"
            in={visible}>
            <div className="input-modals-container">
               <div className="input-modal-header-container">
                  <div className="inputs-modal-title">{inputModalTitle}</div>
                  <div onClick={onClose}>
                     <RoundCloseIcon />
                  </div>
               </div>
               <div className="input-modal-children-container">{children}</div>
            </div>
         </Slide>
      </Modal>
   );
}
