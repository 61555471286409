/** @format */

import React from "react";
import "./index.css";
import { Avatar } from "@mui/material";
import LogoutIcon from "../../icons/LogoutIcon";
import { useNavigate } from "react-router-dom";
import { googleLogout } from "@react-oauth/google";

export default function Logout() {
   const navigate = useNavigate();

   const getUserData = () => {
      const data = JSON.parse(localStorage.getItem("yaba-1234"));
      return data;
   };

   const Logout = () => {
      googleLogout();
      localStorage.clear();
      navigate("/");
   };

   return (
      <div
         onClick={Logout}
         style={{ cursor: "pointer" }}
         className="logout-container">
         <div className="avatar-and-name-container">
            <Avatar
               sx={{ width: 48, height: 48 }}
               alt="Remy Sharp"
               src={getUserData()?.picture}
            />
            <div>
               <div className="logout-name">{getUserData()?.name}</div>
               <div className="logout-email">{getUserData()?.email}</div>
            </div>
         </div>
         <LogoutIcon />
      </div>
   );
}
