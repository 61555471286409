/** @format */

import HighlightText from "Components/HighlightText";
import NoData from "Components/NoData";
import CurrencyFormatText from "Components/CurrencyFormatText";
import React, { useEffect, useState } from "react";
import { useSearchAndFilterStore } from "store/searchAndFilter";
import { getCurrentMonthAndYear } from "utils/dateFunctions";
import AssociatedGoals from "Components/AccountSummaryCells/AssociatedGoals";
import DeleteModal from "Components/DeleteModal";
import FullWidthSearchContainer from "Components/FullWidthSearchContainer";
import ScreenContainer from "Components/ScreenContainer";
import Table from "Components/Table";
import { useAccountSummaryStore } from "store/accountSummay";
import AccountDetails from "./AccountDetails";
import { useAppStore } from "store/useApp";

const breadCrumbsData = [
   {
      title: "Summary",
      link: "/",
   },
   {
      title: "Account summary",
      link: "/account_summary",
   },
];

const searchableKeys = ["bank_name", "associated_goals"];
const dateFilters = [];
const nestedSearchableKeys = ["goal_name"];
const searchPlaceholder = "Search by account name or associated goals";

export default function AccountSummary() {
   const [openModal, setopenModal] = useState(false);
   const AccountSummaryStore = useAppStore((state) => state);
   const [viewMoreIndex, setviewMoreIndex] = useState(null);
   const { setSearchData, isFilterOrSearchApplied, isResultsFounded, getTableData } = useAppStore((state) => state);

   const columnsData = [
      {
         header: "Id",
         accessorKey: "_id",
      },
      {
         header: "Account name",
         accessorKey: "bank_name",
         cell: (value) => <HighlightText value={value?.getValue()} />,
      },
      {
         header: "Current balance",
         accessorKey: "current_balance",
         cell: (value) => <CurrencyFormatText value={value?.getValue()} />,
      },
      {
         header: "Associated goals",
         accessorKey: "associated_goals",
         cell: (value) => (
            <AssociatedGoals
               value={value?.getValue()}
               onClickViewMore={() => {
                  setviewMoreIndex(value.row.index);
               }}
            />
         ),
      },
   ];

   const fetchData = async () => {
      AccountSummaryStore.getAccountSummary(getCurrentMonthAndYear())
         .then((res) => {
            setSearchData(res, searchableKeys, searchPlaceholder, dateFilters, nestedSearchableKeys);
         })
         .catch();
   };

   useEffect(() => {
      AccountSummaryStore.setCurrentPageKey("accountSummary");
      fetchData();
   }, []);

   useEffect(() => {
      viewMoreIndex !== null && setopenModal(true);
   }, [viewMoreIndex]);

   const showTable = () => {
      if (AccountSummaryStore?.accountSummary.length > 0) {
         if (isFilterOrSearchApplied()) {
            return isResultsFounded();
         } else {
            return true;
         }
      } else {
         return false;
      }
   };

   return (
      <div>
         <ScreenContainer breadCrumbsData={breadCrumbsData}>
            <DeleteModal
               visible={false}
               // warningMessage={'By deleting this source the income summary of the upcoming moth will be affected'}
               deleteMessage={"Are you sure you want to delete this income source?. You can’t undo this action."}
            />
            <AccountDetails
               visible={openModal}
               viewMoreIndex={viewMoreIndex}
               onClose={() => setopenModal(false)}
            />
            <FullWidthSearchContainer createButtonText="" />
            {!showTable() ? (
               <NoData
                  buttonText={""}
                  onClick={() => {}}
                  message={"No account found"}
                  text={"No data to display yet"}
               />
            ) : (
               <div style={{ marginTop: "2rem" }}>
                  <Table
                     tableData={getTableData()}
                     columnsData={columnsData}
                     hideColumn={"_id"}
                  />
               </div>
            )}
         </ScreenContainer>
      </div>
   );
}
