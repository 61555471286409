/** @format */

import React from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import Tooltip from "../Components/Tooltip";

export default function Icon() {
   return (
      <Tooltip
         title={"Logout"}
         toolTipBackgroundColor={"#D90429"}
         toolTipFontColor={"#ffffff"}>
         <LogoutIcon sx={{ color: "#D90429" }} />
      </Tooltip>
   );
}
