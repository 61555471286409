/** @format */

import * as Yup from "yup";

export const Inputs = [
   {
      label: "Start month *",
      required: true,
      key: "start_month",
      type: "datePicker",
   },
];

export const Payload = {
   start_month: new Date(),
};

export const PayloadSchema = {
   start_month: Yup.string().required("This field is required"),
   saving_in_account: Yup.string().required("This field is required"),
   saving_from_account: Yup.string().required("This field is required"),
};
