/** @format */

import * as Yup from "yup";

export const Inputs = [
   {
      label: "Enter income source",
      required: true,
      key: "income_name",
      type: "textInput",
   },
   {
      label: "From month",
      required: true,
      key: "from_month",
      type: "datePicker",
   },
   {
      label: "To month",
      required: true,
      key: "to_month",
      type: "datePicker",
   },
   {
      label: "Enter amount",
      required: true,
      key: "amount",
      type: "textInput",
   },
   {
      label: "Choose account",
      required: true,
      key: "bank",
      type: "dropDown",
      options: [
         {
            label: "Account 1",
            value: "account_1",
         },
         {
            label: "Account 2",
            value: "account_2",
         },
         {
            label: "Account 3",
            value: "account_3",
         },
      ],
   },
   {
      label: "Notes",
      required: true,
      key: "notes",
      type: "textInput",
   },
];

export const Payload = {
   income_name: "",
   from_month: new Date(),
   to_month: new Date(),
   amount: null,
   bank: "",
   notes: "",
};

export const PayloadSchema = {
   income_name: Yup.string().required("This field is required"),
   from_month: Yup.date().required("This field is required"),
   to_month: Yup.date().required("This field is required"),
   amount: Yup.number("Please enter number").typeError("Amount must be a number").moreThan(0).required("This field is required"),
   bank: Yup.string().required("This field is required"),
};
