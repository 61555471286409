/** @format */

import React from "react";
import { Button as MuiButton } from "@mui/material";

//Have to rewrite this component

export default function Button({
   title,
   backgroundColor,
   disabled,
   hoverBackgroundColor,
   hoverColor,
   color,
   startIcon,
   endIcon,
   width,
   justifyContent,
   onClick,
   type,
   borderColor,
   borderStyle,
   customStyle,
}) {
   const buttonSyle = {
      backgroundColor: backgroundColor,
      color: color,
      "&:hover": {
         backgroundColor: hoverBackgroundColor,
         color: hoverColor,
      },
      padding: "0.5rem 1rem",
      fontSize: "0.9rem",
      maxWidth: width,
      minWidth: width,
      justifyContent: justifyContent,
      borderRadius: "0.5rem",
      borderColor: borderColor,
      borderStyle: borderStyle,
      borderWidth: "1px",
   };

   return (
      <MuiButton
         sx={buttonSyle}
         variant="contained"
         startIcon={startIcon}
         endIcon={endIcon}
         disabled={disabled}
         disableElevation={true}
         onClick={onClick}
         type={type}>
         {title}
      </MuiButton>
   );
}
