/** @format */

import React from "react";
import GoalConvertedIcon from "../../../icons/GoalConvertedIcon";
import "./index.css";
import HighlightedText from "Components/HighlightText";

export default function ExpenseName({ name, convertedToGoal }) {
   console.log("name, convertedToGoal", name, convertedToGoal);

   return (
      <div>
         <div>
            <HighlightedText value={name} />
         </div>
         {convertedToGoal === true && (
            <div className="converted-to-goal-container">
               <GoalConvertedIcon />
               <div className="converted-to-goal-text">Converted to goal</div>
            </div>
         )}
      </div>
   );
}
