/** @format */

import { create } from "zustand";
import { createRequest, deleteRequest, updateRequest, readRequest } from "APIs/crud";
import { toast } from "react-hot-toast";

const ENDPOINT = "/expected_expenses_summary";

export const useMonthsSummaryStore = (set, get) => ({
   goals: [],
   expectedExpenses: [],
   expectedExpense: 0,
   actualExpense: 0,
   expectedBalance: 0,
   actualBalance: 0,
   totalIncome: 0,
   expectedExpensesFromGoals: 0,
   totalExpenses: 0,

   getMonthsSumary: async (date) => {
      const result = await readRequest(`/month_expense_summary/${date}/`);
      if (result.status === 200 || result.status === 201) {
         set({
            expectedExpenses: result?.data?.response?.expected_expenses,
            goals: result?.data?.response?.goals,
            expectedExpense: result?.data?.response?.total_expected_expense,
            actualExpense: result?.data?.response?.total_actual_expenses,
            expectedBalance: result?.data?.response?.total_expected_balance,
            actualBalance: result?.data?.response?.total_actual_balance,
            totalIncome: result?.data?.response?.total_income,
            expectedExpensesFromGoals: result?.data?.response?.total_expense_from_goals,
            totalExpenses: result?.data?.response?.total_expenses,
         });
      }
   },
   getImpactTableForConvertToGoal: (payload) => {
      return new Promise((resolve, reject) => {
         createRequest(`${ENDPOINT}/convert_expected_expense_to_goal_preview/`, payload)
            .then((res) => {
               resolve(res.data.response);
            })
            .catch((err) => reject(err));
      });
   },
   confirmConvertToGoal: (expected_expenses_summary_id, payload) => {
      return new Promise((resolve, reject) => {
         createRequest(`${ENDPOINT}/convert_expected_expense_to_goal/${expected_expenses_summary_id}/`, payload)
            .then((res) => {
               const resData = res.data;
               toast.success("Coverted to goal successfully");
               set({
                  expectedExpenses: get().expectedExpenses.map((item) => {
                     if (item.expected_expenses_summary_id === resData.expected_expenses_summary_id) {
                        return resData;
                     } else {
                        return item;
                     }
                  }),
               });
               resolve(resData);
            })
            .catch((err) => reject(err));
      });
   },
   updateExpectedExpenseSummary: (expected_expenses_summary_id, payload) => {
      return new Promise((resolve, reject) => {
         updateRequest(`${ENDPOINT}/${expected_expenses_summary_id}/`, payload)
            .then((res) => {
               toast.success("Expected  expense updated successfully");
               set({
                  expectedExpenses: get().expectedExpenses.map((item) => {
                     if (item.expected_expenses_summary_id === expected_expenses_summary_id) {
                        return { ...item, planned_amount: payload.planned_amount };
                     } else {
                        return item;
                     }
                  }),
               });
               resolve();
            })
            .catch((err) => reject(err));
      });
   },
   deleteExpectedExpenseSummary: (expected_expenses_summary_id) => {
      return new Promise((resolve, reject) => {
         deleteRequest(`${ENDPOINT}/${expected_expenses_summary_id}/`)
            .then((res) => {
               toast.success("Expected expense deleted successfully");
               set({
                  expectedExpenses: get().expectedExpenses.filter((item) => {
                     if (item.expected_expenses_summary_id !== expected_expenses_summary_id) {
                        return item;
                     }
                  }),
               });
               resolve();
            })
            .catch((err) => reject(err));
      });
   },
   updateGoalSummary: (goal_summary_id, payload) => {
      return new Promise((resolve, reject) => {
         updateRequest(`/goal_summary/${goal_summary_id}`, payload)
            .then((res) => {
               toast.success("Goal summary updated successfully");
               resolve();
            })
            .catch((err) => {
               reject(err);
            });
      });
   },
});
