/** @format */

import Actions from "Components/Actions";
import ChooseYearAndMonth from "Components/ChooseYearAndMonth";
import CurrencyFormatText from "Components/CurrencyFormatText";
import DeleteModal from "Components/DeleteModal";
import FullWidthSearchContainer from "Components/FullWidthSearchContainer";
import HighlightText from "Components/HighlightText";
import Loader from "Components/Loader";
import NoData from "Components/NoData";
import ScreenContainer from "Components/ScreenContainer";
import Table from "Components/Table";
import React, { useEffect, useState } from "react";
import { useAppStore } from "store/useApp";
import { changeDateFormat } from "utils/dateFunctions";
import IncomeInputsModal from "./Inputs";

const breadCrumbsData = [
   {
      title: "Income details",
      link: "/",
   },
   {
      title: "Income",
      link: "/income",
   },
];
const searchableKeys = ["income_name", "notes"];
const dateFilters = ["from_month", "to_month"];
const searchPlaceholder = "Search by income source or notes";

export default function Income() {
   const [showInputModal, setshowInputModal] = useState(false);
   console.log("useAppStore()", useAppStore());
   const IncomeStore = useAppStore((state) => state);
   const getIncome = useAppStore().getIncome;
   console.log(getIncome, "IncomeStore");
   const BankStore = useAppStore((state) => state);
   const TagStore = useAppStore((state) => state);
   const columnsData = [
      {
         header: "Id",
         accessorKey: "income_id",
      },
      {
         header: "Income source",
         accessorKey: "income_name",
         cell: (value) => <HighlightText value={value?.getValue()} />,
      },
      {
         header: "From month",
         accessorKey: "from_month",
         cell: (value) => changeDateFormat(value?.getValue()),
      },
      {
         header: "To month",
         accessorKey: "to_month",
         cell: (value) => changeDateFormat(value?.getValue()),
      },
      {
         header: "Amount (In ₹)",
         accessorKey: "amount",
         cell: (value) => {
            return <CurrencyFormatText value={value?.getValue()} />;
         },
      },
      {
         header: "Notes",
         accessorKey: "notes",
         cell: (value) => <HighlightText value={value?.getValue()} />,
      },
      {
         header: "Action",
         accessorKey: "action",
         cell: (value) => {
            return (
               <Actions
                  showDelete={true}
                  showEdit={true}
                  onClickEditIcon={() => handleEdit(value?.row?.original)}
                  onClickDeleteIcon={() => handleDelete(value?.row?.original)}
               />
            );
         },
      },
   ];

   const [editData, seteditData] = useState(null);
   const [editMode, seteditMode] = useState(false);
   const [deleteIncomeId, setdeleteIncomeId] = useState(null);
   const [showDeleteModal, setshowDeleteModal] = useState(false);
   const { setSearchData, isFilterOrSearchApplied, isResultsFounded, getTableData } = useAppStore();

   const handleDelete = (tableRowData) => {
      setdeleteIncomeId(tableRowData?.income_id);
      setshowDeleteModal(true);
   };

   const handleEdit = (tableRowData) => {
      seteditData(tableRowData);
      seteditMode(true);
      setshowInputModal(true);
   };
   const fetchData = () => {
      BankStore.getAllbanks();
      TagStore.getAlltags();
      IncomeStore?.getIncome()
         .then((res) => {
            setSearchData(res, searchableKeys, searchPlaceholder, dateFilters);
         })
         .catch((err) => console.log(err.message));
   };

   useEffect(() => {
      IncomeStore.onChangeFilter();
   }, [IncomeStore?.income]);

   const DeleteIncome = () => {
      IncomeStore?.deleteIncome(deleteIncomeId)
         .then((res) => {
            setshowDeleteModal(false);
         })
         .catch((err) => console.log(err.message));
   };

   useEffect(() => {
      IncomeStore?.setCurrentPageKey("income");
      fetchData();
   }, []);

   const showTable = () => {
      if (IncomeStore?.income.length > 0) {
         if (isFilterOrSearchApplied()) {
            return isResultsFounded();
         } else {
            return true;
         }
      } else {
         return false;
      }
   };

   return (
      <div>
         <Loader visible={false} />
         <ScreenContainer breadCrumbsData={breadCrumbsData}>
            <DeleteModal
               visible={showDeleteModal}
               warningMessage={"By deleting this source the income summary of the upcoming moth will be affected"}
               deleteMessage={"Are you sure you want to delete this income source?. You can’t undo this action."}
               onClose={() => setshowDeleteModal(false)}
               onDelete={DeleteIncome}
            />
            <IncomeInputsModal
               visible={showInputModal}
               onClose={() => {
                  seteditMode(false);
                  setshowInputModal(false);
               }}
               editMode={editMode}
               preValues={editData}
            />{" "}
            <FullWidthSearchContainer
               createButtonText="Create new income"
               onPressCreate={() => setshowInputModal(true)}
            />
            <ChooseYearAndMonth />
            {!showTable() ? (
               <NoData
                  buttonText={"Create new income"}
                  message={"No income found"}
                  onClickButton={() => setshowInputModal(true)}
               />
            ) : (
               <Table
                  tableData={getTableData()}
                  columnsData={columnsData}
                  hideColumn={"income_id"}
               />
            )}
         </ScreenContainer>
      </div>
   );
}
