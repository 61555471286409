/** @format */

import React from "react";
import "./index.css";
import LinearProgress from "@mui/material/LinearProgress";
import CurrencyFormatText from "Components/CurrencyFormatText";

export default function progress({ percentage, amount }) {
   return (
      <div>
         <div className="goals-progress-row">
            <div>
               <CurrencyFormatText value={amount} />
            </div>
            <div>{percentage}%</div>
         </div>
         <LinearProgress
            sx={{
               backgroundColor: "rgba(18, 183, 105, 0.1)",
               "& .MuiLinearProgress-bar": {
                  backgroundColor: "#12B769",
               },
            }}
            color="primary"
            variant="determinate"
            value={percentage}
         />
      </div>
   );
}
