/** @format */

import { create } from "zustand";
import { createRequest, deleteRequest, updateRequest, readRequest } from "APIs/crud";
import { toast } from "react-hot-toast";

const API_ENDPOINT = "/income/";

export const useIncomeStore = (set, get) => {
   console.log("useIncomeStore", set, get);
   return {
      income: [],
      incomeSummary: [],
      setIncome: (income) => set({ income }),
      addIncome: async (income) => {
         return new Promise(async (resolve, reject) => {
            createRequest(API_ENDPOINT, income)
               .then((result) => {
                  console.log(...get().income, "ADD INCOME", result?.data);
                  set({ income: [...get().income, result?.data] });
                  toast.success("Income added successfully");
                  resolve(result?.data);
               })
               .catch((error) => {
                  console.log("error addIncome", error);
                  reject(error);
               });
         });
      },
      getIncome: async () => {
         return new Promise(async (resolve, reject) => {
            readRequest(API_ENDPOINT)
               .then((result) => {
                  console.log("GET INCOME", set);
                  set({ income: result?.data?.response });
                  resolve(result?.data?.response);
               })
               .catch((error) => reject(error));
         });
      },
      getIncomeSummary: async () => {
         return new Promise(async (resolve, reject) => {
            await readRequest(`${API_ENDPOINT}income_summary/`)
               .then((result) => {
                  set({ incomeSummary: result?.data?.response });
                  resolve(result?.data?.response);
               })
               .catch((error) => reject(error));
         });
      },
      deleteIncome: async (id) => {
         return new Promise(async (resolve, reject) => {
            deleteRequest(`${API_ENDPOINT}${id}/`)
               .then((result) => {
                  set((state) => ({ income: state.income.filter((income) => income.income_id !== id) }));
                  resolve(result?.data);
                  toast.success("Income deleted successfully");
               })
               .catch((error) => reject(error));
         });
      },
      updateIncome: async (data) => {
         return new Promise(async (resolve, reject) => {
            updateRequest(`${API_ENDPOINT}${data.income_id}/`, data)
               .then((result) => {
                  set((state) => ({ income: state.income.map((income) => (income.income_id === data.income_id ? result?.data : income)) }));
                  resolve(result?.data);
                  toast.success("Income updated successfully");
               })
               .catch((error) => reject(error));
         });
      },
   };
};
