/** @format */

import DropdownInput from "Components/DropdownInput";
import React, { useEffect } from "react";
import { useBankStore } from "store/bank";
import { useAppStore } from "store/useApp";

export default function BankDropdownInput({ form, removedBankId, id, label, placeholder, type, onChange, error, helperText, value, setDropDownValue }) {
   const BankStore = useAppStore((state) => state);

   console.log("helperText", helperText, "error", error, "label", label);

   const onClickItem = (type, val) => {
      console.log(type, val, "onClickItem");
      if (type === "add") {
         BankStore?.addBank(val)
            .then((res) => setDropDownValue(res))
            .catch();
      } else {
         console.log("bank drop setDropDownValue", type, val);
         setDropDownValue(val?.value);
      }
   };

   console.log("BankdropdownComponent", value);

   return (
      <DropdownInput
         id={id}
         label={label}
         disableId={removedBankId}
         placeholder={placeholder}
         type={type}
         options={BankStore.banks}
         onChange={onChange}
         error={error}
         helperText={helperText}
         value={value}
         form={form}
         setDropDownValue={onClickItem}
      />
   );
}
