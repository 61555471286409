import React from "react";

function Icon( {
    onClickUp,
    onClickDown
} ) {

 
    return (
        <div style={ {
            gap: '0.3rem',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            paddingInline: '0.2rem',
            borderLeftWidth: '1px',

        } }>
            <svg className="svg-icon" onClick={ onClickUp } width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.62875 0.526915C3.80966 0.270159 4.19034 0.270159 4.37125 0.526915L7.49568 4.9612C7.70768 5.26207 7.49249 5.67694 7.12443 5.67694L0.875565 5.67694C0.507505 5.67694 0.292316 5.26207 0.504314 4.9612L3.62875 0.526915Z" fill="#14142B" />
            </svg>
            <svg className="svg-icon" onClick={ onClickDown } width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.37125 5.43659C4.19034 5.69334 3.80966 5.69334 3.62875 5.43659L0.504316 1.0023C0.292318 0.701426 0.507508 0.28656 0.875568 0.28656H7.12443C7.49249 0.28656 7.70768 0.701426 7.49569 1.0023L4.37125 5.43659Z" fill="#14142B" />
            </svg>
        </div>

    );
}

export default Icon;