/** @format */

import React from "react";
import "./index.css";
import CurrencyFormatText from "Components/CurrencyFormatText";

export default function ExpenseAmount({ value, updated }) {
   return (
      <div className="expense-amount-container">
         <div>
            <CurrencyFormatText value={value} />
         </div>
         {updated && <div className="expense-amount-updated">Updated</div>}
      </div>
   );
}
