/** @format */

import React from "react";
import "./index.css";
import CurrencyFormatText from "Components/CurrencyFormatText";
import Grow from "@mui/material/Grow";

export default function Card({ color, title, value, month }) {
   return (
      <Grow
         {...(true ? { timeout: 1000 } : {})}
         style={{ transformOrigin: "0 0 0" }}
         in={true}>
         <div className="statistics-card-container">
            <div className="statistics-card-title">{title}</div>
            <div
               style={{ color: color }}
               className="statistics-card-value">
               <CurrencyFormatText value={value} />
            </div>
            <div className="statistics-card-month">{month}</div>
         </div>
      </Grow>
   );
}
