/** @format */

import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useGoogleLogin, GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { backendUrl } from "../definitions";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function Copyright(props) {
   return (
      <Typography
         variant="body2"
         color="text.secondary"
         align="center"
         {...props}>
         {"Copyright © "}
         <Link
            color="inherit"
            href="https://mui.com/">
            Your Website
         </Link>{" "}
         {new Date().getFullYear()}
         {"."}
      </Typography>
   );
}

const theme = createTheme();

export default function Login() {
   const handleSubmit = (event) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      console.log({
         email: data.get("email"),
         password: data.get("password"),
      });
   };

   const navigate = useNavigate();

   const onSucessGoogleLogin = async (credentialResponse) => {
      try {
         const res = await axios.post(
            `${backendUrl}/sign_up/`,
            {},
            {
               headers: {
                  Authorization: `Bearer ${credentialResponse.credential}`,
                  "ngrok-skip-browser-warning": "any",
               },
            },
         );
         console.log("onSucessGoogleLogin", res);
         if (res.data.auth_token) {
            localStorage.setItem("auth_token", res.data.auth_token);
            storeUserdetails(credentialResponse.credential);
         }
      } catch (error) {
         console.log(error);
      }
   };

   const storeUserdetails = (token) => {
      fetch(`https://oauth2.googleAPIs.com/tokeninfo?id_token=${token}`).then(async (res) => {
         const resData = await res.json();
         localStorage.setItem("yaba-1234", JSON.stringify(resData));
         const navigated_path = localStorage.getItem("navigated_path");
         if (navigated_path !== undefined && navigated_path !== null) {
            localStorage.removeItem("navigated_path");
            navigate(navigated_path);
         } else {
            console.log("storeUserdetails", resData);
            navigate("/income?years=2024");
         }
      });
   };

   useEffect(() => {
      autoLogin();
   }, []);

   const autoLogin = () => {
      const user = localStorage.getItem("yaba-1234");
      if (user !== undefined && user !== null) {
         navigate("/income");
      }
   };

   return (
      <ThemeProvider theme={theme}>
         <Container
            component="main"
            maxWidth="xs"
            className="min-h-screen">
            <CssBaseline />
            <Box
               sx={{
                  marginTop: 8,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
               }}>
               <Avatar sx={{ m: 1, bgcolor: "primary" }}>
                  <LockOutlinedIcon />
               </Avatar>
               <Typography
                  component="h1"
                  variant="h5">
                  Sign In
               </Typography>
               <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 3 }}>
                  <GoogleLogin
                     shape="rectangular"
                     text="continue_with"
                     theme="outline"
                     logo_alignment="left"
                     onSuccess={onSucessGoogleLogin}
                     onError={() => {
                        console.log("Login Failed");
                     }}
                  />
               </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
         </Container>
      </ThemeProvider>
   );
}
