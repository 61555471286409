import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <mask
                id="mask0_28_1131"
                style={ { maskType: "alpha" } }
                width="24"
                height="24"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
            </mask>
            <g mask="url(#mask0_28_1131)">
                <path
                    fill="#898995"
                    d="M16 10.885a.852.852 0 00.626-.26.852.852 0 00.259-.625.852.852 0 00-.26-.626.853.853 0 00-.625-.259.853.853 0 00-.626.259.853.853 0 00-.259.626c0 .245.087.454.259.626a.853.853 0 00.626.259zM8 8c0 .414.336.75.75.75h3.5a.75.75 0 100-1.5h-3.5A.75.75 0 008 8zM5.626 20.5a1 1 0 01-.96-.722c-.459-1.58-.917-3.154-1.375-4.72A19.711 19.711 0 012.5 9.5c0-1.392.485-2.574 1.456-3.544C4.926 4.986 6.108 4.5 7.5 4.5h5.25a5.458 5.458 0 011.647-1.451A4.164 4.164 0 0116.5 2.5c.276 0 .511.098.707.293a.963.963 0 01.293.707.77.77 0 01-.028.204c-.019.068-.038.13-.059.188a6.78 6.78 0 00-.235.74c-.065.253-.12.508-.166.766L19.613 8h.887a1 1 0 011 1v4.385a1 1 0 01-.688.95l-1.545.508a1 1 0 00-.646.663l-1.282 4.281a1 1 0 01-.958.713H13.5a1 1 0 01-1-1v-1h-3v1a1 1 0 01-1 1H5.626zM6 19h2v-1a1 1 0 011-1h4a1 1 0 011 1v1h2l1.401-4.655a1 1 0 01.639-.66l1.28-.43a1 1 0 00.68-.949v-2.099a.707.707 0 00-.707-.707.707.707 0 01-.5-.207L15.5 6c0-.333.02-.654.063-.963.055-.407-.315-.765-.66-.541-.14.091-.273.195-.4.31-.36.332-.636.73-.828 1.194H7.5c-.967 0-1.792.342-2.475 1.025A3.372 3.372 0 004 9.5c0 1.633.225 3.23.675 4.787.45 1.559.892 3.13 1.325 4.713z"
                ></path>
            </g>
        </svg>
    );
}

export default Icon;