export const YEARS = [
    '2021',
    '2022',
    '2023',
    '2024',
    '2025',
];


export const MONTHS = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];