import React from 'react';
import './index.css';
 

export default function Component({children}) {
    return (
        <div className='highlighted-inputs-container'>
            { children }
        </div>
    );
}
