import React from 'react';
import './index.css';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


export default function CheckBoxInput( { title, value, onChange } ) {
    return (
        <div className='check-box-input-container'>
            <FormControlLabel
                control={
                    <Checkbox
                        color='secondary'
                        checked={ value }
                        onChange={ ( event ) => {
                            onChange( event.target.checked );
                        } }
                    />
                }
                label={ title }
            />
        </div>
    );
}
