/** @format */

import { createRequest, readRequest } from "APIs/crud";
import { create } from "zustand";

const API_ENDPOINT = "/bank_accounts/";

export const useBankStore = (set, get) => ({
   banks: [],
   getAllbanks: async () => {
      return new Promise((resolve, reject) => {
         readRequest(API_ENDPOINT)
            .then((result) => {
               const incomeResponse = result?.data?.response?.map((item) => {
                  return {
                     label: item?.bank_name,
                     value: item,
                  };
               });
               console.log("incomeResponse", incomeResponse);
               set({ banks: incomeResponse });
               resolve(result);
            })
            .catch((err) => reject(err));
      });
   },
   addBank: async (data) => {
      return new Promise((resolve, reject) => {
         const payload = { bank_name: data };
         createRequest(API_ENDPOINT, payload)
            .then((result) => {
               set({
                  banks: [
                     ...get().banks,
                     {
                        label: result?.data?.bank_name,
                        value: result?.data,
                     },
                  ],
               });
               resolve(result?.data);
            })
            .catch((err) => reject(err));
      });
   },
});
