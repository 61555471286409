/** @format */

import React from "react";
import ScreenContainer from "Components/ScreenContainer";
import StatsCard from "Components/StatsCard";
import CurrencyFormatText from "Components/CurrencyFormatText";
import GoalsIcon from "icons/GoalsIcon";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import Chart from "Components/DashboardChart";

export default function Dashboard() {
   return (
      <ScreenContainer breadCrumbsData={[{ title: "Dashboard", path: "/dashboard" }]}>
         <h3 className="font-bold text-[2rem]">Welcome, Jonathan 👋</h3>
         <div className="w-[95%] flex mt-4 items-center justify-between">
            <StatsCard
               title={"Current month income"}
               value={<CurrencyFormatText value={259201} />}
               icon={
                  <TrendingUpIcon
                     fontSize="small"
                     sx={{ color: "#12B769" }}
                  />
               }
            />
            <StatsCard
               title={"Current month expense"}
               icon={
                  <TrendingDownIcon
                     fontSize="small"
                     sx={{ color: "#D90429" }}
                  />
               }
               value={<CurrencyFormatText value={59201} />}
            />
            <StatsCard
               title={"Total goals"}
               value={15}
               icon={<GoalsIcon />}
            />
            <StatsCard
               title={"Amount saved in goals"}
               icon={
                  <TrendingUpIcon
                     fontSize="small"
                     sx={{ color: "#12B769" }}
                  />
               }
               value={<CurrencyFormatText value={259201} />}
            />
         </div>
         <Chart />
      </ScreenContainer>
   );
}
