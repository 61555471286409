import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

function handleClick( event ) {
    event.preventDefault();
    console.info( 'You clicked a breadcrumb.' );
}


export default function BasicBreadcrumbs( { breadCrumbsData } ) {
    return (
        <div role="presentation" onClick={ handleClick }>
            <Breadcrumbs aria-label="breadcrumb">
                { breadCrumbsData?.map( ( item, index ) => {
                    if ( index !== breadCrumbsData.length - 1 )
                    {
                        return (
                            <Link underline="hover" color="inherit" href={ item.link }>
                                { item.title }
                            </Link>
                        );
                    } else
                    {
                        return <Typography color="text.primary">{ item.title }</Typography>;
                    }
                }
                ) }
            </Breadcrumbs>
        </div>
    );
}
