/** @format */

import React from "react";
import Tooltip from "../Components/Tooltip";

function Icon() {
   return (
      <Tooltip
         placement={"top"}
         title="Delete"
         toolTipBackgroundColor={"#D90429"}
         toolTipFontColor={"#ffffff"}>
         <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="24"
            fill="none"
            viewBox="0 0 24 24">
            <mask
               id="mask0_147_8559"
               style={{ maskType: "alpha" }}
               width="24"
               height="24"
               x="0"
               y="0"
               maskUnits="userSpaceOnUse">
               <path
                  fill="#D9D9D9"
                  d="M0 0H24V24H0z"></path>
            </mask>
            <g mask="url(#mask0_147_8559)">
               <path
                  fill="#D90429"
                  d="M7.308 20.5c-.499 0-.925-.177-1.278-.53a1.742 1.742 0 01-.53-1.278V6h-.25a.75.75 0 110-1.5h3.308A.442.442 0 009 4.058c0-.244.198-.443.442-.443h5.116c.244 0 .442.198.442.443v.246c0 .108.088.196.196.196h3.554a.75.75 0 010 1.5h-.25v12.692c0 .506-.175.933-.525 1.283-.35.35-.778.525-1.283.525H7.308zM17 6H7v12.692a.3.3 0 00.087.221.3.3 0 00.22.087h9.385a.294.294 0 00.212-.096.294.294 0 00.096-.212V6zM9.404 16.25a.75.75 0 101.5 0v-7.5a.75.75 0 00-1.5 0v7.5zm3.692 0a.75.75 0 001.5 0v-7.5a.75.75 0 00-1.5 0v7.5z"></path>
            </g>
         </svg>
      </Tooltip>
   );
}

export default Icon;
