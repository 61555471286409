/** @format */

import React from "react";
import "./index.css";
import HighlightText from "Components/HighlightText";

export default function IncomeSummaryCell({ value }) {
   return (
      <div className="income-summary-cell-container">
         <div className="income-summary-cell-line"></div>
         <HighlightText value={value} />
      </div>
   );
}
