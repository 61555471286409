/** @format */

import * as Yup from "yup";

export const EXPENSE_TYPES = [
   {
      label: "Fixed",
      value: "Fixed",
   },
   {
      label: "Variable",
      value: "Variable",
   },
];

export const Inputs = [
   {
      label: "Expense type *",
      required: true,
      key: "expense_type",
      type: "Select",
      options: [
         {
            label: "Fixed",
            value: "Fixed",
         },
         {
            label: "Variable",
            value: "Variable",
         },
      ],
   },
   {
      label: "Reason *",
      required: true,
      key: "reason",
      type: "textInput",
   },
   {
      label: "Enter amount *",
      required: true,
      key: "amount",
      type: "textInput",
   },
   {
      customComponent: true,
   },
   {
      label: "Choose account",
      required: true,
      key: "bank",
      type: "dropDown",
      options: [
         {
            label: "Account 1",
            value: "account_1",
         },
         {
            label: "Account 2",
            value: "account_2",
         },
         {
            label: "Account 3",
            value: "account_3",
         },
      ],
   },
];

export const Payload = {
   expense_type: "Variable",
   reason: "",
   amount: null,
   bank: "",
   from_month: new Date(),
   to_month: new Date(),
   recurring_expense: false,
};

export const PayloadSchema = {
   expense_type: Yup.string().required("This field is required"),
   reason: Yup.string().required("This field is required"),
   amount: Yup.number("Please enter number").typeError("Amount must be a number").moreThan(0).required("This field is required"),
   bank: Yup.string().required("This field is required"),
};
