/** @format */

import React from "react";
import "./index.css";
import HightlightText from "Components/HighlightText";

export default function ExpenseType({ value }) {
   const wrapperClass = value === "Fixed" ? "expense-type-fixed" : "expense-type-variable";

   return (
      <div className={wrapperClass}>
         <HightlightText value={value} />
      </div>
   );
}
