/** @format */

import React from "react";
import CenterModal from "Components/CenterModal";
import InlineAlert from "Components/InlineAlert";
import CloseIcon from "@mui/icons-material/Close";

export default function index({ message, onClose }) {
   return (
      <CenterModal
         visible={true}
         onClose={onClose}>
         <div
            onClick={onClose}
            className="flex justify-between">
            <div className="text-[1.3rem] text-[#D90429]">Cannot revive the reminder</div>
            <CloseIcon />
         </div>
         <InlineAlert
            background={"rgba(217, 4, 41, 0.1)"}
            borderColor={"#D90429"}
            mode={"warning"}
            text={<div className="text-[1rem]"> {message}</div>}
         />
      </CenterModal>
   );
}
