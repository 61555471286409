/** @format */

import BankDropdownInput from "Components/BankDropdownInput";
import Button from "Components/Button";
import dayjs from "dayjs";
import { APP_MODE } from "definition";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useBankStore } from "store/bank";
import { generateAmount, generateNumbers } from "utils/stringFunction";
import * as Yup from "yup";
import Input from "Components/Input";
import InputModalsButton from "Components/InputModalButtons";
import InputsModal from "Components/InputsModal";
import { useIncomeStore } from "store/income";
import "./index.css";
import { Payload, PayloadSchema } from "./inputs";
import { useAppStore } from "store/useApp";
import { upperCaseFirst } from "upper-case-first";

export default function IncomeInputsModal({ visible, onClose, editMode, preValues }) {
   const [inputValues, setinputValues] = useState();
   const IncomeStore = useAppStore((state) => state);
   const Banks = useAppStore((state) => state.banks);
   const validationSchema = Yup.object().shape(PayloadSchema);

   useEffect(() => {
      if (editMode) {
         setinputValues(preValues);
      } else {
         setinputValues(Payload);
      }
   }, [editMode, preValues]);

   const CreateNewIncome = () => {
      const payload = generatePayload(form.values);
      editMode ? UpdateIncome({ ...payload, income_id: form.values.income_id }) : AddIncome(payload);
   };

   console.log(Payload, "Payload");

   const AddIncome = (payload) => {
      IncomeStore.addIncome(payload)
         .then((res) => resetForm())
         .catch((err) => console.log(err.message));
   };

   const UpdateIncome = (payload) => {
      IncomeStore.updateIncome(payload)
         .then((res) => resetForm())
         .catch((err) => console.log(err.message));
   };

   const resetForm = () => {
      form.resetForm();
      onClose();
   };

   const form = useFormik({
      initialValues: inputValues,
      validationSchema: validationSchema,
      onSubmit: CreateNewIncome,
      enableReinitialize: true,
   });
   console.log(form.values, "FORM>VALUE");
   const generatePayload = (values) => {
      return {
         income_name: values.income_name || "",
         from_month: dayjs(values.from_month).format("YYYY-MM-DD") || "",
         to_month: dayjs(values.to_month).format("YYYY-MM-DD") || "",
         amount: parseFloat(values.amount) || 0,
         bank: values.bank || "",
         notes: values.notes || "",
         bank_account_id: values.bank_account_id || "",
      };
   };

   const handleFillData = () => {
      form.setFieldValue("income_name", `Income ${generateNumbers()}`);
      form.setFieldValue("from_month", dayjs().format("YYYY-MM-DD"));
      form.setFieldValue("to_month", dayjs().format("YYYY-MM-DD"));
      form.setFieldValue("amount", generateAmount());
      form.setFieldValue("bank", Banks[0]?.label);
      form.setFieldValue("notes", `Salary ${generateNumbers()}`);
      form.setFieldValue("bank_account_id", Banks[0]?.value?.bank_account_id);
   };

   return (
      <InputsModal
         visible={visible}
         inputModalTitle={"New income"}
         onClose={onClose}>
         <div>
            {APP_MODE === "development" && !editMode && (
               <Button
                  title={"Fill data (dev mode)"}
                  onClick={handleFillData}
               />
            )}
            <form onSubmit={form.handleSubmit}>
               <div className="inputs-container">
                  <Input
                     id={"income_name"}
                     label={"Income source"}
                     placeholder={"Income source"}
                     type={"textInput"}
                     onChange={form.handleChange}
                     error={form.touched?.["income_name"] && Boolean(form.errors?.["income_name"])}
                     helperText={form.touched?.["income_name"] && form.errors?.["income_name"]}
                     value={form.values?.["income_name"]}
                     form={form}
                  />
                  <Input
                     id={"from_month"}
                     label={"From month"}
                     placeholder={"From month"}
                     type={"datePicker"}
                     minDate={dayjs()}
                     error={form.touched?.["from_month"] && Boolean(form.errors?.["from_month"])}
                     helperText={form.touched?.["from_month"] && form.errors?.["from_month"]}
                     value={form.values?.["from_month"]}
                     setDateValue={(val) => {
                        form.setFieldValue("from_month", val);
                        form.setFieldValue("to_month", val);
                     }}
                  />
                  <Input
                     id={"to_month"}
                     label={"To month"}
                     placeholder={"To month"}
                     minDate={form.values?.["from_month"]}
                     type={"datePicker"}
                     onChange={form.handleChange}
                     error={form.touched?.["to_month"] && Boolean(form.errors?.["to_month"])}
                     helperText={form.touched?.["to_month"] && form.errors?.["to_month"]}
                     value={form.values?.["to_month"]}
                     setDateValue={(val) => {
                        form.setFieldValue("to_month", val);
                     }}
                  />
                  <Input
                     id={"amount"}
                     label={"Amount"}
                     placeholder={"Amount"}
                     type={"textInput"}
                     onChange={form.handleChange}
                     currencyMode={true}
                     error={form.touched?.["amount"] && Boolean(form.errors?.["amount"])}
                     helperText={form.touched?.["amount"] && form.errors?.["amount"]}
                     value={form.values?.["amount"]}
                     form={form}
                  />
                  <BankDropdownInput
                     id={"bank"}
                     label={"Bank"}
                     placeholder={"Bank"}
                     type={"dropDown"}
                     onChange={form.handleChange}
                     error={form.touched?.["bank"] && Boolean(form.errors?.["bank"])}
                     helperText={form.touched?.["bank"] && form.errors?.["bank"]}
                     value={form.values?.["bank"]}
                     form={form}
                     setDropDownValue={(val) => {
                        console.log("bank setDropDownValue", val);
                        form.setFieldValue("bank", val?.bank_name);
                        form.setFieldValue("bank_account_id", val?.bank_account_id);
                     }}
                  />
                  <Input
                     id={"notes"}
                     label={"Notes"}
                     placeholder={"Notes"}
                     type={"textInput"}
                     onChange={form.handleChange}
                     error={form.touched?.["notes"] && Boolean(form.errors?.["notes"])}
                     helperText={form.touched?.["notes"] && form.errors?.["notes"]}
                     value={form.values?.["notes"]}
                     form={form}
                  />
               </div>
               <InputModalsButton
                  onClose={onClose}
                  button1Title={"Cancel"}
                  button2Title={editMode ? "Update" : "Create"}
               />
            </form>
         </div>
      </InputsModal>
   );
}
