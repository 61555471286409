/** @format */

import React, { useEffect, useState } from "react";
import "./index.css";
import { SidebarData } from "../../data/sidebarData";
import Button from "../Button";
import IncomeIcon from "../../icons/IncomeIcon";
import { ActiveTab, InActiveTab } from "./sidebarTabStatusStyles";
import Logout from "../Logout";
import { useNavigate, useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import { useSearchAndFilterStore } from "store/searchAndFilter";
import { useAppStore } from "store/useApp";

export default function Sidebar() {
   const [SelectedTab, setSelectedTab] = useState("Income");
   const { onSearchQueryChange } = useAppStore((state) => state);
   const clearFiltersYearAndMonth = useAppStore().clearFiltersYearAndMonth;

   const navigate = useNavigate();
   const location = useLocation();

   const handleTabClick = (tabName) => {
      setSelectedTab(tabName);
   };

   useEffect(() => {
      const path = location.pathname.split("/");
      setSelectedTab(`/${path[1]}`);
   }, [location]);

   console.log(SelectedTab, "SelectedTab");

   const navigateTo = (path, params) => {
      onSearchQueryChange("");
      clearFiltersYearAndMonth();
      const currentYear = new Date().getFullYear();
      navigate(`${path}/${params}`);
   };

   return (
      <div className="side-bar-container">
         <Typography
            marginTop={"1rem"}
            marginLeft={"2rem"}
            fontSize={"2rem"}
            color={"secondary"}
            fontWeight={"bold"}
            letterSpacing={4}>
            YABA
         </Typography>
         <div className="side-bar-main-container">
            {SidebarData.map((item, index) => (
               <>
                  <div className="side-bar-title">{item.title}</div>
                  <div className="side-bar-subtitles-container">
                     {item.subTitles?.map((SubItem, subIndex) => (
                        <>
                           <Button
                              onClick={() => navigateTo(SubItem.path, SubItem?.params)}
                              title={SubItem.title}
                              startIcon={SubItem.Icon}
                              justifyContent="flex-start"
                              backgroundColor={SelectedTab === SubItem.path ? ActiveTab.backgroundColor : InActiveTab.backgroundColor}
                              color={SelectedTab === SubItem.path ? ActiveTab.color : InActiveTab.color}
                              hoverBackgroundColor={SelectedTab === SubItem.path ? ActiveTab.hoverBackgroundColor : InActiveTab.hoverBackgroundColor}
                              hoverColor={SelectedTab === SubItem.path ? ActiveTab.hoverColor : InActiveTab.hoverColor}
                           />
                           <div className="side-bar-tab-margins"></div>
                        </>
                     ))}
                  </div>
               </>
            ))}
         </div>
         <Logout />
      </div>
   );
}
