/** @format */

import { readRequest } from "APIs/crud";
import { create } from "zustand";

const API_ENDPOINT = "/bank_accounts/account_summary";

export const useAccountSummaryStore = (set, get) => ({
   accountSummary: [],
   getAccountSummary: async (date) => {
      return new Promise((resolve, reject) => {
         readRequest(`${API_ENDPOINT}/${date}/`)
            .then((result) => {
               set({ accountSummary: result?.data?.response });
               resolve(result?.data?.response);
            })
            .catch((err) => reject(err));
      });
   },
});
