import React from 'react';
import './index.css';
import BreadCrumbs from '../BreadCrumbs';
import NotificationIcon from '../../icons/NotificationIcon';

export default function Header( { breadCrumbsData } ) {
  return (
    <div className='header-container'>
      <BreadCrumbs breadCrumbsData={ breadCrumbsData } />
      <NotificationIcon />
    </div>
  );
}
