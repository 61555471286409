/** @format */

import BankDropdownInput from "Components/BankDropdownInput";
import Button from "Components/Button";
import dayjs from "dayjs";
import { APP_MODE } from "definition";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useBankStore } from "store/bank";
import { generateAmount, generateNumbers } from "utils/stringFunction";
import * as Yup from "yup";
import Input from "Components/Input";
import InputModalsButton from "Components/InputModalButtons";
import InputsModal from "Components/InputsModal";
import { useGoalsStore } from "store/goals";
import "./index.css";
import { Payload, PayloadSchema } from "./inputs";
import { useAppStore } from "store/useApp";
const SAVING_ACCOUNTS = [
   {
      label: "HDFC",
      value: "hdfc",
   },
   {
      label: "Canara",
      value: "canara",
   },
   {
      label: "SBI",
      value: "sbi",
   },
   {
      label: "ICICI",
      value: "icici",
   },
];

export default function InputsComponent({ visible, onClose, editMode, preValues }) {
   const [inputValues, setinputValues] = useState(editMode ? preValues : Payload);
   const GoalsStore = useAppStore();
   const validationSchema = Yup.object().shape(PayloadSchema);
   const Banks = useAppStore((state) => state.banks);

   const onSubmit = (values) => {
      const payload = generatePayload(values);
      editMode ? UpdateGoal({ ...payload, goal_id: values.goal_id }) : AddGoal(payload);
   };

   const resetForm = () => {
      form.resetForm();
      onClose();
   };

   const AddGoal = (payload) => {
      if (!checkBeginningBalance()) {
         GoalsStore.addGoal(payload)
            .then(() => resetForm())
            .catch((err) => console.log(err));
      }
   };

   const UpdateGoal = (payload) => {
      GoalsStore.updateGoal(payload)
         .then(() => resetForm())
         .catch((err) => console.log(err));
   };

   const form = useFormik({
      initialValues: inputValues,
      validationSchema: validationSchema,
      onSubmit: onSubmit,
      enableReinitialize: true,
   });

   useEffect(() => {
      if (editMode) {
         setinputValues(preValues);
      } else {
         setinputValues(Payload);
      }
   }, [editMode, preValues]);

   console.log(form.values, "form.values");

   const generatePayload = (values) => {
      return {
         goal_name: values.goal_name || "",
         by_when: dayjs(values.by_when).format("YYYY-MM-DD") || "",
         start_by: dayjs(values.start_by).format("YYYY-MM-DD") || "",
         amount: parseFloat(values.amount) || 0,
         saving_in_account: values.saving_in_account || "", // confirm this
         saving_in_bank_account_id: values.saving_in_bank_account_id || "",
         saving_from_account: values.saving_from_account || "", // confirm this
         saving_from_bank_account_id: values.saving_from_bank_account_id || "",
         comments: values.comments || "",
         beginning_balance: parseFloat(values.beginning_balance) || 0,
         float: parseFloat(values.float) || 0,
      };
   };

   const handleFillData = () => {
      form.setFieldValue("goal_name", `Goal ${generateNumbers()}`);
      form.setFieldValue("amount", generateAmount());
      form.setFieldValue("beginning_balance", 0);
      form.setFieldValue("start_by", dayjs().format("YYYY-MM-DD"));
      form.setFieldValue("by_when", dayjs().format("YYYY-MM-DD"));
      form.setFieldValue("saving_from_account", Banks[0]?.label);
      form.setFieldValue("saving_from_bank_account_id", Banks[0]?.value.bank_account_id);
      form.setFieldValue("saving_in_account", Banks[1]?.label);
      form.setFieldValue("saving_in_bank_account_id", Banks[1]?.value?.bank_account_id);
      form.setFieldValue("comments", `Comment ${generateNumbers()}`);
   };

   const checkBeginningBalance = () => {
      console.log("checkBeginningBalance");
      if (parseFloat(form.values?.["amount"]) < parseFloat(form.values?.["beginning_balance"])) {
         return true;
      } else {
         return false;
      }
   };

   useEffect(() => {
      checkBeginningBalance();
   }, [form.values?.["beginning_balance"]]);

   return (
      <InputsModal
         visible={visible}
         inputModalTitle={editMode ? "Edit the goal" : "Create new goal"}
         onClose={onClose}>
         <div className="input-modal-inputs-container">
            {APP_MODE === "development" && !editMode && (
               <Button
                  title={"Fill data (dev mode)"}
                  onClick={handleFillData}
               />
            )}
            <form onSubmit={form.handleSubmit}>
               <div className="inputs-container">
                  <Input
                     id={"goal_name"}
                     label={"Goal name"}
                     placeholder={"Goal name"}
                     type={"textInput"}
                     onChange={form.handleChange}
                     error={form.touched?.["goal_name"] && Boolean(form.errors?.["goal_name"])}
                     helperText={form.touched?.["goal_name"] && form.errors?.["goal_name"]}
                     value={form.values?.["goal_name"]}
                     form={form}
                  />
                  <Input
                     id={"amount"}
                     label={"Enter the goal amount"}
                     placeholder={"Enter the goal amount"}
                     type={"textInput"}
                     onChange={form.handleChange}
                     error={form.touched?.["amount"] && Boolean(form.errors?.["amount"])}
                     helperText={form.touched?.["amount"] && form.errors?.["amount"]}
                     value={form.values?.["amount"]}
                     form={form}
                     currencyMode={true}
                  />
                  <Input
                     id={"beginning_balance"}
                     label={"Beginning balance"}
                     placeholder={"Beginning balance"}
                     type={"textInput"}
                     currencyMode={true}
                     onChange={form.handleChange}
                     error={checkBeginningBalance() || (form.touched?.["beginning_balance"] && Boolean(form.errors?.["beginning_balance"]))}
                     helperText={(checkBeginningBalance() && "Beginning balance cannot be greater than goal amount") || (form.touched?.["beginning_balance"] && form.errors?.["beginning_balance"])}
                     value={form.values?.["beginning_balance"]}
                     form={form}
                  />
                  <Input
                     id={"start_by"}
                     label={"When do you want to start *"}
                     placeholder={"When do you want to start *"}
                     type={"datePicker"}
                     setDateValue={(val) => {
                        form.setFieldValue("start_by", val);
                        form.setFieldValue("by_when", val);
                     }}
                     minDate={dayjs()}
                     error={form.touched?.["start_by"] && Boolean(form.errors?.["start_by"])}
                     helperText={form.touched?.["start_by"] && form.errors?.["start_by"]}
                     value={form.values?.["start_by"]}
                     form={form}
                  />
                  <Input
                     id={"by_when"}
                     label={"When do you want to complete this goal  *"}
                     placeholder={"When do you want to complete this goal  *"}
                     type={"datePicker"}
                     setDateValue={(val) => form.setFieldValue("by_when", val)}
                     minDate={form.values?.["start_by"]}
                     error={form.touched?.["by_when"] && Boolean(form.errors?.["by_when"])}
                     helperText={form.touched?.["by_when"] && form.errors?.["by_when"]}
                     value={form.values?.["by_when"]}
                     form={form}
                  />
                  <BankDropdownInput
                     id={"saving_from_account"}
                     label={"Transfer amount from"}
                     placeholder={"Transfer amount from  *"}
                     type={"dropDown"}
                     onChange={form.handleChange}
                     error={form.touched?.["saving_from_account"] && Boolean(form.errors?.["saving_from_account"])}
                     helperText={form.touched?.["saving_from_account"] && form.errors?.["saving_from_account"]}
                     value={form.values?.["saving_from_account"]}
                     form={form}
                     setDropDownValue={(val) => {
                        form.setFieldValue("saving_from_account", val?.bank_name);
                        form.setFieldValue("saving_from_bank_account_id", val?.bank_account_id);
                        form.setFieldValue("saving_in_account", "");
                        form.setFieldValue("saving_in_bank_account_id", "");
                     }}
                  />
                  <BankDropdownInput
                     id={"saving_in_account"}
                     label={"Where do you want to save for this goal  *"}
                     placeholder={"Where do you want to save for this goal  *"}
                     type={"dropDown"}
                     removedBankId={form.values?.["saving_from_bank_account_id"]}
                     onChange={form.handleChange}
                     error={form.touched?.["saving_in_account"] && Boolean(form.errors?.["saving_in_account"])}
                     helperText={form.touched?.["saving_in_account"] && form.errors?.["saving_in_account"]}
                     value={form.values?.["saving_in_account"]}
                     form={form}
                     setDropDownValue={(val) => {
                        form.setFieldValue("saving_in_account", val?.bank_name);
                        form.setFieldValue("saving_in_bank_account_id", val?.bank_account_id);
                     }}
                  />
                  <Input
                     id={"comments"}
                     label={"Comments"}
                     placeholder={"Comments"}
                     type={"textInput"}
                     onChange={form.handleChange}
                     error={form.touched?.["comments"] && Boolean(form.errors?.["comments"])}
                     helperText={form.touched?.["comments"] && form.errors?.["comments"]}
                     value={form.values?.["comments"]}
                     form={form}
                  />
               </div>
               <InputModalsButton
                  onClose={onClose}
                  button1Title={"Cancel"}
                  button2Title={editMode ? "Update" : "Create"}
               />
            </form>
         </div>
      </InputsModal>
   );
}
