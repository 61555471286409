import React from 'react';

export default function Icon() {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.02 14.91C20.71 14.91 18.02 17.6 18.02 20.91V23.8C18.02 24.41 17.76 25.34 17.45 25.86L16.3 27.77C15.59 28.95 16.08 30.26 17.38 30.7C21.69 32.14 26.34 32.14 30.65 30.7C31.86 30.3 32.39 28.87 31.73 27.77L30.58 25.86C30.28 25.34 30.02 24.41 30.02 23.8V20.91C30.02 17.61 27.32 14.91 24.02 14.91Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" />
            <path d="M25.87 15.2C25.56 15.11 25.24 15.04 24.91 15C23.95 14.88 23.03 14.95 22.17 15.2C22.46 14.46 23.18 13.94 24.02 13.94C24.86 13.94 25.58 14.46 25.87 15.2Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M27.02 31.06C27.02 32.71 25.67 34.06 24.02 34.06C23.2 34.06 22.44 33.72 21.9 33.18C21.36 32.64 21.02 31.88 21.02 31.06" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" />
        </svg>

    );
}
