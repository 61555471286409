/** @format */

import BankDropdownInput from "Components/BankDropdownInput";
import Button from "Components/Button";
import CheckBoxInput from "Components/CheckBoxInput";
import CurrencyFormatText from "Components/CurrencyFormatText";
import HighlightedInputsContainer from "Components/HighlightedInputsContainer";
import Input from "Components/Input";
import InputModalsButton from "Components/InputModalButtons";
import InputsModal from "Components/InputsModal";
import dayjs from "dayjs";
import { APP_MODE } from "definition";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useActualExpensesStore } from "store/actualExpenses";
import { useBankStore } from "store/bank";
import { generateAmount, generateNumbers } from "utils/stringFunction";
import * as Yup from "yup";
import "./index.css";
import InlineAlert from "Components/InlineAlert";
import { Payload, PayloadSchema } from "./inputs";
import { useLocation } from "react-router-dom";
import { useAppStore } from "store/useApp";

export default function InputsComponent({ visible, onClose, editMode, preValues }) {
   const [inputValues, setinputValues] = useState(editMode ? preValues : Payload);
   const [monthChanged, setmonthChanged] = useState(true);
   const ActualExpensesStore = useAppStore();
   const [selectedExpenseType, setSelectedExpenseType] = useState(null);
   const validationSchema = Yup.object().shape(PayloadSchema);
   const Banks = useAppStore((state) => state.banks);
   const location = useLocation();

   useEffect(() => {
      if (editMode) {
         setinputValues(preValues);
         updateSelectedExpenseType(preValues);
      } else {
         setinputValues(Payload);
      }
   }, [editMode, preValues]);

   const onSubmit = (values) => {
      let payload = generatePayload(values);
      editMode ? UpdateActualExpense({ ...payload, actual_expense_id: values?.actual_expense_id }) : AddActualExpense(payload);
   };

   const AddActualExpense = (payload) => {
      ActualExpensesStore.addActualExpense(payload)
         .then((res) => resetForm())
         .catch((err) => console.log(err.message));
   };

   const UpdateActualExpense = (payload) => {
      ActualExpensesStore.updateActualExpense(payload)
         .then((res) => resetForm())
         .catch((err) => console.log(err.message));
   };

   const resetForm = () => {
      form.resetForm();
      onClose();
   };

   const isAddExpenseRoute = () => {
      const { pathname } = location;
      return pathname === "/actual_expenses/add";
   };

   const updateSelectedExpenseType = (preValues) => {
      console.log("updateSelectedExpenseType", form.values["expensed_against"]);
      if (form.values["expensed_against"] === "unexpected") {
         setSelectedExpenseType(null);
         form.setFieldValue("unexpected_expense", true);
      }
      {
         if (form.values["expensed_against"] === "expected_expense") {
            const expectedExpense = ActualExpensesStore.expectedExpensesForMonth.find((item) => item?.expected_expenses_summary_id === preValues.expected_expenses_summary_id);
            console.log(expectedExpense, "updateSelectedExpenseType expected_expense", ActualExpensesStore.expectedExpensesForMonth);
            setSelectedExpenseType(expectedExpense);
         } else if (form.values["expensed_against"] === "goal") {
            const goal = ActualExpensesStore.goalsForMonth.find((item) => item?.goal_id === preValues.goal_id);
            console.log(goal, "updateSelectedExpenseType goal", ActualExpensesStore.goalsForMonth);
            setSelectedExpenseType(goal);
         }
      }
   };

   const form = useFormik({
      initialValues: inputValues,
      validationSchema: validationSchema,
      onSubmit: onSubmit,
      enableReinitialize: true,
   });

   const generatePayload = (values) => {
      return {
         expense_date: dayjs(values.expense_date).format("YYYY-MM-DD") || "",
         expected_expenses_summary_id: form.values["unexpected_expense"] ? "" : selectedExpenseType?.expected_expenses_summary_id || "",
         expensed_amount: parseFloat(values.expensed_amount) || 0,
         reason: values.reason || "",
         comments: values.comments || "",
         expensed_against: form.values["unexpected_expense"] ? "unexpected" : values.expensed_against || "",
         goal_id: form.values["unexpected_expense"] ? "" : selectedExpenseType?.goal_id || "",
         bank_account_id: form.values["bank_account_id"] ? values.bank_account_id || "" : "",
         bank: form.values["bank"] ? values.bank || "" : "",
         expected_expenses_id: form.values["unexpected_expense"] ? "" : selectedExpenseType?.expected_expenses_id || "",
      };
   };

   const handleFillData = () => {
      form.setFieldValue("reason", `Actual expense ${generateNumbers()}`);
      form.setFieldValue("expense_date", dayjs().format("YYYY-MM-DD"));
      form.setFieldValue("expensed_amount", generateAmount());
      form.setFieldValue("comments", `test comments ${generateNumbers()}`);
      form.setFieldValue("bank", Banks[0]?.bank_name);
      form.setFieldValue("bank_account_id", Banks[0]?.bank_account_id);
   };

   const getExpectedExpensesForMonth = async (month) => {
      const checkMonth = dayjs(month).format("YYYY-MM-DD");
      ActualExpensesStore.resetExpenseTypes();
      setmonthChanged(true);
      ActualExpensesStore.getExpectedExpenseForMonth(checkMonth)
         .then()
         .catch((err) => console.log(err.message));
      ActualExpensesStore.getGoalsForMonth(checkMonth)
         .then()
         .catch((err) => console.log(err.message));
   };

   useEffect(() => {
      if (editMode || !monthChanged) {
         updateSelectedExpenseType(preValues);
      } else {
         console.log("useeffect edit mode is");
         form.setFieldValue("expensed_against", "expected_expense");
         setSelectedExpenseType(ActualExpensesStore.expectedExpensesForMonth[0]);
      }
   }, [ActualExpensesStore.expectedExpensesForMonth, monthChanged, ActualExpensesStore.goalsForMonth]);

   const renderOptionForExpenseType = () => {
      if (form.values?.["expensed_against"] === "expected_expense") {
         return ActualExpensesStore.expectedExpensesForMonth;
      } else if (form.values?.["expensed_against"] === "goal") {
         return ActualExpensesStore.goalsForMonth;
      }
   };
   useEffect(() => {
      if (form.values["expensed_against"] === "unexpected") {
         form.setFieldValue("expected_expense", "");
      } else if (form.values["expensed_against"] === "goal") {
         setSelectedExpenseType(ActualExpensesStore.goalsForMonth[0]);
      } else if (form.values["expensed_against"] === "expected_expense") {
         setSelectedExpenseType(ActualExpensesStore.expectedExpensesForMonth[0]);
      }
   }, [form.values["expensed_against"]]);

   return (
      <InputsModal
         visible={visible}
         inputModalTitle={editMode ? "Edit actual expense" : "New actual expense"}
         onClose={onClose}>
         <div className="input-modal-inputs-container">
            {!isAddExpenseRoute() && (
               <InlineAlert
                  borderColor={"#1877F2"}
                  background={"rgba(24, 119, 242, 0.1)"}
                  text={"Did you know"}
                  mode={"info"}>
                  <div className="text-[0.8rem] mt-2">You can also add Actual expenses add your actual expenses from your mobile by clicking on this link {"https://yaba.in/actual_expenses/add"}</div>
               </InlineAlert>
            )}
            {APP_MODE === "development" && !editMode && (
               <Button
                  title={"Fill data (dev mode)"}
                  onClick={handleFillData}
               />
            )}
            <form onSubmit={form.handleSubmit}>
               <div className="inputs-container">
                  <Input
                     id={"expense_date"}
                     label={"Expense date"}
                     placeholder={"Expense date"}
                     type={"datePicker"}
                     pickerType={"date"}
                     maxDate={dayjs()}
                     setDateValue={(val) => {
                        form.setFieldValue("expense_date", val);
                        getExpectedExpensesForMonth(val);
                     }}
                     error={form.touched?.["expense_date"] && Boolean(form.errors?.["expense_date"])}
                     helperText={form.touched?.["expense_date"] && form.errors?.["expense_date"]}
                     value={form.values?.["expense_date"]}
                     form={form}
                  />
                  <Input
                     id={"reason"}
                     label={"Reason"}
                     placeholder={"Reason"}
                     type={"textInput"}
                     onChange={form.handleChange}
                     error={form.touched?.["reason"] && Boolean(form.errors?.["reason"])}
                     helperText={form.touched?.["reason"] && form.errors?.["reason"]}
                     value={form.values?.["reason"]}
                     form={form}
                  />
                  <Input
                     id={"expensed_amount"}
                     label={"Expensed amount"}
                     placeholder={"Expensed amount"}
                     type={"textInput"}
                     currencyMode={true}
                     onChange={form.handleChange}
                     error={form.touched?.["expensed_amount"] && Boolean(form.errors?.["expensed_amount"])}
                     helperText={form.touched?.["expensed_amount"] && form.errors?.["expensed_amount"]}
                     value={form.values?.["expensed_amount"]}
                     form={form}
                  />
                  {/* <TagDropdownInput
                     id={"tag"}
                     label={"Add tag to expense (Optional)"}
                     placeholder={"Tag"}
                     type={"dropDown"}
                     options={[{ label: "Tag", value: "tag" }]}
                     onChange={form.handleChange}
                     error={form.touched?.["tag"] && Boolean(form.errors?.["tag"])}
                     helperText={form.touched?.["tag"] && form.errors?.["tag"]}
                     value={form.values?.["tag"]}
                     form={form}
                     setDropDownValue={(val) => form.setFieldValue("tag", val)}
                  /> */}
                  <HighlightedInputsContainer>
                     <div>
                        <CheckBoxInput
                           title={"This is an unexpected expense"}
                           value={form.values?.["unexpected_expense"]}
                           onChange={(value) => form.setFieldValue("unexpected_expense", value)}
                        />{" "}
                        {!form.values?.["unexpected_expense"] ? (
                           <>
                              {ActualExpensesStore.expenseTypes?.length > 0 && (
                                 <div style={{ marginTop: "1rem" }}>
                                    <Input
                                       id={"expensed_against"}
                                       label={"Choose the type of expense*"}
                                       type={"Select"}
                                       options={ActualExpensesStore?.expenseTypes}
                                       onChange={form.handleChange}
                                       error={form.touched?.["expensed_against"] && Boolean(form.errors?.["expensed_against"])}
                                       helperText={form.touched?.["expensed_against"] && form.errors?.["expensed_against"]}
                                       value={form.values?.["expensed_against"]}
                                       form={form}
                                    />
                                    <Input
                                       id={"expected_expense"}
                                       label={"Expected expense"}
                                       placeholder={"Expected expense"}
                                       type={"dropDown"}
                                       diableCreate={true}
                                       options={renderOptionForExpenseType()}
                                       onChange={form.handleChange}
                                       error={form.touched?.["expected_expense"] && Boolean(form.errors?.["expected_expense"])}
                                       helperText={form.touched?.["expected_expense"] && form.errors?.["expected_expense"]}
                                       value={selectedExpenseType?.value}
                                       setDropDownValue={(val) => {
                                          setSelectedExpenseType(val);
                                       }}
                                       form={form}
                                    />
                                    {form.values["expensed_against"] === "expected_expense" && (
                                       <div className="remaining-amount-container">
                                          <div
                                             style={{ color: selectedExpenseType?.remaining_amount < 0 ? "#D90429" : "#00C4A2" }}
                                             className="remaining-amount">
                                             <CurrencyFormatText
                                                value={selectedExpenseType?.remaining_amount > 0 ? selectedExpenseType?.remaining_amount : `-${selectedExpenseType?.remaining_amount}`}
                                             />
                                          </div>
                                          <div className="remaining-amount-text">
                                             remaining out of{" "}
                                             <span>
                                                <CurrencyFormatText value={selectedExpenseType?.planned_amount} />
                                             </span>
                                          </div>
                                       </div>
                                    )}
                                    {form.values["expensed_against"] === "goal" && (
                                       <div className="remaining-amount-container">
                                          <div className="remaining-amount-text">
                                             Remaining amount is{" "}
                                             <span style={{ fontWeight: "bold" }}>
                                                <CurrencyFormatText value={selectedExpenseType?.saved_amount} />
                                             </span>
                                          </div>
                                       </div>
                                    )}
                                 </div>
                              )}
                           </>
                        ) : (
                           <BankDropdownInput
                              id={"bank"}
                              label={"Choose an account"}
                              placeholder={"Choose an account"}
                              type={"dropDown"}
                              onChange={form.handleChange}
                              error={form.touched?.["bank"] && Boolean(form.errors?.["bank"])}
                              helperText={form.touched?.["bank"] && form.errors?.["bank"]}
                              value={form.values?.["bank"]}
                              form={form}
                              setDropDownValue={(val) => {
                                 form.setFieldValue("bank", val?.bank_name);
                                 form.setFieldValue("bank_account_id", val?.bank_account_id);
                              }}
                           />
                        )}
                     </div>
                  </HighlightedInputsContainer>
                  <Input
                     id={"comments"}
                     label={"Comments"}
                     placeholder={"Comments"}
                     type={"textInput"}
                     onChange={form.handleChange}
                     error={form.touched?.["comments"] && Boolean(form.errors?.["comments"])}
                     helperText={form.touched?.["comments"] && form.errors?.["comments"]}
                     value={form.values?.["comments"]}
                     form={form}
                  />
               </div>
               <InputModalsButton
                  onClose={onClose}
                  button1Title={isAddExpenseRoute() ? "" : "Cancel"}
                  button2Title={editMode ? "Update" : "Create"}
               />
            </form>
         </div>
      </InputsModal>
   );
}
