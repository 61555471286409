/** @format */

export const generateYears = () => {
   const startYear = new Date().getFullYear();
   const endYear = 2050;
   const years = [];
   for (let year = startYear; year <= endYear; year++) {
      years.push(year);
   }
   return years;
};
