/** @format */

import React, { useState } from "react";
import AssociatedGoals from "Components/AccountSummaryCells/AssociatedGoals";
import CurrencyFormatText from "Components/CurrencyFormatText";
import TransferDetails from "Pages/Reminders/TransferDetails";
import { useReminderStore } from "store/reminders";
import HighlightText from "Components/HighlightText";
import { useAppStore } from "store/useApp";

export default function TransferCard({ item, index }) {
   const [showTransferDetails, setshowTransferDetails] = useState(false);
   const { transfer_from, transfer_to, goal_transfer_summaries, amount } = item;
   const { completeAllGoals } = useAppStore((state) => state);

   const markAsComplete = () => {
      completeAllGoals(goal_transfer_summaries, index);
   };

   const toggleTransferDetails = () => setshowTransferDetails(!showTransferDetails);

   return (
      <div className="bg-[#fff] grid grid-flow-row grid-cols-12 p-4 rounded-[0.5rem]">
         <TransferDetails
            visible={showTransferDetails}
            onClose={toggleTransferDetails}
            data={item}
            markAsComplete={markAsComplete}
         />
         <div className="col-span-2 flex flex-col gap-1">
            <div className="text-[0.8rem] text-[#898995] font-semibold">From account</div>
            <div className="text-[#000] font-semibold">
               <HighlightText value={transfer_from} />
            </div>
         </div>
         <div className="col-span-1 flex flex-col gap-1">
            <div className="text-[0.8rem] text-[#898995] font-semibold">To account</div>
            <div className="text-[#000] font-semibold">
               {" "}
               <HighlightText value={transfer_to} />
            </div>
         </div>
         <div className="col-span-5 flex flex-col gap-1">
            <div className="text-[0.8rem] text-[#898995] font-semibold">Goals</div>
            <AssociatedGoals
               onClickViewMore={toggleTransferDetails}
               value={goal_transfer_summaries}
            />
         </div>
         <div className="col-span-2 flex flex-col gap-1">
            <div className="text-[0.8rem] text-[#898995] font-semibold">Amount</div>
            <div className="text-[#000] font-semibold">
               <CurrencyFormatText value={amount} />
            </div>
         </div>
         <div className="col-span-2 flex flex-col items-center gap-2">
            <div
               onClick={markAsComplete}
               className="mark-as-complete">
               Mark as complete
            </div>
            <div className="skip-this-month">Skip this month</div>
         </div>
      </div>
   );
}
