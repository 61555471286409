/** @format */

import * as React from "react";
import PropTypes from "prop-types";
import CircularProgress, { circularProgressClasses } from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const size = 120,
   thickness = 9,
   value = 22,
   secColor = "rgba(56,176,0,0.2)";

const progressSx = {
   borderRadius: "50%",
   boxShadow: `inset 0 0 0 ${(thickness / 260) * size}px ${secColor}`,
};
function CircularProgressWithLabel(props) {
   return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
         <CircularProgress
            thickness={4}
            sx={progressSx}
            style={{ color: "#38B000" }}
            variant="determinate"
            {...props}
         />
         <Box
            sx={{
               top: 0,
               left: 0,
               bottom: 0,
               right: 0,
               position: "absolute",
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
            }}>
            <Typography
               variant="caption"
               component="div"
               color="text.secondary">
               {`${Math.round(props.value)}%`}
            </Typography>
         </Box>
      </Box>
   );
}

CircularProgressWithLabel.propTypes = {
   /**
    * The value of the progress indicator for the determinate variant.
    * Value between 0 and 100.
    * @default 0
    */
   value: PropTypes.number.isRequired,
};

export default function CircularStatic({ progress }) {
   return <CircularProgressWithLabel value={progress} />;
}
