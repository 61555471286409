/** @format */

import React from "react";
import Tooltip from "../Components/Tooltip";

function Icon({ disabled }) {
   return (
      <Tooltip
         placement={"top"}
         title={"Convert to goal"}
         toolTipBackgroundColor={disabled ? "grey" : "#EB304A"}
         toolTipFontColor={"#ffffff"}>
         <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="24"
            fill="none"
            viewBox="0 0 24 24">
            <g clipPath="url(#clip0_492_17162)">
               <path
                  fill="#fff"
                  d="M0 0H24V24H0z"></path>
               <circle
                  cx="11"
                  cy="12"
                  r="8.25"
                  stroke="#EB304A"
                  strokeWidth="1.5"></circle>
               <circle
                  cx="11"
                  cy="12"
                  r="2.85"
                  stroke="#EB304A"
                  strokeWidth="1.5"></circle>
               <circle
                  cx="10.999"
                  cy="12"
                  r="5.55"
                  stroke="#EB304A"
                  strokeWidth="1.5"></circle>
               <path
                  fill="#EB304A"
                  stroke="#fff"
                  strokeWidth="0.5"
                  d="M21.552 1.983l.137-.707-.545.47-1.443 1.24a1.25 1.25 0 00-.267.323l-.886 1.535a.749.749 0 01-.185.214l-1.038.817-2.378 1.874-4.167 3.282a1 1 0 101.238 1.571l4.167-3.282 2.378-1.874 1.05-.827a.75.75 0 01.23-.123l1.787-.587c.128-.042.247-.104.355-.183l1.472-1.092.572-.423-.711-.027L21.14 4.1l.412-2.117z"></path>
            </g>
            <defs>
               <clipPath id="clip0_492_17162">
                  <path
                     fill="#fff"
                     d="M0 0H24V24H0z"></path>
               </clipPath>
            </defs>
         </svg>
      </Tooltip>
   );
}

export default Icon;
