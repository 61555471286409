/** @format */

import { readRequest } from "APIs/crud";
import { create } from "zustand";

const API_ENDPOINT = "/monthly_summary/";

export const useMonthlySummaryStore = (set, get) => ({
   monthlySummary: [],
   getMonthlySummary: async () => {
      return new Promise((resolve, reject) => {
         readRequest(API_ENDPOINT).then((result) => {
            if (result.status === 200 || result.status === 201) {
               set({ monthlySummary: result?.data?.response });
               resolve(result?.data?.response);
            }
         });
      });
   },
});
