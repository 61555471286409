/** @format */

import { createRequest, readRequest } from "APIs/crud";
import { create } from "zustand";

const API_ENDPOINT = "/tags/";

export const useTagStore = (set, get) => ({
   tags: [],
   getAlltags: async () => {
      return new Promise((resolve, reject) => {
         readRequest(API_ENDPOINT)
            .then((result) => {
               const tagsData = result?.data?.response?.map((tag) => {
                  return { value: tag.tag_name, label: tag.tag_name };
               });
               set({ tags: tagsData });
               resolve(result);
            })
            .catch((err) => reject(err));
      });
   },
   addTag: async (data) => {
      return new Promise((resolve, reject) => {
         const payload = { tag_name: data };
         createRequest(API_ENDPOINT, payload)
            .then((result) => {
               set({
                  tags: [
                     ...get().tags,
                     {
                        value: result?.data?.tag_name,
                        label: result?.data?.tag_name,
                     },
                  ],
               });
               resolve(result);
            })
            .catch((err) => reject(err));
      });
   },
});
