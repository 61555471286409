/** @format */

import BankDropdownInput from "Components/BankDropdownInput";
import Input from "Components/Input";
import InputModalsButton from "Components/InputModalButtons";
import InputsModal from "Components/InputsModal";
import dayjs from "dayjs";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSavingsStore } from "store/savings";
import * as Yup from "yup";
import "./index.css";
import { Payload, PayloadSchema } from "./inputs";
import { useAppStore } from "store/useApp";

export default function InputsComponent({ visible, onClose, editMode, preValues }) {
   const [inputValues, setinputValues] = useState(editMode ? preValues : Payload);
   const SavingsStore = useAppStore();
   const validationSchema = Yup.object().shape(PayloadSchema);

   const onSubmit = (values) => {
      if (!checkSavedAmount()) {
         const payload = generatePayload(values);
         editMode ? SavingsStore.updateSaving({ ...payload, saving_id: values.saving_id }) : SavingsStore.addSaving(payload);
         form.resetForm();
         onClose();
      }
   };

   const form = useFormik({
      initialValues: inputValues,
      validationSchema: validationSchema,
      onSubmit: onSubmit,
      enableReinitialize: true,
   });

   useEffect(() => {
      if (editMode) {
         setinputValues(preValues);
      } else {
         setinputValues(Payload);
      }
   }, [editMode, preValues]);

   console.log(inputValues, "inputValues goals");

   const generatePayload = (values) => {
      return {
         saving_name: values.saving_name || "",
         start_month: dayjs(values.start_month).format("YYYY-MM-DD") || "",
         planned_amount: parseFloat(values.planned_amount) || 0,
         saving_in_account: values.saving_in_account || "", // confirm this
         comments: values.comments || "",
         saved_amount: parseFloat(values.saved_amount) || 0,
         bank_account_id: values.bank_account_id || "",
      };
   };

   const checkSavedAmount = () => {
      if (parseFloat(form.values?.["saved_amount"]) > parseFloat(form.values?.["planned_amount"])) {
         return true;
      } else {
         return false;
      }
   };

   return (
      <InputsModal
         visible={visible}
         inputModalTitle={editMode ? "Edit the savings" : "Create new savings"}
         onClose={onClose}>
         <div className="input-modal-inputs-container">
            <form onSubmit={form.handleSubmit}>
               <div className="inputs-container">
                  <Input
                     id={"saving_name"}
                     label={"Saving name"}
                     placeholder={"Saving name"}
                     type={"textInput"}
                     onChange={form.handleChange}
                     error={form.touched?.["saving_name"] && Boolean(form.errors?.["saving_name"])}
                     helperText={form.touched?.["saving_name"] && form.errors?.["saving_name"]}
                     value={form.values?.["saving_name"]}
                     form={form}
                  />
                  <Input
                     id={"planned_amount"}
                     label={"How much would you like to save per month ?*"}
                     placeholder={"How much would you like to save?*"}
                     type={"textInput"}
                     currencyMode={true}
                     onChange={form.handleChange}
                     error={form.touched?.["planned_amount"] && Boolean(form.errors?.["planned_amount"])}
                     helperText={form.touched?.["planned_amount"] && form.errors?.["planned_amount"]}
                     value={form.values?.["planned_amount"]}
                     form={form}
                  />
                  <Input
                     id={"saved_amount"}
                     label={"Saved amount so far"}
                     placeholder={"Saved amount so far"}
                     type={"textInput"}
                     currencyMode={true}
                     onChange={form.handleChange}
                     error={checkSavedAmount() || (form.touched?.["saved_amount"] && Boolean(form.errors?.["saved_amount"]))}
                     helperText={(checkSavedAmount() && "Saved amount cannot be greater than the planned amount") || (form.touched?.["saved_amount"] && form.errors?.["saved_amount"])}
                     form={form}
                  />
                  <Input
                     id={"start+month"}
                     label={"When do you want to start *"}
                     placeholder={"When do you want to start *"}
                     type={"datePicker"}
                     setDateValue={(val) => {
                        form.setFieldValue("start+month", val);
                        form.setFieldValue("by_when", val);
                     }}
                     minDate={dayjs()}
                     error={form.touched?.["start+month"] && Boolean(form.errors?.["start+month"])}
                     helperText={form.touched?.["start+month"] && form.errors?.["start+month"]}
                     value={form.values?.["start+month"]}
                     form={form}
                  />
                  <BankDropdownInput
                     id={"saving_in_account"}
                     label={"Where do you want to save for this goal  *"}
                     placeholder={"Where do you want to save for this goal  *"}
                     type={"dropDown"}
                     onChange={form.handleChange}
                     error={form.touched?.["saving_in_account"] && Boolean(form.errors?.["saving_in_account"])}
                     helperText={form.touched?.["saving_in_account"] && form.errors?.["saving_in_account"]}
                     value={form.values?.["saving_in_account"]}
                     form={form}
                     setDropDownValue={(val) => {
                        form.setFieldValue("saving_in_account", val?.bank_name);
                        form.setFieldValue("bank_account_id", val?.bank_account_id);
                     }}
                  />
                  <Input
                     id={"comments"}
                     label={"Comments"}
                     placeholder={"Comments"}
                     type={"textInput"}
                     onChange={form.handleChange}
                     error={form.touched?.["comments"] && Boolean(form.errors?.["comments"])}
                     helperText={form.touched?.["comments"] && form.errors?.["comments"]}
                     value={form.values?.["comments"]}
                     form={form}
                  />
               </div>
               <InputModalsButton
                  onClose={onClose}
                  button1Title={"Cancel"}
                  button2Title={editMode ? "Update" : "Create"}
               />
            </form>
         </div>
      </InputsModal>
   );
}
