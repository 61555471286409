/** @format */

import React from "react";
import Highlighter from "react-highlight-words";
import { useSearchAndFilterStore } from "store/searchAndFilter";
import { useAppStore } from "store/useApp";

export default function Index({ value }) {
   const { searchQuery } = useAppStore((state) => state);

   return (
      <Highlighter
         highlightClassName="YourHighlightClass"
         searchWords={[searchQuery]}
         autoEscape={true}
         textToHighlight={value}
      />
   );
}
