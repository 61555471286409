import { createTheme } from '@mui/material/styles';
const font = "'Manrope', sans-serif";
export const theme = createTheme( {
    palette: {
        primary: {
            main: '#79CBB8',
        },
        secondary: {
            main: '#500472',
        },
        error: {
            main: '#D90429',
        }
    },
    typography: {
        fontFamily: font,
        button: {
            textTransform: 'none',
        },
    },

} );
