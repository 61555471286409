import React from 'react';

export default function Icon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 2V5" stroke="#898995" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16 2V5" stroke="#898995" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M21 8.5V13.63C20.11 12.92 18.98 12.5 17.75 12.5C16.52 12.5 15.37 12.93 14.47 13.66C13.26 14.61 12.5 16.1 12.5 17.75C12.5 18.73 12.78 19.67 13.26 20.45C13.63 21.06 14.11 21.59 14.68 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="#898995" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M7 11H13" stroke="#898995" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M7 16H9.62" stroke="#898995" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M23 17.75C23 18.73 22.72 19.67 22.24 20.45C21.96 20.93 21.61 21.35 21.2 21.69C20.28 22.51 19.08 23 17.75 23C16.6 23 15.54 22.63 14.68 22C14.11 21.59 13.63 21.06 13.26 20.45C12.78 19.67 12.5 18.73 12.5 17.75C12.5 16.1 13.26 14.61 14.47 13.66C15.37 12.93 16.52 12.5 17.75 12.5C18.98 12.5 20.11 12.92 21 13.63C22.22 14.59 23 16.08 23 17.75Z" stroke="#898995" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16.0322 15.0457H17.3549C17.41 15.0457 17.4835 15.0483 17.5753 15.0535C17.6698 15.0562 17.7538 15.064 17.8273 15.0771C18.1658 15.1296 18.4427 15.2412 18.6579 15.4117C18.8757 15.5823 19.0358 15.7975 19.1381 16.0573C19.2405 16.3145 19.2917 16.6019 19.2917 16.9194C19.2917 17.3944 19.1801 17.7933 18.9571 18.1161C18.734 18.4363 18.3915 18.6449 17.9296 18.742C17.8168 18.7657 17.7223 18.7801 17.6462 18.7853C17.5701 18.7906 17.473 18.7932 17.3549 18.7932H16.0322V17.7933H17.3077C17.3628 17.7933 17.4231 17.7907 17.4887 17.7855C17.5543 17.7802 17.6147 17.7697 17.6698 17.754C17.8142 17.712 17.9257 17.6451 18.0044 17.5532C18.0831 17.4614 18.1369 17.359 18.1658 17.2462C18.1973 17.1307 18.2131 17.0218 18.2131 16.9194C18.2131 16.8171 18.1973 16.7095 18.1658 16.5966C18.1369 16.4812 18.0831 16.3775 18.0044 16.2857C17.9257 16.1912 17.8142 16.1243 17.6698 16.0849C17.6147 16.0691 17.5543 16.0586 17.4887 16.0534C17.4231 16.0482 17.3628 16.0455 17.3077 16.0455H16.0322V15.0457ZM16.0322 18.7932L17.2132 18.5491L19.6459 20.7142H18.1579L16.0322 18.7932ZM16.0637 17.317V16.5218H20.0002V17.317H16.0637ZM17.2132 15.8408V15.0457H19.9687V15.8408H17.2132Z" fill="#898995" />
        </svg>

    );
}
