/** @format */

import React, { useEffect } from "react";
import Input from "@mui/material/Input";
import { InputBase } from "@mui/material";
import "./index.css";
import { useSearchAndFilterStore } from "store/searchAndFilter";
import { useAppStore } from "store/useApp";

export default function SearchInput() {
   const { searchPlaceholder, searchQuery, onSearchQueryChange } = useAppStore((state) => state);
   console.log(useAppStore().onSearchQueryChange, "SEAECHBARSTORE");
   return (
      <div>
         <InputBase
            sx={{ height: "3rem" }}
            className="search-input"
            color="secondary"
            placeholder={searchPlaceholder}
            onChange={(e) => onSearchQueryChange(e.target.value)}
         />
      </div>
   );
}
