/** @format */

import { LinearProgress } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField/TextField";
import Tooltip from "@mui/material/Tooltip";
import CurrencyFormatText from "Components/CurrencyFormatText";
import React, { useState, useEffect } from "react";
import "../index.css";
import HighlightText from "Components/HighlightText";

export default function ReminderRow({ item, index, updateReminder, reviveReminder }) {
   const [amountPaid, setamountPaid] = useState(item?.amount_paid != "" ? item?.amount_paid : item?.amount);

   useEffect(() => {
      setamountPaid(item?.amount_paid != "" ? item?.amount_paid : item?.amount);
   }, [item]);

   const checkAmountToPayisGreater = (value) => {
      if (amountPaid > item.amount) return true;
      else return false;
   };

   const isReviveEnabled = () => {
      return true;
   };

   return (
      <tr
         id="reminder-table-row"
         key={index}>
         <td id="reminder-type">{item.reminder_type}</td>
         <td id="table-cell-center-align">
            <HighlightText value={item.goal_name} />
         </td>
         <td id="table-cell-center-align">
            <CurrencyFormatText value={item.amount} />
         </td>
         <td id="table-cell-center-align">
            <div className="reminder-amount-paid-container">
               <TextField
                  inputProps={{ style: { height: "0.5rem" } }}
                  className="reminder-amount-paid"
                  InputProps={{
                     startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                  }}
                  disabled={item.mark_as_completed || item?.skip_month}
                  value={amountPaid}
                  sx={{
                     "& fieldset": { border: "none" },
                  }}
                  type="number"
                  onChange={(e) => setamountPaid(e.target.value)}
                  style={{ borderRadius: 8, border: "0px" }}
               />
            </div>
         </td>
         <td id="table-cell-center-align">
            <div className="reminder-transfer-details-row">
               <div className="reminder-transfer-bank">
                  <HighlightText value={item.transfer_from} />
               </div>
               <div>to</div>
               <div className="reminder-transfer-bank">
                  <HighlightText value={item.transfer_to} />
               </div>
            </div>
         </td>
         <td id="table-cell-center-align">
            <div className="goal-completed-container">
               <div>{item.goal_progress}%</div>
               <div>
                  <LinearProgress
                     sx={{
                        backgroundColor: "rgba(18, 183, 105, 0.1)",
                        "& .MuiLinearProgress-bar": {
                           backgroundColor: "#12B769",
                        },
                        width: "80%",
                     }}
                     color="primary"
                     variant="determinate"
                     value={item.goal_progress}
                  />
               </div>
            </div>
         </td>
         <td id="table-cell-center-align">
            <div className="reminder-action-container">
               {item.mark_as_completed || item?.skip_month ? (
                  <div
                     onClick={() => isReviveEnabled() && reviveReminder(item)}
                     className={isReviveEnabled() ? "revive" : "revive-disabled"}>
                     Revive
                  </div>
               ) : (
                  <>
                     <Tooltip title={checkAmountToPayisGreater() && "Amount to pay cannot be greater than the monthly goal amount"}>
                        <div
                           style={{ opacity: checkAmountToPayisGreater() ? 0.5 : 1 }}
                           onClick={() => !checkAmountToPayisGreater() && updateReminder(item, amountPaid, "mark_as_completed")}
                           className="mark-as-complete">
                           Mark as complete
                        </div>
                     </Tooltip>
                     <div
                        onClick={() => updateReminder(item, 0, "skip_month")}
                        className="skip_this_month">
                        Skip this month
                     </div>
                  </>
               )}
            </div>
         </td>
      </tr>
   );
}
