/** @format */

import React, { useState } from "react";
import "./index.css";
import ReminderRow from "./ReminderRow";
import GoalManageMent from "./GoalManagement";
import { useReminderStore } from "../../../store/reminders";
import NoData from "Components/NoData";
import CannotReviveModal from "Components/CannotReviveModal";
import { useAppStore } from "store/useApp";

export default function ReminderGoalsTable({ data, tabIndex }) {
   const [openGoalManagement, setopenGoalManagement] = useState(false);
   const ReminderStore = useAppStore();
   const [goalDetails, setgoalDetails] = useState({});
   const [amountPaid, setamountPaid] = useState(0);
   console.log(data, "data reminders");
   const [toUpdate, settoUpdate] = useState("");
   const [reviveWarningMessage, setreviveWarningMessage] = useState("");

   const updateReminderFromGoalManagement = (value) => {
      setopenGoalManagement(false);
      let payload = {
         ...goalDetails,
         amount_paid: amountPaid,
         mark_as_completed: toUpdate == "mark_as_completed" ? true : false,
         skip_month: toUpdate == "skip_month" ? true : false,
         update_reminder_type: value,
      };
      console.log("updateReminderFromGoalManagement", payload);
      ReminderStore.updateReminder(goalDetails?.reminder_id, payload, toUpdate)
         .then()
         .catch((err) => console.log(err));
   };

   const updateReminder = (item, amountPaid, toUpdate) => {
      settoUpdate(toUpdate);
      if (amountPaid < item?.amount) {
         setgoalDetails(item);
         fetchGoalSummary(item, amountPaid);
         setamountPaid(amountPaid);
      } else {
         ReminderStore.updateReminder(
            item?.reminder_id,
            {
               ...item,
               amount_paid: amountPaid,
               mark_as_completed: toUpdate === "mark_as_completed" ? true : false,
               skip_month: toUpdate === "skip_month" ? true : false,
               update_reminder_type: "normal",
            },
            toUpdate,
         )
            .then()
            .catch((err) => console.log(err));
      }
   };

   const fetchGoalSummary = (item, amountPaid) => {
      const payload = {
         goal_id: item?.goal_id,
         amount_paid: amountPaid,
         goal_summary_id: item?.goal_summary_id,
         amount: item?.amount,
      };
      console.log("fetchGoalSummary");
      ReminderStore.getGoalSummary(payload)
         .then((res) => setopenGoalManagement(true))
         .catch((err) => console.log(err));
   };

   const reviveReminder = (item) => {
      ReminderStore.reviveReminder(item?.reminder_id)
         .then()
         .catch((err) => {
            setreviveWarningMessage(err?.response?.data?.detail);
         });
   };

   return (
      <div className="reminder-row-container">
         <GoalManageMent
            onClose={() => setopenGoalManagement(false)}
            visible={openGoalManagement}
            onSubmit={(value) => updateReminderFromGoalManagement(value)}
            goalDetails={goalDetails}
         />
         {reviveWarningMessage !== "" && (
            <CannotReviveModal
               onClose={() => setreviveWarningMessage("")}
               message={reviveWarningMessage}
            />
         )}
         {data?.length === 0 ? (
            <NoData
               buttonText={""}
               description={"No ongoing goals found"}
               onClick={() => {}}
            />
         ) : (
            <table id="reminder-table">
               <tr id="reminder-table-header">
                  <thead id="reminder-table-header-container">
                     <th id="reminder-type-header"></th>
                     <th id="reminder-table-header">Goal name</th>
                     <th id="reminder-table-header">Monthly goal </th>
                     <th id="reminder-table-header">Amount to pay</th>
                     <th id="reminder-table-header">Transfer details</th>
                     <th id="reminder-table-header">Goal Completed</th>
                     <th id="reminder-table-header">Action</th>
                  </thead>
               </tr>
               <tbody id="reminder-table-body">
                  {data?.map((item, index) => {
                     return (
                        <ReminderRow
                           item={item}
                           index={index}
                           updateReminder={updateReminder}
                           reviveReminder={reviveReminder}
                        />
                     );
                  })}
               </tbody>
            </table>
         )}
      </div>
   );
}
