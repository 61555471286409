/** @format */

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./utils/theme";
import { BrowserRouter } from "react-router-dom";
import Feedback from "feeder-react-feedback";
import "feeder-react-feedback/dist/feeder-react-feedback.css"; // import stylesheet

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
   <BrowserRouter>
      <ThemeProvider theme={theme}>
         <App />
         <Feedback
            email={true}
            emailRequired={true}
            projectId={process.env.REACT_APP_FEEDER_PROJECT_ID}
            primaryColor="#500472"
            hoverBorderColor="#fff"
         />
         ;
      </ThemeProvider>
   </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
