/** @format */

import React from "react";
import "./index.css";
import HightlightText from "Components/HighlightText";

export default function GoalStatus({ value }) {
   const className = value === "Completed" ? "goal-status-completed" : "goal-status-ongoing";
   console.log(__dirname, "__dirname");
   return (
      <div className={className}>
         <HightlightText value={value} />
      </div>
   );
}
