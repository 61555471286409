import React, { useState } from 'react';
import Sidebar from '../Sidebar';
import Header from '../Header';
import './index.css';
import SearchInput from '../SearchInput';
import Button from '../Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ChipsSelection from '../ChipsSelection';
import { MONTHS, YEARS } from '../../data/dummyData';
import InputsModal from '../InputsModal';
import Table from '../Table';
import ChooseYearAndMonth from '../ChooseYearAndMonth';

const Item = styled( Paper )( ( { theme } ) => ( {
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing( 1 ),
    textAlign: 'center',
    color: theme.palette.text.secondary,
} ) );


export default function ScreenContainer( {
    createButtonText,
    breadCrumbsData,
    inputs,
    tableData,
    columnsData,
    hideColumn,
    inputModalTitle,
    onPressCreate,
    children,

} ) {


    const [openCreateModal, setopenCreateModal] = useState( false );

    return (
        <div className='screen-container'>
            <div className='screen-sidebar-container'>
                <Sidebar />
            </div>
            <div className='screen-top-container'>
                <Header breadCrumbsData={ breadCrumbsData } />
            </div>
            <div className='screen-content-container'>
                { children }
            </div>

        </div>
    );
}
