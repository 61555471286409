/** @format */

import Actions from "Components/Actions";
import AddActualExpense from "Components/AddActualExpenseModal";
import ChooseYearAndMonth from "Components/ChooseYearAndMonth";
import CurrencyFormatText from "Components/CurrencyFormatText";
import DeleteModal from "Components/DeleteModal";
import EditExpectedExpense from "Components/EditExpectedExpenseModal";
import HighlightText from "Components/HighlightText";
import ExpenseName from "Components/MonthsSummaryCells/ExpenseName";
import ExpenseType from "Components/MonthsSummaryCells/ExpenseType";
import GoalStatus from "Components/MonthsSummaryCells/GoalStatus";
import PlannedAmount from "Components/MonthsSummaryCells/PlannedAmount";
import NoData from "Components/NoData";
import ScreenContainer from "Components/ScreenContainer";
import SearchInput from "Components/SearchInput";
import StatisticsCard from "Components/StatisticsCard";
import TabWrapper from "Components/TabWrapper";
import Table from "Components/Table";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useMonthsSummaryStore } from "store/monthsSummary";
import { useSearchAndFilterStore } from "store/searchAndFilter";
import { changeDateFormat, checkIsDateCurrentMonth, formatMonthAndYear, getCurrentMonthAndYear } from "utils/dateFunctions";
import ConvertToGoal from "./ConvertToGoal/index";
import "./index.css";
import GoalManagement from "Components/Reminders/ReminderGoalsTable/GoalManagement";
import { useReminderStore } from "store/reminders";
import { useSearchParams } from "react-router-dom";
import { MONTHS } from "data/dummyData";
import { useAppStore } from "store/useApp";

const breadCrumbsData = [
   {
      title: "Expense details",
      link: "/",
   },
   {
      title: "Month's summary",
      link: "/months_summary",
   },
];

const goalSearchableKeys = ["goal_name", "goal_status"];
const expectedExpenseSearchableKeys = ["expense_name", "expense_type"];

export default function Income() {
   const [searchParams, setSearchParams] = useSearchParams();
   const [showInputModal, setshowInputModal] = useState(false);
   const [tabIndex, settabIndex] = useState(0);
   const MonthsSummaryStore = useAppStore((state) => state);
   const { deleteExpectedExpenseSummary } = MonthsSummaryStore;
   const { date } = useParams();
   const { setSearchData, isFilterOrSearchApplied, isResultsFounded, getTableData, searchQuery, onSearchQueryChange } = useAppStore((state) => state);
   const [selectedMonth, setselectedMonth] = useState(new Date());
   const [showConvertToGoal, setshowConvertToGoal] = useState(false);
   const [editData, seteditData] = useState(null);
   const [actualExpenseData, setactualExpenseData] = useState(null);
   const [editExpenseData, seteditExpenseData] = useState(null);
   const [deleteSummaryId, setdeleteSummaryId] = useState(null);
   const [skipGoalFlow, setskipGoalFlow] = useState(false);
   const ReminderStore = useAppStore((state) => state);

   const enableConvertToGoal = (data) => {
      console.log("enableConvertToGoal", data);
      const dateNeedsToCheck = data?.month;
      const currentMonth = new Date().getMonth();
      console.log(currentMonth, "currentMonth monthtest");
      const nextMonth = new Date().getMonth() + 1;
      const checkMonth = new Date(dateNeedsToCheck).getMonth();
      console.log(checkMonth, "checkMonth monthtest");
      const checkYear = new Date(dateNeedsToCheck).getFullYear();
      console.log(checkYear, "checkYear monthtest");
      const currentYear = new Date().getFullYear();
      console.log(currentYear, "currentyear monthtest");
      if (data?.converted_to_goal) {
         return true;
      }
      if ((checkMonth === currentMonth && checkYear === currentYear) || nextMonth === currentMonth) {
         return true;
      }
      return false;
   };

   const expectedExpensecolumnsData = [
      {
         header: "Month",
         accessorKey: "month",
         cell: (value) => changeDateFormat(value?.getValue()),
      },
      {
         header: "Expense name",
         accessorKey: "expense_name",
         cell: (value) => {
            console.log(value?.row, "Expense name");
            return (
               <ExpenseName
                  name={value?.getValue()}
                  convertedToGoal={value?.row?.original?.converted_to_goal}
               />
            );
         },
      },
      {
         header: "Expense type",
         accessorKey: "expense_type",
         cell: (value) => <ExpenseType value={value?.getValue()} />,
      },

      {
         header: "Planned amount",
         accessorKey: "planned_amount",
         cell: (value) => (
            <PlannedAmount
               amount={value?.getValue()}
               updated={value?.row?.original?.edited}
            />
         ),
      },
      {
         header: "Remaining amount",
         accessorKey: "remaining_amount",
         cell: (value) => <CurrencyFormatText value={value?.getValue()} />,
      },
      {
         header: "Action",
         accessorKey: "action",
         cell: (value) => {
            return (
               <Actions
                  showEdit={true}
                  showDelete={true}
                  showPlusIcon={checkIsDateCurrentMonth(value?.row?.original?.month)}
                  showUndoIcon={false}
                  showTickIcon={false}
                  showGoalsIcon={true}
                  disableGoalsIcon={enableConvertToGoal(value?.row?.original)}
                  onClickConvertToGoal={() => handleConvertToGoal(value?.row?.original)}
                  onClickPlusIcon={() => onClickAddActualExpense(value?.row?.original)}
                  onClickEditIcon={() => seteditExpenseData(value?.row?.original)}
                  onClickDeleteIcon={() => setdeleteSummaryId(value?.row?.original?.expected_expenses_summary_id)}
               />
            );
         },
      },
   ];

   const isActionEnabled = (data) => {
      return !data?.amount_paid > 0;
   };

   const goalsColumnsData = [
      {
         header: "Month",
         accessorKey: "month",
         cell: (value) => changeDateFormat(value?.getValue()),
      },
      {
         header: "Goal name",
         accessorKey: "goal_name",
         cell: (value) => <HighlightText value={value.getValue()} />,
      },
      {
         header: "Goal amount",
         accessorKey: "goal_amount",
         cell: (value) => (
            <PlannedAmount
               amount={value?.getValue()}
               updated={value?.getValue()?.updated}
            />
         ),
      },
      {
         header: "Amount paid",
         accessorKey: "amount_paid",
      },
      {
         header: "Status",
         accessorKey: "goal_status",
         cell: (value) => {
            return <GoalStatus value={value?.getValue()} />;
         },
      },
      {
         header: "Action",
         accessorKey: "action",
         cell: (value) => {
            return (
               <Actions
                  showEdit={true}
                  showSkipIcon={true}
                  disableSkipIcon={!isActionEnabled(value?.row?.original)}
                  disablePlusIcon={!isActionEnabled(value?.row?.original)}
                  disableEditIcon={!isActionEnabled(value?.row?.original)}
                  showPlusIcon={checkIsDateCurrentMonth(value?.row?.original?.month)}
                  onClickPlusIcon={() => onClickAddActualExpense(value?.row?.original)}
                  onClickEditIcon={() => seteditData(value?.row?.original)}
                  onClickSkipIcon={() => {
                     fetchGoalSummary(value?.row?.original, 0);
                  }}
               />
            );
         },
      },
   ];

   const handleConvertToGoal = (rowData) => {
      seteditData(rowData);
      console.log("handleConvertToGoal", rowData);
   };

   const onClickAddActualExpense = (rowData) => {
      setactualExpenseData(rowData);
   };

   const year = searchParams.get("year");
   const month = searchParams.get("month");

   useEffect(() => {
      const currentMonth = MONTHS.indexOf(month);
      const data = {
         year: parseInt(year),
         month: currentMonth + 1,
      };
      console.log(data, "MYDATA");
      fetchMonthsSummary(formatMonthAndYear(data));
   }, [year, month]);

   const fetchMonthsSummary = async (date) => {
      MonthsSummaryStore.getMonthsSumary(date).then(() => {
         setselectedMonth(new Date(date));
      });
   };

   useEffect(() => {
      updateSearchData();
   }, [tabIndex]);

   useEffect(() => {
      updateSearchData();
   }, [MonthsSummaryStore.expectedExpenses, MonthsSummaryStore.goals]);

   const getRenderTableData = () => {
      switch (tabIndex) {
         case 0:
            return {
               tableData: MonthsSummaryStore.expectedExpenses,
               columnData: expectedExpensecolumnsData,
               message: "No expected expense found",
               searchPlaceholder: "Search by expense name or type",
               searchableKeys: expectedExpenseSearchableKeys,
            };
            break;
         case 1:
            return {
               tableData: MonthsSummaryStore.goals,
               columnData: goalsColumnsData,
               message: "No goals found",
               searchPlaceholder: "Search by goal name or status",
               searchableKeys: goalSearchableKeys,
            };
         default:
            break;
      }
   };

   const updateSearchData = () => {
      const { tableData, searchableKeys, searchPlaceholder } = getRenderTableData();
      console.log(tableData, "updateSearchData");
      setSearchData(tableData, searchableKeys, searchPlaceholder, []);
      onSearchQueryChange(searchQuery);
   };

   const showTable = () => {
      if (getRenderTableData().tableData.length > 0) {
         if (isFilterOrSearchApplied()) {
            return isResultsFounded();
         } else {
            return true;
         }
      } else {
         return false;
      }
   };

   const getMonthsSummary = (val) => {
      fetchMonthsSummary(formatMonthAndYear(val));
   };

   useEffect(() => {
      if (editData !== null) {
         setshowConvertToGoal(true);
      } else {
         setshowConvertToGoal(false);
      }
   }, [editData]);

   const onDelete = () => {
      deleteExpectedExpenseSummary(deleteSummaryId).then(() => {
         setdeleteSummaryId(null);
      });
   };

   const fetchGoalSummary = (item, amountPaid) => {
      const payload = {
         goal_id: item?.goal_id,
         amount_paid: amountPaid,
         goal_summary_id: item?.goal_summary_id,
         amount: item?.goal_amount,
      };
      console.log("fetchGoalSummary");
      ReminderStore.getGoalSummary(payload)
         .then((res) => {
            seteditData(item);
            if (amountPaid == 0) {
               setskipGoalFlow(true);
            }
         })
         .catch((err) => console.log(err));
   };

   const showGoalManagement = () => {
      if (skipGoalFlow) {
         return true;
      } else if (editData !== null && tabIndex === 1) {
         return true;
      }
      return false;
   };

   const onConfirmGoalManagement = (selectedOption, enteredAmount) => {
      const payload = {
         goal_update_type: selectedOption,
         amount: enteredAmount,
         goal_id: editData.goal_id,
      };
      MonthsSummaryStore.updateGoalSummary(editData.goal_summary_id, payload).then(() => {
         fetchMonthsSummary(changeDateFormat(selectedMonth));
         seteditData(null);
         setskipGoalFlow(false);
      });
   };

   const setCurrentPageKey = () => {
      const key = tabIndex === 0 ? "expectedExpenses" : "goals";
      MonthsSummaryStore.setCurrentPageKey(key);
   };

   useEffect(() => {
      setCurrentPageKey();
   }, [tabIndex]);

   return (
      <div>
         <ScreenContainer breadCrumbsData={breadCrumbsData}>
            {showGoalManagement() && (
               <GoalManagement
                  goalDetails={editData}
                  onClose={() => {
                     seteditData(null);
                     setskipGoalFlow(false);
                  }}
                  visible={true}
                  onSubmit={onConfirmGoalManagement}
                  showAmountInput={!skipGoalFlow}
                  fetchGoalSummary={(amountPaid) => fetchGoalSummary(editData, amountPaid)}
               />
            )}
            {deleteSummaryId !== null && (
               <DeleteModal
                  visible={true}
                  onClose={() => setdeleteSummaryId(null)}
                  onDelete={onDelete}
                  deleteMessage={"Are you sure you want to delete this expected expense ?. You can’t undo this action."}
               />
            )}
            {editData !== null && tabIndex === 0 && (
               <ConvertToGoal
                  visible={true}
                  onClose={() => seteditData(null)}
                  preValues={{ ...editData, start_month: new Date(), end_month: new Date(selectedMonth) }}
               />
            )}
            {}
            {editExpenseData && (
               <EditExpectedExpense
                  data={editExpenseData}
                  onClose={() => seteditExpenseData(null)}
               />
            )}

            {actualExpenseData !== null && (
               <AddActualExpense
                  data={actualExpenseData}
                  tabIndex={tabIndex}
                  onClose={() => setactualExpenseData(null)}
               />
            )}
            <div className="months-summary-row-container">
               <ChooseYearAndMonth
                  onClickDone={getMonthsSummary}
                  type="select"
                  selectedMonth={selectedMonth}
               />
               <div className="search-container">
                  <SearchInput />
               </div>
            </div>
            <div className="statistics-cards-container">
               <StatisticsCard
                  title={"Total income"}
                  value={MonthsSummaryStore?.totalIncome}
                  color={"#FFB703"}
                  month={changeDateFormat(selectedMonth)}
               />
               <StatisticsCard
                  title={"Expected expense"}
                  value={MonthsSummaryStore?.expectedExpense}
                  color={"#FFB703"}
                  month={changeDateFormat(selectedMonth)}
               />
               <StatisticsCard
                  title={"Expenses from goals"}
                  value={MonthsSummaryStore?.expectedExpensesFromGoals}
                  color={"#FFB703"}
                  month={changeDateFormat(selectedMonth)}
               />
               <StatisticsCard
                  title={"Total expenses"}
                  value={MonthsSummaryStore?.totalExpenses}
                  color={"#FFB703"}
                  month={changeDateFormat(selectedMonth)}
               />
               <StatisticsCard
                  title={"Actual expense"}
                  value={MonthsSummaryStore?.actualExpense}
                  color={"#500472"}
                  month={changeDateFormat(selectedMonth)}
               />
               <StatisticsCard
                  title={"Expected balance"}
                  value={MonthsSummaryStore?.expectedBalance}
                  color={"#FFB703"}
                  month={changeDateFormat(selectedMonth)}
               />
               <StatisticsCard
                  title={"Actual balance"}
                  value={MonthsSummaryStore?.actualBalance}
                  color={"#12B769"}
                  month={changeDateFormat(selectedMonth)}
               />
            </div>
            <TabWrapper
               tabIndex={tabIndex}
               onChangeTab={(index) => settabIndex(index)}
               tabs={["Expected expense", "Goals"]}
            />
            {!showTable() ? (
               <NoData
                  buttonText={""}
                  message={getTableData().message}
               />
            ) : (
               <Table
                  tableData={getTableData()}
                  columnsData={getRenderTableData().columnData}
                  hideColumn={"_id"}
               />
            )}
         </ScreenContainer>
      </div>
   );
}
