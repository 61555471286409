/** @format */

import CurrencyFormatText from "Components/CurrencyFormatText";
import NoData from "Components/NoData";
import React, { useEffect, useState } from "react";
import Actions from "Components/Actions";
import ChooseYearAndMonth from "Components/ChooseYearAndMonth";
import DeleteModal from "Components/DeleteModal";
import FullWidthSearchContainer from "Components/FullWidthSearchContainer";
import Progress from "Components/GoalsCells/Progress";
import ScreenContainer from "Components/ScreenContainer";
import Table from "Components/Table";
import { useSavingsStore } from "store/savings";
import { changeDateFormat } from "utils/dateFunctions";
import InputModal from "./Inputs";
import { useAppStore } from "store/useApp";

const breadCrumbsData = [
   {
      title: "Goal & savings",
      link: "/",
   },
   {
      title: "savings",
      link: "/savings",
   },
];

export default function Income() {
   const [showInputModal, setshowInputModal] = useState(false);
   const SavingsStore = useAppStore();
   const columnsData = [
      {
         header: "saving_id",
         accessorKey: "saving_id",
      },
      {
         header: "Savings name",
         accessorKey: "saving_name",
      },
      {
         header: "Start month",
         accessorKey: "start_month",
         cell: (value) => changeDateFormat(value?.getValue()),
      },
      {
         header: "Save in",
         accessorKey: "saving_in_account",
      },
      {
         header: "Planned amount",
         accessorKey: "planned_amount",
         cell: (value) => <CurrencyFormatText value={value?.getValue()} />,
      },
      {
         header: "Progress",
         accessorKey: "saved_progress",
         cell: (value) => {
            return (
               <Progress
                  percentage={value?.getValue()?.saved_percentage}
                  amount={value?.getValue()?.saved_amount}
               />
            );
         },
      },
      {
         header: "Remaining amount",
         accessorKey: "remaining_amount",
         cell: (value) => <CurrencyFormatText value={value?.getValue()} />,
      },
      {
         header: "Action",
         accessorKey: "action",
         cell: (value) => {
            return (
               <Actions
                  showEdit={true}
                  showDelete={true}
                  showGoalsIcon={true}
                  showPlusIcon={true}
                  onClickDeleteIcon={() => handleDelete(value?.row?.original)}
                  onClickEditIcon={() => handleEdit(value?.row?.original)}
               />
            );
         },
      },
   ];
   const [editData, seteditData] = useState(null);
   const [editMode, seteditMode] = useState(false);
   const [deleteId, setdeleteId] = useState(null);
   const [showDeleteModal, setshowDeleteModal] = useState(false);

   const fetchData = async () => {
      await SavingsStore.getSavings();
   };

   useEffect(() => {
      fetchData();
   }, []);

   const handleDelete = (tableRowData) => {
      setdeleteId(tableRowData?.saving_id);
      setshowDeleteModal(true);
   };

   const handleEdit = (tableRowData) => {
      seteditData(tableRowData);
      seteditMode(true);
      setshowInputModal(true);
   };

   console.log(editData, "editData");

   return (
      <div>
         <ScreenContainer breadCrumbsData={breadCrumbsData}>
            <FullWidthSearchContainer
               createButtonText="Create new savings"
               onPressCreate={() => setshowInputModal(true)}
            />
            <DeleteModal
               visible={showDeleteModal}
               deleteMessage={"Are you sure you want to delete this saving?. You can’t undo this action."}
               onClose={() => setshowDeleteModal(false)}
               onDelete={() => {
                  SavingsStore.deleteSaving(deleteId);
                  setshowDeleteModal(false);
               }}
            />
            <InputModal
               visible={showInputModal}
               onClose={() => {
                  seteditMode(false);
                  setshowInputModal(false);
                  seteditData(null);
               }}
               editMode={editMode}
               preValues={editData}
            />
            <ChooseYearAndMonth />
            {SavingsStore?.savings?.length === 0 ? (
               <NoData
                  buttonText={"Create new savings"}
                  onClick={() => setshowInputModal(true)}
                  message={"You don't have any savings yet"}
               />
            ) : (
               <Table
                  tableData={SavingsStore?.savings}
                  columnsData={columnsData}
                  hideColumn={"saving_id"}
               />
            )}
         </ScreenContainer>
      </div>
   );
}
