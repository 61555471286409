/** @format */

import { flexRender, getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import React, { useMemo, useState } from "react";
import TableSortIcon from "../../icons/TableSortIcon";
import Pagination from "../Pagination";
import "./index.css";
import Fade from "@mui/material/Fade";

export default function Table({ tableData, hideColumn, columnsData }) {
   const [sorting, setSorting] = useState([]);
   const [columnVisibility, setColumnVisibility] = useState({});
   const columns = useMemo(() => columnsData);

   const table = useReactTable({
      data: tableData,
      columns,
      state: {
         sorting,
         columnVisibility,
         pagination: {
            pageIndex: 0,
            pageSize: 50,
         },
      },
      onColumnVisibilityChange: setColumnVisibility,
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      debugTable: true,
   });
   console.log(table.getState().pagination, "CHECK");

   return (
      <Fade in={true}>
         <div>
            <table id="main-table">
               <thead id="main-table-header-container">
                  {table.getHeaderGroups().map((headerGroup) => (
                     <tr
                        id="main-table-tr"
                        key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                           if (header?.id !== hideColumn) {
                              const columnName = header.column.columnDef.header;
                              let columnSpan = 1;
                              if (columnName === "Associated goals") {
                                 columnSpan = 3;
                              }
                              return (
                                 <th
                                    id="main-table-th"
                                    key={header.id}
                                    colSpan={columnSpan}>
                                    {header.isPlaceholder ? null : (
                                       <div
                                          style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                                          {...{
                                             className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                                             onClick: header.column.getToggleSortingHandler(),
                                          }}>
                                          {flexRender(header.column.columnDef.header, header.getContext())}
                                          {header.id !== "action" && <TableSortIcon />}
                                       </div>
                                    )}
                                 </th>
                              );
                           }
                        })}
                     </tr>
                  ))}
               </thead>
               <tbody id="main-table-body">
                  {table.getRowModel().rows.map((row) => {
                     return (
                        <tr
                           id="main-table-tr"
                           key={row.id}>
                           {row.getVisibleCells().map((cell) => {
                              if (cell.column.id !== hideColumn) {
                                 let columnSpan = 1;
                                 const columnId = cell.column.id;
                                 if (columnId === "associated_goals") {
                                    columnSpan = 3;
                                 }
                                 return (
                                    <td
                                       id="main-table-td"
                                       colSpan={columnSpan}
                                       key={cell.id}>
                                       {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                 );
                              }
                           })}
                        </tr>
                     );
                  })}
               </tbody>
            </table>
            <Pagination
               currentPage={table.getState().pagination.pageIndex + 1}
               totalPages={table.getPageCount()}
               onPressNext={() => table.getState().pagination.pageIndex + 1 < table.getPageCount() && table.nextPage()}
               onPressPrevious={() => table.previousPage()}
               setPageIndex={(pageIndex) => table.setPageIndex(pageIndex)}
               pageSize={table.getState().pagination.pageSize}
               setPageSize={(pageSize) => table.setPageSize(pageSize)}
            />
         </div>
      </Fade>
   );
}
