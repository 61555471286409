/** @format */

import React, { useState, useEffect } from "react";
import "./index.css";
import { changeDateFormat } from "utils/dateFunctions";
import CurrencyFormatText from "Components/CurrencyFormatText";

export default function InputModalTable({ viewMonths, data }) {
   const [tableData, settableData] = useState(data);

   useEffect(() => {
      if (viewMonths) {
         let result = [];
         for (const key in data) {
            if (data[key].expected_monthly_balance < 0) {
               result.push(data[key]);
            }
         }
         settableData(result);
      } else {
         settableData(data);
      }
   }, [viewMonths]);

   useEffect(() => {
      settableData(data);
   }, [data]);

   function isDecimal(num) {
      return num % 1;
   }

   return (
      <table id="ctg-table">
         <thead id="ctg-table-header-container">
            <tr id="ctg-table-tr">
               <th id="ctg-table-th">Month</th>
               <th id="ctg-table-th">Goal amount</th>
               <th id="ctg-table-th">Expected monthly balance</th>
            </tr>
         </thead>
         <tbody id="ctg-table-body">
            {tableData.map((row, index) => (
               <tr
                  id="ctg-table-tr"
                  key={index}>
                  <td id="ctg-table-td">{changeDateFormat(row.month)}</td>
                  <td id="ctg-table-td">
                     {" "}
                     <CurrencyFormatText value={isDecimal(row?.amount) ? row?.amount?.toPrecision(5) : row?.amount} />
                  </td>
                  <td
                     id="ctg-table-td"
                     style={{ color: row.expected_monthly_balance < 0 ? "#D90429" : "#12B769" }}>
                     <CurrencyFormatText value={isDecimal(row?.expected_monthly_balance) ? row?.expected_monthly_balance?.toPrecision(5) : row?.expected_monthly_balance} />
                  </td>
               </tr>
            ))}
         </tbody>
      </table>
   );
}
