/** @format */

import React from "react";
import "./index.css";
import CurrencyFormatText from "Components/CurrencyFormatText";

export default function TransferDetailsTable({ data }) {
   return (
      <div>
         <table id="transfer-details-table">
            <thead id="transfer-details-table-header-container">
               <tr id="transfer-details-table-tr">
                  <th id="transfer-details-table-th">Goals</th>
                  <th id="transfer-details-table-th">Amount</th>
               </tr>
            </thead>
            <tbody id="transfer-details-table-body">
               {data.map((row, index) => (
                  <tr
                     id="transfer-details-table-tr"
                     key={index}>
                     <td
                        style={{
                           color: "#500472",
                        }}
                        id="transfer-details-table-td">
                        {row.goal_name}
                     </td>
                     <td id="transfer-details-table-td">
                        <CurrencyFormatText value={row.amount} />{" "}
                     </td>
                  </tr>
               ))}
            </tbody>
         </table>
      </div>
   );
}
