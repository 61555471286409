/** @format */

import * as Yup from "yup";

export const Inputs = [
   {
      label: "Goal name *",
      required: true,
      key: "goal_name",
      type: "textInput",
   },
   {
      label: "Goal amount *",
      required: true,
      key: "goal_amount",
      type: "textInput",
   },
   {
      label: "Beginning balance *",
      required: true,
      key: "beginning_balance",
      type: "textInput",
   },
   {
      label: "When do you want to start *",
      required: true,
      key: "start_month",
      type: "datePicker",
   },
   {
      label: "When do you want to complete this goal  *",
      required: true,
      key: "end_month",
      type: "datePicker",
   },
   {
      label: "Where do you want to save for this goal  *",
      required: true,
      key: "saving_in_account",
      type: "dropDown",
      options: [
         {
            label: "HDFC",
            value: "hdfc",
         },
         {
            label: "Canara",
            value: "canara",
         },
         {
            label: "SBI",
            value: "sbi",
         },
         {
            label: "ICICI",
            value: "icici",
         },
      ],
   },
];

export const Payload = {
   saving_name: "",
   planned_amount: null,
   saved_amount: 0,
   start_month: new Date(),
   comments: "",
   saving_in_account: "HDFC",
};

export const PayloadSchema = {
   saving_name: Yup.string().required("This field is required"),
   saving_in_account: Yup.string().required("This field is required"),
   saved_amount: Yup.number("Please enter number").typeError("Amount must be a number").required("This field is required"),
   planned_amount: Yup.number("Please enter number").typeError("Amount must be a number").moreThan(0).required("This field is required"),
};
