import React from "react";

function Icon( { onClick } ) {
    return (
        <svg
            onClick={ onClick }
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            fill="none"
            viewBox="0 0 34 34"
            className="svg-icon"
        >
            <rect
                width="33"
                height="33"
                x="0.5"
                y="0.5"
                fill="#fff"
                stroke="#E8E8EA"
                rx="5.5"
            ></rect>
            <mask
                id="mask0_363_19051"
                style={ { maskType: "alpha" } }
                width="24"
                height="24"
                x="6"
                y="5"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M6 5H30V29H6z"></path>
            </mask>
            <g mask="url(#mask0_363_19051)">
                <path
                    fill="#14142B"
                    d="M15.927 22.127a.745.745 0 11-1.054-1.054L18.946 17l-4.073-4.073a.745.745 0 111.054-1.054l4.42 4.42a1 1 0 010 1.414l-4.42 4.42z"
                ></path>
            </g>
        </svg>
    );
}

export default Icon;