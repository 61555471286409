/** @format */

import React, { useState } from "react";
import { TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import "./index.css";

const filter = createFilterOptions();

export default function DropdownInput({ label, disableId, diableCreate, options, error, onCreate, helperText, id, value, onChange, setDropDownValue }) {
   console.log(value, "options DropdownInput");
   return (
      <Autocomplete
         value={value}
         onChange={(event, newValue) => {
            console.log(newValue, "newValue");
            if (typeof newValue === "string") {
               setDropDownValue("", newValue);
            } else if (newValue && newValue.inputValue) {
               console.log(newValue, "newValue str str");
               // Create a new value from the user input
               setDropDownValue("add", newValue.inputValue);
            } else {
               console.log(newValue, "newValue 3");
               setDropDownValue("", newValue);
            }
         }}
         getOptionDisabled={(option) => {
            if (disableId !== undefined) {
               if (option?.value?.bank_account_id === disableId) {
                  return true;
               } else {
                  return false;
               }
            }
         }}
         filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option?.label);
            if (inputValue !== "" && !isExisting && !diableCreate) {
               filtered.push({
                  inputValue,
                  label: `Create "${inputValue}"`,
               });
            }

            return filtered;
         }}
         selectOnFocus
         clearOnBlur
         disablePortal
         handleHomeEndKeys
         id={id}
         fullWidth
         className="inputRounded"
         options={options}
         getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
               return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
               return option.inputValue;
            }
            // Regular option
            return option?.label;
         }}
         renderOption={(props, option) => <li {...props}>{option.label}</li>}
         freeSolo
         renderInput={(params) => (
            <TextField
               {...params}
               value={value}
               error={error}
               helperText={helperText}
               label={label}
               fullWidth
               style={{ marginTop: 20, marginBottom: 10, borderRadius: 12 }}
               SelectDisplayProps={{ style: { borderRadius: 12 } }}
            />
         )}
      />
   );
}
