/** @format */

import CurrencyFormatText from "Components/CurrencyFormatText";
import HighlightText from "Components/HighlightText";
import NoData from "Components/NoData";
import React, { useEffect, useState } from "react";
import { useSearchAndFilterStore } from "store/searchAndFilter";
import Actions from "Components/Actions";
import ChooseYearAndMonth from "Components/ChooseYearAndMonth";
import DeleteModal from "Components/DeleteModal";
import FullWidthSearchContainer from "Components/FullWidthSearchContainer";
import Progress from "Components/GoalsCells/Progress";
import ScreenContainer from "Components/ScreenContainer";
import Table from "Components/Table";
import { useGoalsStore } from "store/goals";
import { changeDateFormat } from "utils/dateFunctions";
import InputModal from "./Inputs";
import { useAppStore } from "store/useApp";

const breadCrumbsData = [
   {
      title: "Goals",
      link: "/",
   },
   {
      title: "Goals",
      link: "/goals",
   },
];

const searchableKeys = ["goal_name", "saving_in_account", "saving_from_account"];
const dateFilters = ["start_by", "by_when"];
const searchPlaceholder = "Search by goal name or bank";

export default function Income() {
   const [showInputModal, setshowInputModal] = useState(false);
   const GoalsStore = useAppStore();
   const { setSearchData, isFilterOrSearchApplied, isResultsFounded, getTableData } = useAppStore((state) => state);

   const columnsData = [
      {
         header: "goal_id",
         accessorKey: "goal_id",
      },
      {
         header: "Goal name",
         accessorKey: "goal_name",
         cell: (value) => <HighlightText value={value?.getValue()} />,
      },
      {
         header: "Start month",
         accessorKey: "start_by",
         cell: (value) => changeDateFormat(value?.getValue()),
      },
      {
         header: "End month",
         accessorKey: "by_when",
         cell: (value) => changeDateFormat(value?.getValue()),
      },
      {
         header: "Save goal in",
         cell: (value) => <HighlightText value={value?.getValue()} />,
         accessorKey: "saving_in_account",
      },
      {
         header: "Planned amount",
         accessorKey: "amount",
         cell: (value) => <CurrencyFormatText value={value?.getValue()} />,
      },
      {
         header: "Progress",
         accessorKey: "goal_progress",
         cell: (value) => {
            return (
               <Progress
                  percentage={value?.getValue()?.saved_percentage}
                  amount={value?.getValue()?.saved_amount}
               />
            );
         },
      },
      {
         header: "Remaining amount",
         accessorKey: "remaining_amount",
         cell: (value) => <CurrencyFormatText value={value?.getValue()} />,
      },
      {
         header: "Action",
         accessorKey: "action",
         cell: (value) => {
            return (
               <Actions
                  showEdit={true}
                  showDelete={true}
                  showPlusIcon={true}
                  onClickDeleteIcon={() => handleDelete(value?.row?.original)}
                  onClickEditIcon={() => handleEdit(value?.row?.original)}
               />
            );
         },
      },
   ];
   const [editData, seteditData] = useState(null);
   const [editMode, seteditMode] = useState(false);
   const [deleteId, setdeleteId] = useState(null);
   const [showDeleteModal, setshowDeleteModal] = useState(false);

   const fetchData = async () => {
      GoalsStore.getGoals()
         .then((res) => {
            setSearchData(res, searchableKeys, searchPlaceholder, dateFilters);
         })
         .catch((err) => console.log(err));
   };

   useEffect(() => {
      GoalsStore.onChangeFilter();
   }, [GoalsStore?.goals]);

   useEffect(() => {
      GoalsStore.setCurrentPageKey("goals");
      fetchData();
   }, []);

   const handleDelete = (tableRowData) => {
      setdeleteId(tableRowData?.goal_id);
      setshowDeleteModal(true);
   };

   const handleEdit = (tableRowData) => {
      seteditData(tableRowData);
      seteditMode(true);
      setshowInputModal(true);
   };

   const DeleteGoal = () => {
      GoalsStore.deleteGoal(deleteId)
         .then(() => {
            setshowDeleteModal(false);
         })
         .catch((err) => console.log(err));
   };

   const showTable = () => {
      if (GoalsStore?.goals.length > 0) {
         if (isFilterOrSearchApplied()) {
            return isResultsFounded();
         } else {
            return true;
         }
      } else {
         return false;
      }
   };

   return (
      <div>
         <ScreenContainer breadCrumbsData={breadCrumbsData}>
            <FullWidthSearchContainer
               createButtonText="Create new goal"
               onPressCreate={() => setshowInputModal(true)}
            />
            <DeleteModal
               visible={showDeleteModal}
               deleteMessage={"This is an irreversible, are you sure you want to delete this goal?"}
               onClose={() => setshowDeleteModal(false)}
               onDelete={DeleteGoal}
            />
            <InputModal
               visible={showInputModal}
               onClose={() => {
                  seteditMode(false);
                  setshowInputModal(false);
                  seteditData(null);
               }}
               editMode={editMode}
               preValues={editData}
            />
            <ChooseYearAndMonth />
            {!showTable() ? (
               <NoData
                  message={"No goals found"}
                  buttonText={"Create new goal"}
                  onClickButton={() => setshowInputModal(true)}
               />
            ) : (
               <Table
                  tableData={getTableData()}
                  columnsData={columnsData}
                  hideColumn={"goal_id"}
               />
            )}
         </ScreenContainer>
      </div>
   );
}
