/** @format */

import React, { useState } from "react";
import "./index.css";
import Chips from "../Chips";
import { Grid } from "@mui/material";

export default function ChipsSelection({ title, data, onSelect, selectedValues }) {
   return (
      <div className="chips-selection-container">
         <div className="chips-selection-title">{title}</div>
         <Grid
            container
            columnSpacing={0.7}>
            {data?.map((item) => (
               <Grid item>
                  <Chips
                     onClick={() => onSelect(item)}
                     title={item}
                     isSelected={selectedValues?.includes(item)}
                  />
               </Grid>
            ))}
         </Grid>
      </div>
   );
}
