/** @format */

import CurrencyFormatText from "Components/CurrencyFormatText";
import HighlightText from "Components/HighlightText";
import NoData from "Components/NoData";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSearchAndFilterStore } from "store/searchAndFilter";
import { formatString } from "utils/stringFunction";
import Actions from "Components/Actions";
import ChooseYearAndMonth from "Components/ChooseYearAndMonth";
import DeleteModal from "Components/DeleteModal";
import FullWidthSearchContainer from "Components/FullWidthSearchContainer";
import ScreenContainer from "Components/ScreenContainer";
import Table from "Components/Table";
import { useActualExpensesStore } from "store/actualExpenses";
import { changeDateFormat } from "utils/dateFunctions";
import InputModal from "./Inputs";
import { useLocation } from "react-router-dom";
import { useAppStore } from "store/useApp";

const searchableKeys = ["expected_expense_reason", "reason", "comments"];
const dateFilters = ["expense_date", "expense_date"];
const searchPlaceholder = "Search by comments or expense against or reason";

const breadCrumbsData = [
   {
      title: "Expense details",
      link: "/",
   },
   {
      title: "Actual expenses",
      link: "/actual_expense",
   },
];

export default function Income() {
   const [showInputModal, setshowInputModal] = useState(false);
   const ActualExpensesStore = useAppStore();
   const { setSearchData, isFilterOrSearchApplied, isResultsFounded, getTableData } = useAppStore((state) => state);
   const location = useLocation();

   const columnsData = [
      {
         header: "Expense date",
         accessorKey: "expense_date",
         cell: (value) => changeDateFormat(value?.getValue()),
      },
      {
         header: "Expensed against",
         accessorKey: "expected_expense_reason",
         cell: (value) => <HighlightText value={formatString(value?.getValue())} />,
      },
      {
         header: "Expensed amount",
         accessorKey: "expensed_amount",
         cell: (value) => <CurrencyFormatText value={value?.getValue()} />,
      },
      // {
      //    header: "Tag",
      //    accessorKey: "tag",
      // },
      {
         header: "Comments",
         accessorKey: "comments",
         cell: (value) => <HighlightText value={value?.getValue()} />,
      },
      {
         header: "Reason",
         accessorKey: "reason",
         cell: (value) => <HighlightText value={value?.getValue()} />,
      },
      {
         header: "Action",
         accessorKey: "action",
         cell: (value) => {
            return (
               <Actions
                  showEdit={true}
                  showDelete={true}
                  onClickDeleteIcon={() => handleDelete(value?.row?.original)}
                  onClickEditIcon={() => handleEdit(value?.row?.original)}
               />
            );
         },
      },
   ];

   const [editData, seteditData] = useState(null);
   const [editMode, seteditMode] = useState(false);
   const [deleteId, setdeleteId] = useState(null);
   const [showDeleteModal, setshowDeleteModal] = useState(false);

   const handleDelete = (tableRowData) => {
      setdeleteId(tableRowData?.actual_expense_id);
      setshowDeleteModal(true);
   };

   const handleEdit = (tableRowData) => {
      getExpectedExpenseForCurrentMonth();
      seteditData(tableRowData);
      seteditMode(true);
      setshowInputModal(true);
   };

   const fetchData = async () => {
      ActualExpensesStore.getActualExpenses()
         .then((res) => {
            setSearchData(res, searchableKeys, searchPlaceholder, dateFilters);
         })
         .catch((err) => console.log(err.message));
   };

   const getExpectedExpenseForCurrentMonth = async (month) => {
      const checkMonth = dayjs(month).format("YYYY-MM-DD");
      ActualExpensesStore.resetExpenseTypes();
      ActualExpensesStore.getExpectedExpenseForMonth(checkMonth)
         .then()
         .catch((err) => console.log(err.message));
      ActualExpensesStore.getGoalsForMonth(checkMonth)
         .then()
         .catch((err) => console.log(err.message));
   };

   const DeleteActualExpense = (payload) => {
      ActualExpensesStore.deleteActualExpense(deleteId)
         .then((res) => {
            setshowDeleteModal(false);
         })
         .catch((err) => console.log(err.message));
   };

   useEffect(() => {
      ActualExpensesStore.onChangeFilter();
   }, [ActualExpensesStore?.actualExpenses]);

   useEffect(() => {
      ActualExpensesStore.setCurrentPageKey("actualExpenses");
      fetchData();
   }, []);

   const isAddExpenseRoute = () => {
      const { pathname } = location;
      return pathname === "/actual_expenses/add";
   };

   const visibleByDefault = () => {
      if (isAddExpenseRoute()) {
         return true;
      } else {
         return showInputModal;
      }
   };

   const showTable = () => {
      if (ActualExpensesStore.actualExpenses.length > 0) {
         if (isFilterOrSearchApplied()) {
            return isResultsFounded();
         } else {
            return true;
         }
      } else {
         return false;
      }
   };

   useEffect(() => {
      getExpectedExpenseForCurrentMonth();
   }, [showInputModal]);

   return (
      <div>
         <ScreenContainer breadCrumbsData={breadCrumbsData}>
            <DeleteModal
               visible={showDeleteModal}
               deleteMessage={"Are you sure you want to delete this actual expense?. You can’t undo this action."}
               onClose={() => setshowDeleteModal(false)}
               onDelete={DeleteActualExpense}
            />
            <InputModal
               visible={visibleByDefault()}
               onClose={() => {
                  seteditMode(false);
                  !isAddExpenseRoute() && setshowInputModal(false);
               }}
               editMode={editMode}
               preValues={editData}
            />

            <FullWidthSearchContainer
               createButtonText="Create actual expense"
               onPressCreate={() => {
                  setshowInputModal(true);
               }}
            />
            <ChooseYearAndMonth />

            {!showTable() ? (
               <NoData
                  buttonText={"Create actual expense"}
                  onClickButton={() => setshowInputModal(true)}
                  message={"You don't have any actual expenses yet"}
               />
            ) : (
               <Table
                  tableData={getTableData()}
                  columnsData={columnsData}
                  hideColumn={"_id"}
               />
            )}
         </ScreenContainer>
      </div>
   );
}
