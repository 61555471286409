/** @format */

import { create } from "zustand";
import { createRequest, deleteRequest, updateRequest, readRequest } from "APIs/crud";

const API_ENDPOINT = "/savings";

export const useSavingsStore = (set, get) => ({
   savings: [],
   savingsSummary: [],
   addSaving: async (data) => {
      const result = await createRequest(API_ENDPOINT, data);
      if (result.status === 200 || result.status === 201) {
         set({ savings: [...get().savings, result?.data] });
      }
   },
   getSavings: async () => {
      const result = await readRequest(API_ENDPOINT);
      if (result.status === 200 || result.status === 201) {
         set({ savings: result?.data?.response });
      }
   },
   getSavingsSummary: async () => {
      const result = await readRequest(`${API_ENDPOINT}/summary/`);
      if (result.status === 200 || result.status === 201) {
         set({ incomeSummary: result?.data?.response });
      }
   },
   deleteSaving: async (id) => {
      const result = await deleteRequest(`${API_ENDPOINT}/${id}`);
      if (result.status === 200 || result.status === 201) {
         set((state) => ({ savings: state.savings.filter((item) => item.saving_id !== id) }));
      }
   },
   updateSaving: async (data) => {
      const result = await updateRequest(`${API_ENDPOINT}/${data.saving_id}`, data);
      if (result.status === 200 || result.status === 201) {
         set((state) => ({ savings: state.savings.map((item) => (item.saving_id === data.saving_id ? result?.data : item)) }));
      }
   },
});
