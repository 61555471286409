/** @format */

import React, { useState } from "react";
import InputsModal from "Components/InputsModal";
import HighlightsInputsContainer from "Components/HighlightedInputsContainer";
import "./index.css";
import ProgressCard from "./ProgressCard";
import { useAccountSummaryStore } from "store/accountSummay";
import CurrencyFormatText from "Components/CurrencyFormatText";
import { useAppStore } from "store/useApp";

export default function AccountDetails({ visible, onClose, viewMoreIndex }) {
   const AccountSummaryData = useAppStore((state) => state.accountSummary[viewMoreIndex]);
   const [hideCompletedGoals, sethideCompletedGoals] = useState(false);
   console.log(AccountSummaryData, "AccountSummaryData");

   return (
      <InputsModal
         visible={visible}
         inputModalTitle={"Account details"}
         onClose={onClose}>
         <HighlightsInputsContainer>
            <div className="transfer-details-bank-row">
               <div className="bank">Account name</div>
               <div className="bank">:</div>
               <div
                  style={{ color: "#500472" }}
                  className="bank">
                  {AccountSummaryData?.bank_name}
               </div>
            </div>
            <div className="transfer-amount">
               {" "}
               <CurrencyFormatText value={AccountSummaryData?.current_balance} />
            </div>
         </HighlightsInputsContainer>
         <div
            onClick={() => sethideCompletedGoals(!hideCompletedGoals)}
            className="hide-completed-goals-text">
            {hideCompletedGoals ? "Show" : "Hide"} completed goals
         </div>
         <div className="progress-cards-container">
            {AccountSummaryData?.associated_goals?.map((item, index) => {
               if (hideCompletedGoals && item?.saved_percentage === 100) return null;
               return (
                  <ProgressCard
                     key={index}
                     goal_name={item?.goal_name}
                     ends_in={item?.months_left}
                     saved_so_far={item?.saved_amount}
                     remaining={item?.amount - item?.saved_amount}
                     progress={item?.saved_percentage}
                  />
               );
            })}
         </div>
      </InputsModal>
   );
}
