import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="none"
            viewBox="0 0 12 12"
        >
            <circle
                cx="5.5"
                cy="6"
                r="4.125"
                stroke="#12B769"
                strokeWidth="0.75"
            ></circle>
            <circle
                cx="5.5"
                cy="6"
                r="1.425"
                stroke="#12B769"
                strokeWidth="0.75"
            ></circle>
            <circle
                cx="5.5"
                cy="6"
                r="2.775"
                stroke="#12B769"
                strokeWidth="0.75"
            ></circle>
            <mask
                id="path-4-outside-1_492_16965"
                width="9"
                height="7"
                x="2.5"
                y="0.5"
                fill="#000"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#fff" d="M2.5 0.5H11.5V7.5H2.5z"></path>
                <path
                    fillRule="evenodd"
                    d="M10.609 2.265A.443.443 0 1010 1.621l-4.455 4.21-1.287-1.295a.444.444 0 00-.629.626l1.598 1.607a.444.444 0 00.678-.059l4.704-4.445z"
                    clipRule="evenodd"
                ></path>
            </mask>
            <path
                fill="#12B769"
                fillRule="evenodd"
                d="M10.609 2.265A.443.443 0 1010 1.621l-4.455 4.21-1.287-1.295a.444.444 0 00-.629.626l1.598 1.607a.444.444 0 00.678-.059l4.704-4.445z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                d="M10.626 1.639l.215-.203-.215.203zm-.017.626l.203.215-.203-.215zM10 1.621l.203.215L10 1.621zm-4.455 4.21l-.21.208.204.205.21-.198-.204-.215zM4.258 4.536l-.21.209.21-.209zm-.627-.002l-.209-.21.209.21zm-.002.628l.21-.209-.21.209zm1.598 1.607l-.21.209.21-.209zm.627.002l.209.21-.209-.21zm.051-.06l-.203-.216-.022.02-.017.026.242.17zm4.506-4.87a.147.147 0 01-.005.21l.406.43a.739.739 0 00.03-1.045l-.43.406zm-.208-.005a.147.147 0 01.209.006l.43-.406a.739.739 0 00-1.045-.03l.406.43zm-4.455 4.21l4.455-4.21-.406-.43-4.455 4.21.406.43zm-1.7-1.301l1.288 1.294.42-.416-1.288-1.295-.42.417zm-.209-.001a.148.148 0 01.21 0l.419-.416a.74.74 0 00-1.046-.003l.417.419zm0 .21a.148.148 0 010-.21l-.417-.42A.74.74 0 003.42 5.37l.42-.417zM5.437 6.56L3.839 4.953l-.42.417 1.598 1.608.42-.417zm.209 0a.148.148 0 01-.21 0l-.419.417a.74.74 0 001.046.003l-.417-.42zm.017-.02a.15.15 0 01-.017.02l.417.42a.742.742 0 00.085-.101l-.485-.34zm4.743-4.49L5.702 6.494l.407.43 4.703-4.445-.406-.43z"
                mask="url(#path-4-outside-1_492_16965)"
            ></path>
        </svg>
    );
}

export default Icon;