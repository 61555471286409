/** @format */

import React from "react";
import "./index.css";
import { Fade } from "@mui/material";

export default function Index({ visible }) {
   return (
      <Fade in={visible}>
         <div className="loader-container">
            <div class="loader"></div>
         </div>
      </Fade>
   );
}
