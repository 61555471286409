/** @format */

import React from "react";
import CenterModal from "Components/CenterModal";
import TextInput from "Components/Input";
import InputModalButtons from "Components/InputModalButtons";
import { changeDateFormat, formatMonthAndYear } from "utils/dateFunctions";
import * as Yup from "yup";
import { useFormik } from "formik";
import dayjs from "dayjs";
import { useActualExpensesStore } from "store/actualExpenses";
import { useAppStore } from "store/useApp";

export const PayloadSchema = {
   expensed_amount: Yup.number("Please enter number").typeError("Amount must be a number").moreThan(0).required("This field is required"),
};

const inputs = [
   {
      label: "Expensed amount",
      id: "expensed_amount",
   },
   {
      label: "Reason (optional)",
      id: "reason",
   },
   {
      label: "Comments (optional)",
      id: "comments",
   },
];

export default function Index({ data, onClose, tabIndex }) {
   const validationSchema = Yup.object().shape(PayloadSchema);
   const { addActualExpense } = useAppStore((state) => state);

   const checkIsGoalTab = () => tabIndex === 1;
   const onSubmit = (values) => {
      let payload = {};
      if (!checkIsGoalTab) {
         const { month, expected_expenses_summary_id, expected_expenses_id } = data;
         payload = {
            ...values,
            expense_date: dayjs(month).format("YYYY-MM-DD") || "",
            expected_expenses_summary_id,
            expected_expenses_id,
            expensed_against: "expected_expense",
         };
      } else {
         const { month, goal_id, goal_summary_id } = data;
         payload = {
            ...values,
            expense_date: dayjs(month).format("YYYY-MM-DD") || "",
            goal_summary_id,
            goal_id,
            expensed_against: "goal",
         };
      }
      addActualExpense(payload)
         .then((res) => {
            onClose();
         })
         .catch();
   };

   const inputValues = {
      expensed_amount: "",
      comments: "",
      reason: "",
   };

   const form = useFormik({
      initialValues: inputValues,
      validationSchema: validationSchema,
      onSubmit: onSubmit,
      enableReinitialize: true,
   });

   return (
      <CenterModal
         visible={true}
         onClose={onClose}>
         <div className="w-full">
            <div className="text-[#500472] font-bold text-[1.5rem]">Add actual expense</div>
            <div className="flex flex-col gap-2 mt-5 ">
               <div>
                  You are adding actual expense against <span className="font-semibold">{checkIsGoalTab() ? data?.goal_name : data?.expense_name}</span>
                  {"  "}on <span className="font-semibold">{changeDateFormat(data.month)}</span>
               </div>
            </div>
            <form onSubmit={form.handleSubmit}>
               {inputs.map((item, index) => {
                  return (
                     <TextInput
                        type={"textInput"}
                        label={item.label}
                        id={item.id}
                        error={form.errors[item.id]}
                        touched={form.touched[item.id]}
                        value={form.values[item.id]}
                        onChange={form.handleChange}
                        key={index}
                        helperText={form.errors[item.id] && form.errors[item.id]}
                     />
                  );
               })}
               <div className="bg-[red] mt-[4rem]">
                  <InputModalButtons
                     button1Title={"Cancel"}
                     onClose={onClose}
                     button2Title={"Add actual expense"}
                  />
               </div>
            </form>
         </div>
      </CenterModal>
   );
}
