import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
        >
            <mask
                id="mask0_352_12068"
                style={ { maskType: "alpha" } }
                width="20"
                height="20"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H20V20H0z"></path>
            </mask>
            <g mask="url(#mask0_352_12068)">
                <mask id="path-2-inside-1_352_12068" fill="#fff">
                    <path d="M4.8 8.334l5.2-5.2 5.2 5.2H4.8zm0 3.333h10.4l-5.2 5.2-5.2-5.2zm2.396.994L10 15.465l2.805-2.804h-5.61z"></path>
                </mask>
                <path
                    fill="#500472"
                    d="M4.8 8.334l5.2-5.2 5.2 5.2H4.8zm0 3.333h10.4l-5.2 5.2-5.2-5.2zm2.396.994L10 15.465l2.805-2.804h-5.61z"
                ></path>
                <path
                    fill="#500472"
                    d="M4.8 8.334L-87.713-84.18-311.06 139.167H4.8V8.334zm5.2-5.2l92.513-92.514L10-181.893-82.513-89.38 10 3.133zm5.2 5.2v130.833h315.86L107.713-84.18 15.2 8.334zM4.8 11.667v-130.833h-315.86L-87.713 104.18 4.8 11.667zm10.4 0l92.513 92.513L331.06-119.166H15.2V11.667zm-5.2 5.2l-92.513 92.513L10 201.893l92.513-92.513L10 16.867zM7.196 12.66v-130.833h-315.858l223.344 223.346L7.196 12.66zM10 15.466l-92.513 92.513L10 200.492l92.514-92.514L10 15.465zm2.805-2.804l92.513 92.512 223.344-223.346H12.805V12.661zm84.508 88.186l5.2-5.2L-82.513-89.38l-5.2 5.2L97.313 100.847zm-179.826-5.2l5.2 5.2L107.713-84.18l-5.2-5.2L-82.513 95.646zM15.2-122.5H4.8v261.667h10.4V-122.5zm-10.4 265h10.4v-261.666H4.8V142.5zM-77.313-80.846l-5.2 5.2L102.513 109.38l5.2-5.2L-77.313-80.846zm179.826 5.2l-5.2-5.2L-87.713 104.18l5.2 5.2L102.513-75.646zm-187.83 180.819l2.804 2.805L102.514-77.048l-2.805-2.804-185.027 185.025zm187.831 2.805l2.804-2.805L-79.709-79.852l-2.804 2.804 185.027 185.026zm-89.71-226.151H7.197v261.667h5.609v-261.667z"
                    mask="url(#path-2-inside-1_352_12068)"
                ></path>
            </g>
        </svg>
    );
}

export default Icon;