/** @format */

import { createRequest, readRequest, updateRequest } from "APIs/crud";
import { create } from "zustand";
import { toast } from "react-hot-toast";

const API_ENDPOINT = "/reminders";

export const useReminderStore = (set, get) => ({
   ongoingGoals: [],
   completedGoals: [],
   skippedGoals: [],
   impactSummaryTable: {
      increase_monthly_amount: [],
      extend_deadline: [],
   },
   transferSummary: [],
   getAllReminders: async (date) => {
      return new Promise((resolve, reject) => {
         readRequest(`${API_ENDPOINT}/${date}/`)
            .then((result) => {
               set({ ongoingGoals: result?.data?.response?.ongoing_goals, completedGoals: result?.data?.response?.completed_goals, skippedGoals: result?.data?.response?.skipped_goals });
               resolve(result);
            })
            .catch((err) => reject(err));
      });
   },
   updateReminder: async (id, payload, toUpdate) => {
      return new Promise((resolve, reject) => {
         updateRequest(`${API_ENDPOINT}/${id}/`, payload)
            .then((result) => {
               console.log(result, "updateReminder");
               if (toUpdate === "mark_as_completed") {
                  toast.success("Reminder completed successfully");
                  set((state) => ({ ongoingGoals: state.ongoingGoals.filter((item) => item.reminder_id !== id), completedGoals: [...state.completedGoals, result?.data?.response] }));
               } else if (toUpdate === "skip_month") {
                  toast.success("Reminder skipped");
                  set((state) => ({ ongoingGoals: state.ongoingGoals.filter((item) => item.reminder_id !== id), skippedGoals: [...state.skippedGoals, result?.data?.response] }));
               }
               resolve(result);
            })
            .catch((err) => reject(err));
      });
   },
   getGoalSummary: async (payload) => {
      return new Promise((resolve, reject) => {
         createRequest(`${API_ENDPOINT}/goal_summary/`, payload)
            .then((result) => {
               set({ impactSummaryTable: result?.data?.response });
               resolve(result);
            })
            .catch((err) => reject(err));
      });
   },
   reviveReminder: async (id) => {
      return new Promise((resolve, reject) => {
         createRequest(`${API_ENDPOINT}/revive/${id}/`)
            .then((result) => {
               set((state) => ({
                  skippedGoals: state.skippedGoals.filter((item) => item.reminder_id !== id),
                  completedGoals: state.completedGoals.filter((item) => item.reminder_id !== id),
                  ongoingGoals: [...state.ongoingGoals, result?.data],
               }));
               resolve(result);
            })
            .catch((err) => {
               console.log("REVIVE", err);
               reject(err);
            });
      });
   },
   completeAllGoals: async (goal_summary_details, index) => {
      return new Promise((resolve, reject) => {
         const payload = {
            goal_summary_details,
         };
         createRequest(`${API_ENDPOINT}/complete_all_goals/`, payload)
            .then((result) => {
               toast.success("Completed all goals");
               const existing = [...get().transferSummary];
               existing.splice(index, 1);
               set({ transferSummary: existing });
               resolve(result);
            })
            .catch((err) => reject(err));
      });
   },
   getTransferSummary: (date) => {
      return new Promise((resolve, reject) => {
         readRequest(`${API_ENDPOINT}/get_all_transfers/${date}`)
            .then((result) => {
               set({ transferSummary: result.data });
               resolve();
            })
            .catch((err) => {
               console.log(err, "getTransferSummary");
               reject(err);
            });
      });
   },
   resetSummaryTable: () => {
      set({
         impactSummaryTable: {
            increase_monthly_amount: [],
            extend_deadline: [],
         },
      });
   },
});
