/** @format */

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const checkIsDate = (date) => {
   return date instanceof Date && !isNaN(date);
};

export const changeDateFormat = (date) => {
   const newDate = new Date(date);
   const month = months[newDate.getMonth()];
   const year = newDate.getFullYear();
   return `${month} ${year}`;
};

export const getCurrentMonthAndYear = () => {
   const currentMonth = new Date().getMonth() + 1;
   const currentYear = new Date().getFullYear();
   return `${currentYear}-${currentMonth.toString().padStart(2, "0")}`;
};

export const formatYearAndMonth = (date) => {
   const d = new Date(date);
   return `${d?.getFullYear()}-${(d?.getMonth() + 1).toString().padStart(2, "0")}-${d?.getDate().toString().padStart(2, "0")}`;
};

export const formatMonthAndYear = (data) => {
   return `${data?.year}-${data?.month.toString().padStart(2, "0")}`;
};

export const isDateInRange = (date, selectedYears, selectedMonths) => {
   const year = date.getFullYear().toString();
   const month = months[date.getMonth()];
   console.log("isDateInRange", year, month, selectedYears.includes(year) || selectedMonths.includes(month));
   if (selectedYears.length > 0 && selectedMonths.length > 0) {
      return selectedYears.includes(year) && selectedMonths.includes(month);
   } else if (selectedYears.length > 0) {
      return selectedYears.includes(year);
   } else {
      return selectedMonths.includes(month);
   }
};

export const checkIsDateCurrentMonth = (date) => {
   const currentMonth = new Date().getMonth();
   const incomingMonth = new Date(date).getMonth();
   if (currentMonth == incomingMonth) {
      return true;
   } else {
      return false;
   }
};

export const getDateAfterGivenMonths = (date, givenMonth) => {
   const existingDate = new Date(date);
   const newDate = new Date(existingDate.setMonth(existingDate.getMonth() + givenMonth));
   console.log("getDateAfterGivenMonths", newDate);
   return newDate;
};
