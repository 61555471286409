/** @format */

export function formatString(str) {
   const words = str?.split("_").map((word) => word.charAt(0).toUpperCase() + word.slice(1));
   return words?.join(" ");
}

export const generateNumbers = () => Math.floor(Math.random() * 1000);

export const generateAmount = () => Math.floor(Math.random() * 100) * 1000;
