/** @format */

import React from "react";
import Tooltip from "../Components/Tooltip";

export default function Icon() {
   return (
      <Tooltip
         title={"Complete"}
         toolTipBackgroundColor={"#12B769"}
         toolTipFontColor={"#ffffff"}
         placement={"top"}>
         <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="24"
            fill="none"
            viewBox="0 0 24 24">
            <mask
               id="mask0_492_17203"
               style={{ maskType: "alpha" }}
               width="24"
               height="24"
               x="0"
               y="0"
               maskUnits="userSpaceOnUse">
               <path
                  fill="#D9D9D9"
                  d="M0 0H24V24H0z"></path>
            </mask>
            <g mask="url(#mask0_492_17203)">
               <path
                  fill="#12B769"
                  stroke="#12B769"
                  d="M9.864 17.34a.444.444 0 01-.627 0L4.75 12.854a.756.756 0 111.07-1.07l3.48 3.48a.355.355 0 00.501 0l8.38-8.38a.756.756 0 111.07 1.07L9.863 17.34z"></path>
            </g>
         </svg>
      </Tooltip>
   );
}
