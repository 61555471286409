/** @format */

import React from "react";
import CenterModal from "../CenterModal";
import RoundCloseIcon from "../../icons/RoundCloseIcon";
import "./index.css";
import InlineAlert from "../InlineAlert";
import Button from "../Button";
import DeleteIcon from "@mui/icons-material/Delete";

export default function DeleteModal({ visible, deleteMessage, onDelete, onClose, warningMessage }) {
   console.log(warningMessage, "warningMessage");
   return (
      <CenterModal
         onClose={onClose}
         visible={visible}>
         <div className="delete-modal-header-container">
            <div></div>
            <div className="delete-modal-header-text">Confirm delete</div>
            <div onClick={onClose}>
               <RoundCloseIcon />
            </div>
         </div>
         <div className="delete-modal-delete-message">{deleteMessage}</div>
         {warningMessage !== undefined && (
            <div className="delete-modal-alert-container">
               <InlineAlert
                  background={"rgba(255, 183, 3, 0.2)"}
                  mode={"warning"}
                  text={warningMessage}
                  borderColor={"#FFB703"}
               />
            </div>
         )}
         <div className="delete-modal-button-container">
            <Button
               onClick={onClose}
               title={"Cancel"}
               backgroundColor={"#E8E8EA"}
               color={"#14142B"}
               hoverColor={"#E8E8EA"}
               hoverBackgroundColor={"#14142B"}
            />
            <Button
               onClick={onDelete}
               title={"Delete"}
               backgroundColor={"#D90429"}
               color={"#FFFFFF"}
               startIcon={<DeleteIcon />}
               hoverBackgroundColor={"#D90429"}
               hoverColor={"#FFFFFF"}
            />
         </div>
      </CenterModal>
   );
}
