import React from "react";
import "./index.css";
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';

function Icon( { borderColor, background, children, text, mode } ) {

    return (
        <div style={ { borderColor: borderColor, background: background } } className="inline-alert-container">
            <div className="info-container">
                { mode === 'info' && <InfoIcon sx={ { color: borderColor } } /> }
                { mode === 'warning' && <WarningIcon sx={ { color: borderColor } } /> }
                <div style={ { color: '#000000' } }>{ text }</div>
            </div>
            { children }
        </div>
    );
}

export default Icon;