/** @format */

import React from "react";
import CurrencyFormatText from "Components/CurrencyFormatText";

export default function index({ value }) {
   return (
      <div style={{ color: value > 0 ? "#12B769" : "red" }}>
         <CurrencyFormatText value={value} />
      </div>
   );
}
