/** @format */

import * as Yup from "yup";

export const Inputs = [
   {
      label: "Expense date *",
      required: true,
      key: "expense_date",
      type: "datePicker",
   },
   {
      label: "Reason *",
      required: true,
      key: "reason",
      type: "textInput",
   },
   {
      label: "Expensed amount *",
      required: true,
      key: "expensed_amount",
      type: "textInput",
   },
   {
      customComponent: true,
   },
   {
      label: "Comments",
      required: true,
      key: "comments",
      type: "textInput",
   },
];

export const Payload = {
   expense_date: new Date(),
   reason: "",
   expensed_amount: null,
   unexpected_expense: false,
   expected_expense: "groceries",
   comments: "",
   expensed_against: "",
   tag: "",
};

export const PayloadSchema = {
   expense_date: Yup.string().required("This field is required"),
   reason: Yup.string().required("This field is required"),
   expensed_amount: Yup.number("Please enter number").typeError("Amount must be a number").moreThan(0).required("This field is required"),
};
