/** @format */

import { GoogleOAuthProvider } from "@react-oauth/google";
import "App.css";
import { useEffect } from "react";
import Loader from "Components/Loader";
import AccountSummary from "Pages/AccountSummary";
import ActualExpenses from "Pages/ActualExpense";
import ExpectedExpenses from "Pages/ExpectedExpense";
import Goals from "Pages/Goals";
import Income from "Pages/Income";
import IncomeSymmary from "Pages/IncomeSummary";
import MonthlySummary from "Pages/MonthlySummary";
import MonthsSummary from "Pages/MonthsSummary";
import Reminders from "Pages/Reminders";
import Savings from "Pages/Savings";
import Dashboard from "./Pages/Dashboard";
import { usePromiseTracker } from "react-promise-tracker";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useBankStore } from "store/bank";
import "./App.css";
import Login from "./Pages/login";
import { Toaster, toast } from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppStore } from "./store/useApp";

function App() {
   const { promiseInProgress } = usePromiseTracker();
   const BankStore = useAppStore((state) => state);
   const navigate = useNavigate();
   const location = useLocation();
   const fetchBanks = () => {
      BankStore?.getAllbanks()
         .then((res) => {
            console.log(res, "fetchBanks");
         })
         .catch();
   };

   useEffect(() => {
      const token = localStorage.getItem("auth_token");
      if (token === null || token === undefined) {
         localStorage.setItem("navigated_path", location.pathname);
         navigate("/");
      }
   }, []);

   useEffect(() => {
      fetchBanks();
   }, []);

   return (
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
         <Loader visible={promiseInProgress} />
         <Toaster position="top-center" />
         <Routes>
            <Route
               path="/"
               element={<Login />}
            />
            <Route
               path="/dashboard"
               element={<Dashboard />}
            />
            <Route
               path="/income"
               element={<Income />}
            />
            <Route
               path="/income_summary"
               element={<IncomeSymmary />}
            />
            <Route
               path="/expected_expenses"
               element={<ExpectedExpenses />}
            />
            <Route
               path="/actual_expenses"
               element={<ActualExpenses />}
            />
            <Route
               path="/actual_expenses/add"
               element={<ActualExpenses />}
            />
            <Route
               path="/months_summary"
               element={<MonthsSummary />}
            />
            <Route
               path="/months_summary/:date"
               element={<MonthsSummary />}
            />
            <Route
               path="/goals"
               element={<Goals />}
            />
            <Route
               path="/savings"
               element={<Savings />}
            />
            <Route
               path="/monthly_summary"
               element={<MonthlySummary />}
            />
            <Route
               path="/account_summary"
               element={<AccountSummary />}
            />
            <Route
               path="/reminders"
               element={<Reminders />}
            />
         </Routes>
      </GoogleOAuthProvider>
   );
}

export default App;
