/** @format */

import React from "react";
import Tooltip from "../Components/Tooltip";

function Icon({ onClick }) {
   return (
      <Tooltip
         placement={"top"}
         title={"Add actual expense"}
         toolTipBackgroundColor={"#000"}
         toolTipFontColor={"#fff"}>
         <svg
            onClick={onClick}
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="24"
            fill="none"
            viewBox="0 0 24 24">
            <path
               stroke="#14142B"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeWidth="2"
               d="M6 12h12m-6 6V6"></path>
         </svg>
      </Tooltip>
   );
}

export default Icon;
