import React from 'react';

export default function Icon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.1098 11.1501H7.45981C6.82981 11.1501 6.31982 11.6601 6.31982 12.2901V17.4101H10.1098V11.1501V11.1501Z" stroke="#898995" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.7611 6.6001H11.2411C10.6111 6.6001 10.1011 7.11011 10.1011 7.74011V17.4001H13.8911V7.74011C13.8911 7.11011 13.3911 6.6001 12.7611 6.6001Z" stroke="#898995" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16.5479 12.8501H13.8979V17.4001H17.688V13.9901C17.678 13.3601 17.1679 12.8501 16.5479 12.8501Z" stroke="#898995" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="#898995" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
}
