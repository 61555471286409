/** @format */

import { create } from "zustand";
import { createRequest, deleteRequest, updateRequest, readRequest } from "APIs/crud";
import { toast } from "react-hot-toast";

const API_ENDPOINT = "/actual_expenses/";

export const useActualExpensesStore = (set, get) => ({
   actualExpenses: [],
   expectedExpensesForMonth: [],
   goalsForMonth: [],
   expenseTypes: [],
   addActualExpense: async (data) => {
      return new Promise(async (resolve, reject) => {
         createRequest(API_ENDPOINT, data)
            .then((result) => {
               set({ actualExpenses: [...get().actualExpenses, result?.data] });
               toast.success("Actual expense added successfully");
               resolve(result?.data);
            })
            .catch((error) => reject(error));
      });
   },
   getActualExpenses: async () => {
      return new Promise(async (resolve, reject) => {
         readRequest(API_ENDPOINT)
            .then((result) => {
               set({ actualExpenses: result?.data?.response });
               resolve(result?.data?.response);
            })
            .catch((error) => reject(error));
      });
   },
   deleteActualExpense: async (id) => {
      return new Promise(async (resolve, reject) => {
         deleteRequest(`${API_ENDPOINT}${id}/`)
            .then((result) => {
               set((state) => ({ actualExpenses: state.actualExpenses.filter((item) => item.actual_expense_id !== id) }));
               toast.success("Actual expense deleted successfully");
               resolve(result?.data);
            })
            .catch((error) => reject(error));
      });
   },
   updateActualExpense: async (data) => {
      return new Promise(async (resolve, reject) => {
         updateRequest(`${API_ENDPOINT}${data.actual_expense_id}/`, data)
            .then((result) => {
               set((state) => ({ actualExpenses: state.actualExpenses.map((item) => (item.actual_expense_id === data.actual_expense_id ? result?.data : item)) }));
               toast.success("Actual expense updated successfully");
               resolve(result?.data);
            })
            .catch((error) => reject(error));
      });
   },
   getExpectedExpenseForMonth: async (date) => {
      return new Promise(async (resolve, reject) => {
         readRequest(`/expected_expenses_summary/get_unique_eesummary_for_month/${date}/`)
            .then((result) => {
               let options = [];
               result?.data?.response?.forEach((item) => {
                  options.push({
                     label: item.reason,
                     value: item.reason,
                     ...item,
                  });
               });
               if (options?.length > 0) {
                  set({ expectedExpensesForMonth: options });
                  set((state) => ({ expenseTypes: [...state.expenseTypes, { label: "Exptd expense", value: "expected_expense", key: "expectedExpensesForMonth" }] }));
               }
            })
            .catch((error) => reject(error));
      });
   },
   getGoalsForMonth: async (date) => {
      return new Promise(async (resolve, reject) => {
         readRequest(`/goal/get_unique_goals_for_month/${date}/`)
            .then((result) => {
               let options = [];
               result?.data?.response?.forEach((item) => {
                  options.push({
                     label: item.goal_name,
                     value: item.goal_name,
                     ...item,
                  });
               });
               if (options?.length > 0) {
                  set({ goalsForMonth: options });
                  set((state) => ({ expenseTypes: [...state.expenseTypes, { label: "Goal", value: "goal", key: "goalsForMonth" }] }));
               }
            })
            .catch((error) => reject(error));
      });
   },
   resetExpenseTypes: () => {
      set({ expenseTypes: [] });
   },
});
