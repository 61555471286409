/** @format */

import IncomeIcon from "icons/IncomeIcon";
import SummaryIcon from "icons/SummaryIcon";
import ExpectedExpenseIcon from "icons/ExpectedExpenseIcon";
import ActualExpenseIcon from "icons/ActualExpenseIcon";
import GoalsIcon from "icons/GoalsIcon";
import MonthlySummaryIcon from "icons/MonthlySummaryIcon";
import AccountSummaryIcon from "icons/AccountSummaryIcon";
import PiggyIcon from "icons/PiggyIcon";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SavingsIcon from "icons/SavingsIcon";
import { MONTHS } from "./dummyData";

const date = new Date();
const currentYear = date.getFullYear();
const currentMonth = MONTHS[date.getMonth()];
console.log(currentMonth, "CURRENTMONTH");

export const SidebarData = [
   {
      title: "Income details",
      subTitles: [
         {
            title: "Income",
            path: `/income`,
            Icon: <IncomeIcon />,
            params: `?years=${currentYear}`,
         },
         {
            title: "Income summary",
            path: "/income_summary",
            Icon: <SummaryIcon />,
            params: `?years=${currentYear}`,
         },
      ],
   },
   {
      title: "Expense details",
      subTitles: [
         {
            title: "Expected expense",
            path: "/expected_expenses",
            Icon: <ExpectedExpenseIcon />,
            params: `?years=${currentYear}`,
         },
         {
            title: "Actual expense",
            path: "/actual_expenses",
            Icon: <ActualExpenseIcon />,
            params: `?years=${currentYear}`,
         },
         {
            title: "Month's summary",
            path: "/months_summary",
            Icon: <SummaryIcon />,
            params: `?year=${currentYear}&month=${currentMonth}`,
         },
      ],
   },
   {
      title: "Goals",
      subTitles: [
         {
            title: "Goals",
            path: "/goals",
            Icon: <PiggyIcon />,
            params: `?years=${currentYear}`,
         },
         // {
         //    title: "Savings",
         //    path: "/savings",
         //    Icon: <SavingsIcon />,
         // },
      ],
   },
   {
      title: "Summary",
      subTitles: [
         {
            title: "Monthly summary",
            path: "/monthly_summary",
            Icon: <MonthlySummaryIcon />,
            params: `?years=${currentYear}`,
         },
         {
            title: "Account summary",
            path: "/account_summary",
            Icon: <AccountSummaryIcon />,
            params: ``,
         },
      ],
   },
   {
      title: "Reminders",
      subTitles: [
         {
            title: "Reminders",
            path: "/reminders",
            Icon: <NotificationsOutlinedIcon />,
            params: ``,
         },
      ],
   },
];
